import { useState } from 'react';
import { useQuery } from '@apollo/client';

import theme from 'web/theme/theme';

import { useAppFilters } from 'web/contexts/AppFiltersContext';

import UnsignedDocumentsDialog from 'web/components/widgets/dialogs/DocumentsDialog';

import {
	WidgetList,
	WidgetListItem,
	WidgetListLabel,
} from 'web/components/widgets/components/WidgetList';

import ErrorAlert from 'components/ErrorAlert';

import Widget from 'web/components/widgets/Widget';

import { COMPANY_DOCUMENTS_WITH_ACTIVE_SIGNING_REQUESTS } from 'api/queries/widgetQueries';

export default function CompanyDocumentsWidget() {
	const { department, accountant, accountantRole, company } = useAppFilters();

	const [showDialog, setShowDialog] = useState(false);

	const {
		data: { items } = {
			items: [],
		},
		error,
		loading,
	} = useQuery(COMPANY_DOCUMENTS_WITH_ACTIVE_SIGNING_REQUESTS, {
		variables: {
			filter: {
				departmentId: department?.value,
				accountantId: accountant?.value,
				accountantRole: accountantRole?.value,
				company: company?.value,
			},
		},
		fetchPolicy: 'no-cache',
	});

	return (
		<Widget title="Dokumenter" titleIcon="files">
			<ErrorAlert error={error} />

			<WidgetList>
				<WidgetListItem
					label={
						<WidgetListLabel
							label="Aktive signeringer"
							chipColor={theme.palette.status.warning}
						/>
					}
					value={items?.length ?? 0}
					loading={loading}
					onClick={() => setShowDialog(true)}
				/>
			</WidgetList>

			<UnsignedDocumentsDialog
				documents={items}
				open={showDialog}
				onClose={() => setShowDialog(false)}
			/>
		</Widget>
	);
}
