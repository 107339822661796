import styled from 'styled-components';

import PageHeader from 'app/components/PageHeader';

const StyledLayout = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	background-color: ${p => p.theme.palette.background.paper};
	-webkit-overflow-scrolling: touch;
	z-index: 9;
	box-shadow: 0 3px 60px rgba(0, 0, 0, 0.15);
`;
const Main = styled.main`
	margin: 30px auto 116px;
	padding: 0 15px;
	max-width: ${p => p.theme.width.large};
`;
const Scroller = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
`;
const GradientOverlay = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	height: 116px;
	pointer-events: none;
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,fcf9f9+100&0+0,1+100 */
	background: -moz-linear-gradient(
		top,
		rgba(255, 255, 255, 0) 0%,
		rgba(252, 249, 249, 1) 100%
	); /* FF3.6-15 */
	background: -webkit-linear-gradient(
		top,
		rgba(255, 255, 255, 0) 0%,
		rgba(252, 249, 249, 1) 100%
	); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(
		to bottom,
		rgba(255, 255, 255, 0) 0%,
		rgba(252, 249, 249, 1) 100%
	); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#fcf9f9',GradientType=0 ); /* IE6-9 */
`;

export default function Layout({
	pageTitle = 'Admento Falstad',
	children,
	hideGradient,
	leftButton,
	hideMainMenuButton = false,
	hideSearchButton,
	afterScroll,
	showLogo,
}) {
	return (
		<StyledLayout>
			<Scroller>
				<PageHeader
					showLogo={showLogo}
					title={pageTitle}
					leftButton={leftButton}
					hideMainMenuButton={hideMainMenuButton}
					hideSearchButton={hideSearchButton}
				/>

				<Main>{children}</Main>
			</Scroller>

			{!hideGradient && <GradientOverlay />}

			{afterScroll}
		</StyledLayout>
	);
}
