import styled from 'styled-components';

import AppFilters from 'components/filters/AppFilters';

const Title = styled.h2`
	margin-top: 0;
`;

export default function AppFiltersSidebar({ displayFilters = [] }) {
	return (
		<>
			<Title>Filtrering</Title>

			<AppFilters displayFilters={displayFilters} direction="column" />
		</>
	);
}
