import styled from 'styled-components';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import Icon from 'components/Icon';

import { pluralize } from 'utils/strings';

import { useAppFilters, actionTypes } from 'web/contexts/AppFiltersContext';
import { useUser } from 'web/contexts/UserContext';

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`;

const FilterTitle = styled.div`
	font-size: 14px;
`;

const FiltersWrapper = styled.ul`
	list-style: none;
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 10px;
	margin-right: 10px;
`;

export default function AppFiltersActive({
	displayFilters = [],
	openFilterButton = null,
}) {
	const { userHasMinRole } = useUser();

	const {
		year,
		month,
		department,
		accountant,
		projectManager,
		accountManager,
		payrollManager,
		annualAccountsManager,
		controller,
		auditor,
		accountantLegacy,
		accountantRole,
		accountantRoleLegacy,
		userRole,
		task,
		company,
		accountType,
		auditingCompany,
		dispatch,
	} = useAppFilters();

	const onDelete = actionType => {
		dispatch({ type: actionType, payload: null });
	};

	const allFilters = [
		{
			id: 'year',
			name: 'År',
			value: year?.value,
			label: year?.label,
		},
		{
			id: 'month',
			name: 'Måned',
			value: month?.value,
			label: month?.label,
		},
		{
			id: 'task',
			name: 'Oppgave',
			value: task?.value,
			label: task?.label,
			onDelete: () => onDelete(actionTypes.SET_TASK),
		},
		{
			id: 'department',
			name: 'Avdeling',
			value: department?.value,
			label: department?.label,
			onDelete: () => onDelete(actionTypes.SET_DEPARTMENT),
		},
		{
			id: 'accountant',
			name: 'Regnskapsfører',
			value: accountant?.value,
			label: accountant?.label,
			onDelete: () => onDelete(actionTypes.SET_ACCOUNTANT),
		},
		{
			id: 'projectManager',
			name: 'Oppdragsansvarlig',
			value: projectManager?.value,
			label: projectManager?.label,
			onDelete: () => onDelete(actionTypes.SET_PROJECT_MANAGER),
		},
		{
			id: 'accountManager',
			name: 'Kundeansvarlig',
			value: accountManager?.value,
			label: accountManager?.label,
			onDelete: () => onDelete(actionTypes.SET_ACCOUNT_MANAGER),
		},
		{
			id: 'payrollManager',
			name: 'Lønnsansvarlig',
			value: payrollManager?.value,
			label: payrollManager?.label,
			onDelete: () => onDelete(actionTypes.SET_PAYROLL_MANAGER),
		},
		{
			id: 'annualAccountsManager',
			name: 'Årsoppgjøransvarlig',
			value: annualAccountsManager?.value,
			label: annualAccountsManager?.label,
			onDelete: () => onDelete(actionTypes.SET_ANNUAL_ACCOUNTS_MANAGER),
		},
		{
			id: 'controller',
			name: 'Oppdragskontrollør',
			value: controller?.value,
			label: controller?.label,
			onDelete: () => onDelete(actionTypes.SET_CONTROLLER),
		},
		{
			id: 'auditor',
			name: 'Revisor',
			value: auditor?.value,
			label: auditor?.label,
			onDelete: () => onDelete(actionTypes.SET_AUDITOR),
		},
		userHasMinRole('admin') && {
			id: 'accountantLegacy',
			name: 'Regnskapsfører',
			value: accountantLegacy?.value,
			label: accountantLegacy?.label,
			onDelete: () => onDelete(actionTypes.SET_ACCOUNTANT_LEGACY),
		},
		{
			id: 'accountantRole',
			name: 'Rolle',
			value: accountantRole?.value,
			label: accountantRole?.label,
			onDelete: () => onDelete(actionTypes.SET_ACCOUNTANT_ROLE),
		},
		{
			id: 'accountantRoleLegacy',
			name: 'Rolle',
			value: accountantRoleLegacy?.value,
			label: accountantRoleLegacy?.label,
			onDelete: () => onDelete(actionTypes.SET_ACCOUNTANT_ROLE_LEGACY),
		},
		{
			id: 'userRole',
			name: 'Brukerrolle',
			value: userRole?.value,
			label: userRole?.label,
			onDelete: () => onDelete(actionTypes.SET_USER_ROLE),
		},
		{
			id: 'company',
			name: 'Bedrift',
			value: company?.value,
			label: company?.label,
			onDelete: () => onDelete(actionTypes.SET_COMPANY),
		},
		{
			id: 'accountType',
			name: 'Kontotype',
			value: accountType?.value,
			label: accountType?.label,
			onDelete: () => onDelete(actionTypes.SET_ACCOUNT_TYPE),
		},
		{
			id: 'auditingCompany',
			name: 'Revisorfirma',
			value: auditingCompany?.value,
			label: auditingCompany?.label,
			onDelete: () => onDelete(actionTypes.SET_AUDITING_COMPANY),
		},
	];

	if (displayFilters.length === 0) {
		throw new Error('At least one displayFilter is required!');
	}

	// Keep the order of the displayFilters
	const activeFilters = displayFilters
		.map(id => allFilters.find(filter => filter.id === id))
		.filter(
			filter =>
				filter &&
				filter.value !== null &&
				filter.value !== undefined &&
				filter.value !== ''
		);

	return (
		<Wrapper>
			{activeFilters.length > 0 && (
				<>
					<Tooltip
						title={pluralize(
							'Aktivt filter',
							'Aktive filtre',
							activeFilters
						)}
						placement="top-end"
					>
						<FilterTitle id="active-filters-title">
							<Icon icon="filter-list" />
						</FilterTitle>
					</Tooltip>

					<FiltersWrapper aria-labelledby="active-filters-title">
						{activeFilters.map((filter, index) => {
							const chipLabel = (
								<span>
									<strong>{filter.name}:</strong>{' '}
									{filter.label}
								</span>
							);

							return (
								<li key={`active-filter-${index}`}>
									<Chip
										label={chipLabel}
										onDelete={filter?.onDelete}
									/>
								</li>
							);
						})}
					</FiltersWrapper>
				</>
			)}

			{openFilterButton && openFilterButton}
		</Wrapper>
	);
}
