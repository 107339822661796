import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import Button from '@mui/material/Button';

import Card from 'components/Card';
import DocumentIcon from 'components/DocumentIcon';
import {
	DocumentTitleWrapper,
	DocumentTitle,
	ParentTitle,
	DocumentParagraph,
} from 'components/DocumentLayout';
import { InlineLoading } from 'components/Loading';
import ErrorAlert from 'components/ErrorAlert';

import DesktopLayout, { NarrowContent } from 'web/components/Layout';
import SigneeForm from 'web/components/SigneeForm';

import { GET_COMPANY_DOCUMENTS } from 'api/queries/documentQueries';
import { ADD_SIGNEES_TO_DOCUMENT } from 'api/mutations/signatureMutations';
import { FIND_ONE_USER } from 'api/queries/userQueries';

export default function SendSigningRequest({ companyId }) {
	const { documentNodeId } = useParams();

	const {
		data: { documentNode } = {},
		loading,
		error,
	} = useQuery(GET_COMPANY_DOCUMENTS, {
		variables: {
			tenantId: companyId,
			parentId: documentNodeId,
		},
	});

	return (
		<DesktopLayout
			pageTitle="Send til signering"
			breadcrumbs={[
				{
					to: '/dokumenter/',
					label: 'Dokumenter',
				},
				{
					label: (documentNode && documentNode.name) || '...',
					to: '/dokumenter/' + documentNodeId + '/',
				},
				{
					label: 'Signer',
				},
			]}
		>
			<NarrowContent>
				<SignintRequest
					documentNode={documentNode}
					documentLoading={loading}
					documentError={error}
				/>
			</NarrowContent>
		</DesktopLayout>
	);
}

function SignintRequest({ documentNode, documentLoading, documentError }) {
	const navigate = useNavigate();

	if (documentLoading) {
		return <InlineLoading />;
	}

	if (documentError) {
		return <ErrorAlert error={documentError} />;
	}

	if (documentNode.isSigning || documentNode.isSigned) {
		return (
			<div>
				<p>Dokumentet er ute til signering eller allerede signert!</p>

				<Button
					variant="contained"
					color="primary"
					onClick={() => navigate(`/dokumenter/${documentNode._id}/`)}
					fullWidth
				>
					Tilbake til dokument
				</Button>
			</div>
		);
	}

	return <SigningRequestForm documentNode={documentNode} />;
}

function SigningRequestForm({ documentNode }) {
	const [sent, setSent] = useState(false);
	const [signees, setSignees] = useState([]);
	const [notified, setNotified] = useState([]);
	const client = useApolloClient();

	const [sendSigningRequest, { loading, error }] = useMutation(
		ADD_SIGNEES_TO_DOCUMENT
	);

	useEffect(() => {
		if (!signees) return;

		(async () => {
			const notifiedArray = [];
			await Promise.all(
				signees.map(async s => {
					if (s.userId) {
						const { data } = await client.query({
							query: FIND_ONE_USER,
							variables: {
								_id: s.userId,
							},
						});

						if (data && data.user) {
							notifiedArray.push(data.user.name);
						}
					}

					if (s.phone) {
						notifiedArray.push(s.phone);
					}

					if (s.email) {
						notifiedArray.push(s.email);
					}
				})
			);

			setNotified(notifiedArray);
		})();
	}, [signees]);

	if (sent) {
		return (
			<SuccessMessage documentNode={documentNode} notified={notified} />
		);
	}

	return (
		<SigneeForm
			description="Send til signering"
			loading={loading}
			errors={[error]}
			documentId={documentNode._id}
			companyId={documentNode.tenantId}
			onSubmit={async variables => {
				setSignees(variables.signees);

				const data = await sendSigningRequest({
					variables,
				});

				if (!data.error) {
					setSent(true);
				}
			}}
			{...documentNode}
		/>
	);
}

function SuccessMessage({ documentNode, notified }) {
	const navigate = useNavigate();

	return (
		<Card
			icon={
				<DocumentIcon
					needsSignature={true}
					hasSignature={false}
					fontSize="large"
				/>
			}
		>
			<DocumentTitleWrapper>
				<DocumentTitle>{documentNode.name}</DocumentTitle>

				{documentNode.parent && documentNode.parent.name && (
					<ParentTitle>
						{' / '}
						{documentNode.parent.name}
					</ParentTitle>
				)}

				<div>
					<DocumentParagraph $align="center">
						{`Dokumentet er sendt til signering til ${notified.join(
							', '
						)}`}
					</DocumentParagraph>

					<DocumentParagraph $align="center">
						<Button
							variant="contained"
							color="primary"
							onClick={() =>
								navigate(`/dokumenter/${documentNode._id}/`)
							}
						>
							Tilbake til dokument
						</Button>
					</DocumentParagraph>
				</div>
			</DocumentTitleWrapper>
		</Card>
	);
}
