import styled from 'styled-components';
import { Link } from 'react-router';

import packageJson from '/package.json';

import logo from 'assets/images/logo-full.svg';

import ErrorMessage from 'components/ErrorMessage';

const Wrapper = styled.div`
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 20px;
`;

const Header = styled.header`
	justify-self: flex-start;
	align-self: flex-end;
	height: 100%;
	max-height: 25%;
`;

const Main = styled.main`
	text-align: center;
	max-width: 450px;
	width: 100%;
	margin-top: 80px;
`;

const Content = styled.div`
	max-width: 300px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	gap: 20px;
`;

const Logo = styled.img`
	width: 100%;
	height: auto;
	max-width: 305px;
	margin-bottom: 18px;
`;

const Title = styled.h1`
	font-size: 1.375rem;
	margin: 0 0 60px 0;
`;

const Footer = styled.footer`
	margin-top: auto;
`;

const AppVersion = styled.div`
	font-style: italic;
	margin-top: 20px;
`;

export default function LoginLayout({
	header = null,
	errors = null,
	children,
}) {
	return (
		<Wrapper>
			{<Header>{header && header}</Header>}

			<Main>
				<Link to="/">
					<Logo src={logo} alt="Admento Falstad AS" />
				</Link>

				<Title>Admento Falstad</Title>

				<ErrorMessage errors={errors} />

				<Content>{children}</Content>
			</Main>

			<Footer>
				<AppVersion>v{packageJson.version}</AppVersion>
			</Footer>
		</Wrapper>
	);
}
