import { useAppFilters, actionTypes } from 'web/contexts/AppFiltersContext';

import departments from 'components/utils/departments';

import Filter from 'components/filters/Filter';

const allOption = { value: '', label: 'Alle avdelinger' };

export default function DepartmentFilter({
	disabled = false,
	size = 'medium',
}) {
	const { department, dispatch } = useAppFilters();

	return (
		<Filter
			name="department-filter"
			label="Vis avdeling"
			value={department || allOption}
			options={[allOption, ...departments]}
			onChange={value =>
				dispatch({
					type: actionTypes.SET_DEPARTMENT,
					payload: value,
				})
			}
			size={size}
			disabled={disabled}
			minWidth="160px"
		/>
	);
}
