import { useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { utils, writeFile } from 'xlsx';
import xlsx from 'json-as-xlsx';
import values from 'lodash/values';
import cloneDeep from 'lodash/cloneDeep';
import omitDeep from 'omit-deep';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';

import dayjs from 'utils/dates';
import { fileSlugify } from 'utils/strings';

import departments, { getDepartmentLabel } from 'components/utils/departments';

import DesktopLayout, { MediumContent } from 'web/components/Layout';
import ErrorMessage from 'components/ErrorMessage';

import Form from 'components/form/Form';
import DateField from 'components/form/DateField';
import AutocompleteField from 'components/form/AutocompleteField';
import SelectField from 'components/form/SelectField';
import SubmitButton from 'components/form/SubmitButton';

import { GET_ALL_DOCUMENTS_FOR_TENANT } from 'api/queries/documentQueries';
import {
	FIND_TENANTS,
	FIND_COMPANIES_EXPORT_DATA,
} from 'api/queries/tenantQueries';

const DateFields = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 0 -10px;
`;

const FieldWrapper = styled.div`
	padding: 0 10px;
`;

const Separator = styled.hr`
	border-top: 1px solid ${p => p.theme.palette.primary.main};
	width: 50%;
	margin: 60px auto;
`;

export default function ExportScreen() {
	return (
		<DesktopLayout
			breadcrumbs={[
				{
					label: 'Eksport',
				},
			]}
		>
			<MediumContent>
				<CompaniesExport />

				<Separator />

				<FolderExport />
			</MediumContent>
		</DesktopLayout>
	);
}

function CompaniesExport() {
	const [error, setError] = useState(null);

	const [getCompaniesData, { error: getCompaniesDataError }] = useLazyQuery(
		FIND_COMPANIES_EXPORT_DATA
	);

	async function handleCreateCompaniesExcelExport(values) {
		setError(null);

		if (dayjs(values.toDate).isBefore(dayjs(values.fromDate))) {
			setError('Til-dato kan ikke være før fra-dato!');
			return;
		}

		const { data: { companies } = { companies: [] }, error } =
			await getCompaniesData({
				variables: {
					...values,
				},
			});

		if (error) {
			return;
		}

		if (companies.length === 0) {
			setError('Ingen bedrifter ble funnet for eksport!');
			return;
		}

		try {
			const data = [
				{
					sheet: 'Bedriftsdata',
					columns: [
						{ label: 'Navn', value: 'name' },
						{ label: 'Selskapsform', value: 'companyType.name' },
						{
							label: 'Selskapsform (Forkortning)',
							value: 'companyType.id',
						},
						{ label: 'Organisasjonsnummer', value: 'orgNo' },
						{ label: 'Kundenummer', value: 'clientNo' },

						{ label: 'Daglig leder', value: 'ceoCount' },
						{ label: 'Styreleder', value: 'chairmanCount' },
						{ label: 'Styremedlem', value: 'boardmemberCount' },
						{ label: 'Revisor', value: 'auditorCount' },
						{
							label: 'Revisorfirma (Proff)',
							value: ({ proffData }) =>
								proffData?.companyRoles?.filter(
									({ title }) => title === 'Revisor'
								).length ?? 0,
						},
						{ label: 'Total data (MB)', value: 'sizeUsed' },
						{
							label: 'Signerte dokumenter',
							value: 'signedDocumentsCount',
						},
						{ label: 'Antall signaturer', value: 'signedCount' },
					],
					content: companies,
				},
			];

			const department = values.departmentId
				? getDepartmentLabel(values.departmentId)
				: 'alle';

			xlsx(data, {
				fileName: fileSlugify(['bedriftsdata', department], null, {
					includeDate: true,
				}),
				extraLength: 3,
			});
		} catch (err) {
			console.error(err);

			setError('Det oppstod en feil under generering av eksport!');
		}
	}

	return (
		<div>
			<h2>Alle bedrifter</h2>

			<ErrorMessage errors={[error, getCompaniesDataError]} />

			<Form onSubmit={handleCreateCompaniesExcelExport}>
				<DateFields>
					<FieldWrapper>
						<DateField
							name="fromDate"
							label="Fra"
							defaultValue={dayjs().subtract(3, 'month').toDate()}
							required
						/>
					</FieldWrapper>

					<FieldWrapper>
						<DateField
							name="toDate"
							label="Til"
							defaultValue={new Date()}
							required
						/>
					</FieldWrapper>
				</DateFields>

				<SelectField
					name="departmentId"
					label="Velg avdeling"
					options={departments}
				/>

				<SubmitButton
					variant="contained"
					size="large"
					icon={<SaveIcon />}
					fullWidth
				>
					Eksporter all data fra tidsrom
				</SubmitButton>
			</Form>
		</div>
	);
}

function FolderExport() {
	const [selectedCompany, setSelectedCompany] = useState(null);
	const [error, setError] = useState(null);

	const {
		data: { companies } = { companies: [] },
		loading: companiesLoading,
		error: companiesError,
	} = useQuery(FIND_TENANTS, {
		variables: {
			orderBy: 'name',
			order: 1,
		},
	});

	const [
		getDocuments,
		{ loading: getDocumentsLoading, error: getDocumentsError },
	] = useLazyQuery(GET_ALL_DOCUMENTS_FOR_TENANT);

	async function handleFolderExport() {
		const { data: { documents } = { documents: [] }, error } =
			await getDocuments({
				variables: {
					tenantId: selectedCompany,
				},
			});

		if (error) {
			return;
		}

		if (documents.length === 0) {
			setError('Ingen dokumenter ble funnet for eksport!');
			return;
		}

		// The object we get from the Apollo server is read only,
		// so we need to clone it before we make changes.
		const docs = cloneDeep(documents);
		let sortedDocumentsArray = [];

		docs.map(doc => {
			if (doc.type === 'folder' && doc.parentId === 'root') {
				sortedDocumentsArray.push(
					omitDeep(doc, [
						'__typename',
						'contentType',
						'isSigningComplete',
					])
				);
			}
		});

		const subFolders = docs.filter(
			doc => doc.type === 'folder' && doc.parentId !== 'root'
		);

		// Get all files
		const files = docs.filter(doc => doc.type === 'file');

		// Create the header row that will go on top of each sheet
		let headerRow = [
			'Mappenavn',
			'Filer',
			'Opprettet',
			'Opprettet av',
			'Sist oppdatert',
			'Oppdatert av',
		];

		let subFolderArrays = [];

		// Iterate through the root folders, find subfolders and files that belongs in them
		sortedDocumentsArray.forEach(root => {
			const subs = subFolders.filter(sub => sub.parentId === root._id);
			const rootName = root.name;
			let folderArray = [];

			if (subs) {
				// Add the header row to the top
				folderArray.push(headerRow);

				root.subFolders = [];
				// __typename should already be gone, but making sure is always good
				subs.forEach(sub => {
					sub = omitDeep({ ...sub }, ['__typename']);
					root.subFolders.push(sub);
				});

				root.subFolders.forEach(sub => {
					// Get the files in the subfolder before we remove the _id value
					let subFiles = files.filter(
						file => file.parentId === sub._id
					);

					// Omit all types in the subfolders and files that should not show on the export
					const subFilesRemovedProps = [];
					subFiles.forEach(file => {
						file = omitDeep({ ...file }, [
							'__typename',
							'contentType',
							'isSigningComplete',
							'parentId',
							'type',
							'_id',
						]);

						subFilesRemovedProps.push(file);
					});

					subFiles = subFilesRemovedProps;

					omitDeep(sub, [
						'_id',
						'type',
						'isSigningComplete',
						'parentId',
						'contentType',
					]);

					// Change the DateTime string to a new short local date string
					// updatedAt needs an if in case it hasn't been updated
					sub.createdAt = new Date(
						sub.createdAt
					).toLocaleDateString();

					if (sub.updatedAt) {
						sub.updatedAt = new Date(
							sub.updatedAt
						).toLocaleDateString();
					}

					// Spread the values of the subfolder and subsequent file objects into arrays,
					// then add them to the array of arrays
					let subData = values(sub);
					subData.splice(1, 0, '');
					folderArray.push(subData);

					subFiles.forEach(sf => {
						// Change the DateTime string to a new short local date string
						// updatedAt needs an if in case it hasn't been updated
						sf.createdAt = new Date(
							sf.createdAt
						).toLocaleDateString();
						if (sf.updatedAt) {
							sf.updatedAt = new Date(
								sf.updatedAt
							).toLocaleDateString();
						}

						let spreadFile = values(sf);
						spreadFile.unshift('');
						folderArray.push(spreadFile);
					});
				});

				subFolderArrays.push({
					name: rootName,
					value: folderArray,
				});
			}
		});

		let wb = utils.book_new();

		// Iterate through the subFolderArray and create a sheet for each subfolder (rootFolder)
		subFolderArrays.forEach(subFolder => {
			// Add array of arrays to sheet
			let sheet = utils.aoa_to_sheet(subFolder.value);

			// Set the column sizes
			const cols = [
				{ wch: 30 },
				{ wch: 40 },
				{ wch: 25 },
				{ wch: 25 },
				{ wch: 25 },
				{ wch: 25 },
			];
			sheet['!cols'] = cols;

			utils.book_append_sheet(wb, sheet, subFolder.name);
		});

		// Get the name of the company from the companies list
		const company = companies.filter(
			comp => comp._id === selectedCompany
		)[0];

		writeFile(
			wb,
			fileSlugify(['dokumentmappe', company.name], 'xlsx', {
				includeDate: true,
			})
		);
	}

	return (
		<div>
			<h2>Mappedata bedrift</h2>

			<ErrorMessage errors={[error, companiesError, getDocumentsError]} />

			<AutocompleteField
				label="Bedrift"
				name="companyId"
				loading={companiesLoading}
				loadingText="Laster bedrifter..."
				value={selectedCompany || null}
				options={companies.map(c => ({
					label: c.name,
					value: c._id,
				}))}
				update={({ value }) => {
					setSelectedCompany(value);
				}}
			/>

			<Button
				variant="contained"
				onClick={handleFolderExport}
				disabled={!selectedCompany || getDocumentsLoading}
				startIcon={<SaveIcon />}
				size="large"
				fullWidth
			>
				Eksporter bedriftens mappedata
			</Button>
		</div>
	);
}
