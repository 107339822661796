import { Link } from 'react-router';
import { useQuery, useMutation } from '@apollo/client';
import styled from 'styled-components';
import { useParams } from 'react-router';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';

import { defaultFolders } from 'server/libs/defaultFolders';
import { useUser } from 'web/contexts/UserContext';
import { useSnackbar } from 'web/contexts/SnackbarContext';

import DesktopLayout, { NarrowContent } from 'web/components/Layout';
import ErrorAlert from 'components/ErrorAlert';

import { FIND_USER_WITH_USER_ROLES } from 'api/queries/userQueries';
import { UPDATE_ONE_USER_FOLDER_ACCESS } from 'api/mutations/userMutations';

const StyledTitle = styled.h1`
	color: ${p =>
		p.$subdued
			? p.theme.palette.common.darkGray
			: p.theme.palette.common.black};
	font-style: ${p => (p.subdued ? 'italic' : 'normal')};
`;

const Actions = styled.div`
	display: flex;
	justify-content: flex-end;
`;

const Company = styled.div`
	background-color: ${p => p.theme.palette.background.default};
	border: 1px solid ${p => p.theme.palette.background.paper};
	padding: 20px;
	margin-bottom: 20px;
`;

const CompanyName = styled.h2`
	margin: 0;

	a {
		color: ${p => p.theme.palette.primary.main};
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}
`;

const CompanyRole = styled.div`
	font-size: 1.1em;
	margin-bottom: 20px;
`;

const AccessRow = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;
`;

export default function UsersEdit() {
	const { notify } = useSnackbar();
	const { userId } = useParams();
	const { user: currentUser } = useUser();

	const { data: { user = {} } = { user: {} } } = useQuery(
		FIND_USER_WITH_USER_ROLES,
		{
			fetchPolicy: 'network-only',
			variables: {
				_id: userId,
			},
		}
	);

	const [updateOneUserFolderAccess, { loading, error }] = useMutation(
		UPDATE_ONE_USER_FOLDER_ACCESS,
		{
			refetchQueries: [
				{
					query: FIND_USER_WITH_USER_ROLES,
					variables: {
						_id: userId,
					},
				},
			],
		}
	);

	if (!user) return null;

	const uniqueCompanyUserRoles = [];
	if (user?.userRoles?.length > 0) {
		user.userRoles.forEach(u => {
			if (!uniqueCompanyUserRoles?.find(c => c.id === u.id)) {
				uniqueCompanyUserRoles.push(u);
			}
		});
	}

	return (
		<DesktopLayout
			breadcrumbs={[
				{
					label: 'Brukere',
					to: '/brukere/',
				},
				{
					label: user.name || 'Laster...',
					to: `/brukere/${user._id}/`,
				},
				{
					label: 'Tilganger',
				},
			]}
		>
			<NarrowContent>
				<StyledTitle $subdued={!user.role || user.role === 'silent'}>
					{user.name}
				</StyledTitle>

				<ErrorAlert error={error} />

				{uniqueCompanyUserRoles.length === 0 && (
					<div>Brukeren er ikke tilknyttet noen bedrifter!</div>
				)}

				{uniqueCompanyUserRoles?.map(company => (
					<Company key={company.id}>
						<CompanyName>
							<Link
								to={
									(currentUser?.role === 'admin'
										? '/bedrifter/'
										: '/') + `${company.id}/rediger`
								}
							>
								{company.companyName}
							</Link>
						</CompanyName>

						<CompanyRole>
							<strong>Rolle:</strong> {company.roleLabel}
						</CompanyRole>

						{defaultFolders.map(folder => {
							if (folder?.excludeFromAccess) return null;

							const active = user?.folderAccess
								?.find(access => access.tenantId === company.id)
								?.folders?.includes(folder.slug);

							return (
								<AccessRow key={folder.slug}>
									<div>{folder.name}</div>

									<IconButton
										disabled={loading}
										title={
											active
												? 'Fjern tilgang'
												: 'Gi tilgang'
										}
										onClick={async () => {
											if (loading) return;
											const { data } =
												await updateOneUserFolderAccess(
													{
														variables: {
															_id: userId,
															tenantId:
																company.id,
															folder: folder.slug,
														},
													}
												);

											if (data?.user) {
												notify(
													`Tilgang oppdatert for: ${folder.name}`
												);
											}
										}}
									>
										{active ? (
											<LockOpenIcon color="success" />
										) : (
											<LockIcon color="error" />
										)}
									</IconButton>
								</AccessRow>
							);
						})}
					</Company>
				))}

				<Actions>
					<Button
						variant="contained"
						color="primary"
						size="large"
						component={Link}
						to={`/brukere/${user._id}/`}
					>
						Lagre
					</Button>
				</Actions>
			</NarrowContent>
		</DesktopLayout>
	);
}
