import theme from 'web/theme/theme';

import {
	WidgetList,
	WidgetListLabel,
} from 'web/components/widgets/components/WidgetList';
import WidgetListData from 'web/components/widgets/components/WidgetListData';

import Widget from 'web/components/widgets/Widget';

import {
	COMPANIES_MISSING_ROUTINES,
	COMPANIES_NEED_ROUTINES_REVISION,
} from 'api/queries/widgetQueries';

export default function ComanyRoutinesWidget() {
	return (
		<Widget title="Bedriftsrutiner" titleIcon="recycle">
			<WidgetList>
				<WidgetListData
					label={
						<WidgetListLabel
							label="Mangler rutiner"
							chipColor={theme.palette.status.error}
						/>
					}
					query={COMPANIES_MISSING_ROUTINES}
					link={id => `/bedrifter/${id}/rediger/?tab=skjema`}
				/>

				<WidgetListData
					label={
						<WidgetListLabel
							label="Trenger revisjon"
							chipColor={theme.palette.status.warning}
						/>
					}
					query={COMPANIES_NEED_ROUTINES_REVISION}
					link={id => `/bedrifter/${id}/rediger/?tab=skjema`}
				/>
			</WidgetList>
		</Widget>
	);
}
