import { createContext, useState } from 'react';
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router';

import Icon from 'components/Icon';

const sharedListStyle = css`
	list-style: none;
	margin: 0;
	padding: 0;
	position: relative;
`;

export const StyledList = styled.ul`
	${sharedListStyle};
`;

export const StyledSubList = styled.ul`
	${sharedListStyle};
	display: ${p => (p.$isOpen ? 'block' : 'none')};

	span {
		font-size: 1em;
	}
`;

export const StyledListItem = styled.li`
	&.has-sub {
		position: relative;
		> a {
			margin-right: 40px;
		}
		li a {
			padding-left: 44px;
		}
	}
`;

const sharedLinkStyle = css`
	width: 100%;
	display: flex;
	align-items: center;
	padding: 11px 15px 9px;
	color: ${p => p.theme.palette.common.black};
	text-decoration: none;
	background: none;
	transition: background 0.4s;

	i,
	span {
		transition: color 0.4s;
	}

	&:hover {
		background: ${p => p.theme.palette.background.paper};

		i,
		span {
			color: ${p => p.theme.palette.primary.main};
		}
	}
`;

const StyledLink = styled(NavLink)`
	${sharedLinkStyle};

	&.active {
		${p =>
			!p.preventactive &&
			'background:' + p.theme.palette.background.paper};

		i,
		span {
			${p => !p.preventactive && 'color:' + p.theme.palette.primary.main};
		}
	}
`;

const StyledNavButton = styled.button`
	${sharedLinkStyle};
	appearance: none;
	border: none;
`;

const StyledLinkLabel = styled.span`
	font-size: 18px;
	font-weight: ${p => (p.emphasized === true ? 600 : 400)};
	line-height: 30px;
	margin-left: 5px;
`;

const StyledNavMoreButton = styled.button`
	appearance: none;
	border: none;
	border-left: 1px solid rgba(0, 0, 0, 0.15);
	padding: 0;
	background: rgba(0, 0, 0, 0);
	transition: all 0.4s;
	display: flex;
	align-items: center;
	position: absolute;
	right: 0;
	top: 7px;
	height: 36px;
	width: 50px;
	cursor: pointer;

	i {
		min-width: 50px;
		transition: transform 0.4s;
		transform: ${p => (p.$isExpanded ? 'rotate(-180deg)' : 'rotate(0deg)')};
	}

	&:hover {
		background: rgba(0, 0, 0, 0.03);
		border-color: rgba(0, 0, 0, 0.03);
	}
`;

export const ListItemContext = createContext({
	isOpen: true,
	setIsOpen: () => {},
});

export default function ListItem({ item }) {
	if (!item) {
		return null;
	}

	const SESSION_DATA_KEY = 'sub-item-' + item.id;
	const sessionData = sessionStorage.getItem(SESSION_DATA_KEY);

	const [dropdown, setDropdown] = useState(
		sessionData === 'true' ? true : false
	);

	const hasSub = item.subs?.length > 0;

	return (
		<StyledListItem className={hasSub ? 'has-sub' : ''}>
			<MenuItem
				label={item.label}
				to={item.to}
				icon={item.icon}
				emphasized={item.emphasized}
			/>
			{hasSub && (
				<>
					<StyledNavMoreButton
						type="button"
						aria-haspopup="menu"
						aria-expanded={dropdown ? 'true' : 'false'}
						$isExpanded={dropdown}
						onClick={() => {
							sessionStorage.setItem(SESSION_DATA_KEY, !dropdown);
							setDropdown(!dropdown);
						}}
					>
						<Icon icon="chevron-down" size="xs" />
					</StyledNavMoreButton>
					<StyledSubList $isOpen={dropdown}>
						{item.subs.map((sub, index) => (
							<StyledListItem
								key={`sub-menu-item-${sub.to}-${index}`}
							>
								<MenuItem
									label={sub.label}
									to={sub.to}
									icon={sub.icon}
									emphasized={sub.emphasized}
								/>
							</StyledListItem>
						))}
					</StyledSubList>
				</>
			)}
		</StyledListItem>
	);
}

export function MenuItem(props) {
	const { icon, label, to, emphasized, onClick, preventactive, ...rest } =
		props;
	if (onClick) {
		return (
			<StyledNavButton onClick={onClick} {...rest}>
				<MenuLabel icon={icon} label={label} emphasized={emphasized} />
			</StyledNavButton>
		);
	}
	return (
		<StyledLink to={to} preventactive={preventactive} {...rest}>
			<MenuLabel icon={icon} label={label} emphasized={emphasized} />
		</StyledLink>
	);
}

export function MenuLabel({ icon, label, emphasized }) {
	return (
		<>
			{icon && <Icon icon={icon} duotone />}
			<StyledLinkLabel emphasized={emphasized}>{label}</StyledLinkLabel>
		</>
	);
}
