import { createTheme } from '@mui/material/styles';

const theme = createTheme({
	palette: {
		primary: {
			main: '#004896',
			light: '#27a7de',
		},
		secondary: {
			main: '#fff4e5',
		},
		admento: {
			blaa: '#004896',
			lys: '#27a7de',
			mork: '#0f2749',
			sand: '#ad8f5a',
			energi: '#8ce0f4',
			fjaer: '#e4dbcd',
			plomme: '#6b1e27',
			korall: '#df6868',
			skog: '#174a40',
			eng: '#c4f2da',
		},
		action: {
			active: '#000000',
		},
		background: {
			default: '#FFFFFF',
			paper: '#EFF0F1',
			gray: '#F9FAFC',
			green: '#93BD8F',
			blue: '#7cc7f6',
			lightGrayBlue: '#f3f8ff',
			red: '#df6868',
			yellow: '#f6df75',
			orange: '#f6b775',
		},
		status: {
			success: '#93bd90',
			error: '#df6868',
			warning: '#f6df75',
			subdued: '#797979',
			light: {
				gray: '#F6F7F9',
				lightGreen: '#bed7bb',
				mediumGreen: '#a8caa5',
				green: '#93bd8f',
				blue: '#7cc7f6',
				orange: '#f6b775',
				yellow: '#f6df75',
				red: '#df6868',
				black: '#FCF9F9',
			},
			dark: {
				gray: '#c8c7c7',
				lightGreen: '#bed7bb',
				mediumGreen: '#a8caa5',
				green: '#93bd8f',
				blue: '#009dff',
				orange: '#ff8200',
				yellow: '#ffd200',
				red: '#ff4c40',
				black: '#000000',
			},
		},
		common: {
			black: '#000000',
			gray: '#EDEDED',
			darkGray: '#787775',
			yellow: '#F3C600',
		},
		borderColor: {
			default: '#c4c4c4',
			hover: '#000000',
		},
		text: {
			primary: '#000000',
			light: '#787775',
			secondary: '#401D69',
			heading: '#0a0a0a',
			white: '#FFFFFF',
		},
	},
	width: {
		small: '400px',
		medium: '700px',
		large: '1000px',
	},
	typography: {
		fontFamily: '"Lato", serif',
		headingFontFamily: '"Lato", serif',
		headingFontWeight: 700,
		useNextVariants: true,
		button: {
			textTransform: 'none',
		},
		h1: {
			fontFamily: '"Lato", sans-serif',
		},
	},
	zIndex: {
		modal: 1290,
	},
	components: {
		MuiCssBaseline: {
			styleOverrides: ({ theme }) => ({
				'h1,h2,h3,h4,h5,h6': {
					fontFamily: theme.typography.headingFontFamily,
					fontWeight: theme.typography.headingFontWeight,
					color: theme.palette.text.heading,
				},
			}),
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					'-webkit-print-color-adjust': 'exact !important',
					'print-color-adjust': 'exact !important',
				},
			},
		},
		MuiButtonBase: {
			styleOverrides: {
				root: {
					'-webkit-print-color-adjust': 'exact !important',
					'print-color-adjust': 'exact !important',
				},
			},
		},
		MuiButton: {
			defaultProps: {
				disableElevation: true,
			},
			styleOverrides: {
				outlinedSizeLarge: { padding: '13px 30px' },
				containedSizeLarge: { padding: '13px 30px' },
			},
		},
		MuiLoadingButton: {
			styleOverrides: {
				loadingIndicatorStart: { left: '28px' }, // This one should most likely only be used for large sized buttons
			},
		},
		MuiInputBase: {
			defaultProps: {
				variant: 'standard',
			},
			styleOverrides: {
				root: ({ theme }) => ({
					backgroundColor: theme.palette.text.white,

					'&$disabled': {
						opacity: 0.6,
					},
				}),
			},
		},
		MuiBackdrop: {
			styleOverrides: {
				root: {
					background: 'rgba(41, 20, 25, 0.15)',
					backgroundColor: 'rgba(41, 20, 25, 0.15)',
				},
			},
		},
		MuiDrawer: {
			styleOverrides: {
				paper: {
					background: '#fff',
					backgroundColor: '#fff',
				},
			},
		},
		MuiDivider: {
			styleOverrides: {
				root: {
					borderColor: 'rgba(0, 0, 0, 0.12)',
				},
			},
		},
		MuiIcon: {
			styleOverrides: {
				root: {
					textAlign: 'center',
				},
			},
		},
		MuiSnackbar: {
			styleOverrides: {
				anchorOriginTopCenter: { top: '12px !important' },
			},
		},
		MuiAccordion: {
			styleOverrides: {
				root: ({ theme }) => ({
					backgroundColor: theme.palette.background.default,
				}),
			},
		},
		MuiAlert: {
			root: {
				gridColumn: '1/-1',
			},
		},
		MuiTooltip: {
			styleOverrides: {
				tooltip: ({ theme }) => ({
					fontSize: '16px',
					borderRadius: '4px',
					boxShadow: '0 1px 2px rgba(0, 0, 0, 0.2)',
					color: theme.palette.text.white,
					padding: '8px 12px',
					textAlign: 'left',
					lineHeight: '1.5',
				}),
			},
		},
		MuiTab: {
			styleOverrides: {
				textColorPrimary: ({ theme }) => ({
					color: theme.palette.common.black,

					'&:hover': {
						color: theme.palette.primary.main,
					},
				}),
			},
		},
		MuiDialog: {
			styleOverrides: {
				paper: ({ theme }) => ({
					backgroundColor: theme.palette.text.white,
				}),
			},
		},
		MuiBox: {
			styleOverrides: {
				root: ({ theme }) => ({
					backgroundColor: theme.palette.text.white,
				}),
			},
		},
		MuiTableCell: {
			styleOverrides: {
				head: {
					fontWeight: '600',
				},
			},
		},
	},
});

// Debug theme info
// console.info(theme);

export default theme;
