import { useState } from 'react';

import styled from 'styled-components';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import NotificationsIcon from '@mui/icons-material/Notifications';

import ResendSigningRequestDialog from 'web/screens/dialogs/companyContracts/ResendSigningRequestDialog';

import { UnderlineLink } from 'web/components/Link';

const Contract = styled.div`
	margin-bottom: 20px;
`;

const Signees = styled.div`
	background-color: ${({ theme }) => theme.palette.background.paper};
	margin: 20px 0 0 0;
	padding: 20px;
`;

export default function CompanyContractsDialog({ contracts, open, onClose }) {
	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth={true}
			maxWidth="sm"
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">
				Oppdragsavtaler som venter på signering
			</DialogTitle>

			<DialogContent>
				<CompanyContractsDialogContent
					items={contracts}
					link={id => `/bedrifter/${id}/rediger/?tab=oppdrag`}
				/>
			</DialogContent>

			<DialogActions>
				<Button onClick={onClose} variant="cotained">
					Lukk
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export function CompanyContractsDialogContent({ items, link }) {
	return (
		<>
			{items?.length > 0 ? (
				<div>
					{items.map(entry => (
						<Contract key={entry._id}>
							<div>
								<UnderlineLink to={link(entry.company._id)}>
									{entry.company.name}
								</UnderlineLink>
							</div>

							<Signees>
								{entry.signingUsers.map(user => (
									<Signee
										key={user._id}
										signee={user}
										contractId={entry._id}
									/>
								))}
							</Signees>
						</Contract>
					))}
				</div>
			) : (
				<div>Ingen kontrakter mangler signering!</div>
			)}
		</>
	);
}

const SigneeWrapper = styled.div`
	margin-bottom: 10px;

	&:last-of-type {
		margin-bottom: 0;
	}
`;

const SigneeContent = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`;

const SigneeName = styled.div`
	flex: 1;
`;

const SignatureStatus = styled.div`
	background-color: ${({ $signed, theme }) =>
		$signed
			? theme.palette.status.light.green
			: theme.palette.status.light.yellow};
	border-radius: 50px;
	font-size: 14px;
	min-width: 70px;
	padding: 5px 10px;
	text-align: center;
`;

function Signee({ signee, contractId }) {
	const [openDialog, setOpenDialog] = useState(false);

	return (
		<SigneeWrapper>
			<SigneeContent>
				<SigneeName>{signee.name}</SigneeName>

				<Tooltip title="Send påminnelse">
					<div>
						<IconButton
							disabled={signee.signed}
							onClick={() => setOpenDialog(true)}
							aria-label="Send påminnelse"
						>
							<NotificationsIcon fontSize="inherit" />
						</IconButton>
					</div>
				</Tooltip>

				<SignatureStatus $signed={signee.signed}>
					{signee.signed ? 'Signert' : 'Venter'}
				</SignatureStatus>
			</SigneeContent>

			<ResendSigningRequestDialog
				contractId={contractId}
				signeeId={signee._id}
				signeeName={signee.name}
				signeeAccessKey={signee.accessKey}
				open={openDialog}
				onClose={() => setOpenDialog(false)}
			/>
		</SigneeWrapper>
	);
}
