import { useEffect } from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import MenuIcon from '@mui/icons-material/Menu';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import LogoImage from 'assets/images/logo.svg';

import { useWebApp } from 'web/contexts/WebContexts';
import { useUser } from 'web/contexts/UserContext';

import MainMenu from 'web/menus/MainMenu';
import Link from 'web/components/Link';

import {
	Wrapper,
	Header,
	LeftHeaderSection,
	MenuButton,
	Logo,
	BreadcrumbWrapper,
	BreadcumbElement,
	HelpButton,
	Main,
	Content as LayoutContent,
} from 'components/DesktopLayout';

export const ArchiveHeader = styled.header`
	margin-bottom: 40px;
`;

export const ArchiveHeaderRow = styled.div`
	display: flex;
	align-items: center;
	gap: 20px;
	margin-bottom: 20px;
`;

export const Content = LayoutContent;

export const NarrowContent = styled.div`
	box-sizing: border-box;
	max-width: 610px;
	margin: auto;
	padding: 105px 30px 30px;
	width: 100%;
`;

export const MediumContent = styled.div`
	max-width: 850px;
	margin: 0 auto;
	padding: 105px 30px 30px;
	width: 100%;

	@media print {
		max-width: 100%;
		padding: 0;
	}
`;

export const WideContent = styled.div`
	max-width: 1200px;
	margin: 0 auto;
	padding: 105px 30px 30px;
	width: 100%;
`;

export const TwoColumns = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 30px;
`;

export default function Layout({
	children,
	loading = false,
	breadcrumbs = [],
	title = '',
	menu = <MainMenu />,
}) {
	const { pathname } = useLocation();

	const {
		data: { isSidebarOpen },
		set,
	} = useWebApp();
	const { userHasMinRole } = useUser();

	useDocumentTitle(title, breadcrumbs);

	return (
		<Wrapper>
			<Header>
				<LeftHeaderSection>
					<MenuButton
						onClick={() => {
							set({ isSidebarOpen: !isSidebarOpen });
						}}
					>
						<MenuIcon />
					</MenuButton>

					<Logo src={LogoImage} />
				</LeftHeaderSection>

				{!loading && (
					<BreadcrumbWrapper>
						{pathname !== '/' && (
							<BreadcumbElement>
								<Link to="/">Hjem</Link>
							</BreadcumbElement>
						)}

						{breadcrumbs.map((breadcrumb, i) => {
							if (!breadcrumb) return null;

							if (breadcrumb.to) {
								return (
									<BreadcumbElement key={i}>
										<Link to={breadcrumb.to}>
											{breadcrumb.label}
										</Link>
									</BreadcumbElement>
								);
							}

							return (
								<BreadcumbElement key={i}>
									{breadcrumb.label}
								</BreadcumbElement>
							);
						})}
					</BreadcrumbWrapper>
				)}

				{userHasMinRole('accountant') && (
					<HelpButton to="/hjelp">
						<HelpOutlineIcon />
					</HelpButton>
				)}
			</Header>

			{menu}

			<Main $isSidebarOpen={isSidebarOpen}>{children}</Main>
		</Wrapper>
	);
}

export const StickyAction = styled.div`
	display: flex;
	align-items: center;
	gap: 20px;
	position: fixed;
	top: 8px;
	right: 70px;
	z-index: 99;

	@media print {
		display: none;
	}
`;

function useDocumentTitle(title, breadcrumbs) {
	const defaultTitle = 'Admento Falstad AS';
	const titleAfter = ' | ' + defaultTitle;

	useEffect(() => {
		let newTitle = '';
		if (title) {
			newTitle = title + titleAfter;
		} else if (breadcrumbs.length) {
			const breadcrumbTitle = breadcrumbs[breadcrumbs.length - 1]?.label;

			if (breadcrumbTitle) {
				newTitle = breadcrumbTitle + titleAfter;
			} else {
				newTitle = defaultTitle;
			}
		} else {
			newTitle = defaultTitle;
		}

		document.title = newTitle;
	}, [title, breadcrumbs.length]);
}
