import dayjs from 'utils/dates';

/**
 * Pluralize a word.
 */
export function pluralize(singular, plural, array) {
	if (!Array.isArray(array) || array.length === 1) return singular;

	return plural;
}

/**
 * Checks if the given phone number is valid.
 *
 * @param {string} phone - The phone number to validate.
 * @returns {boolean} - Returns true if the phone number is valid, otherwise false.
 */
export function validatePhone(phone) {
	const phoneRegex = /^\+?[1-9]\d{1,14}$/;

	return phoneRegex.test(phone);
}

/**
 * Checks if the given email address is valid.
 *
 * @param {string} email - The email address to validate.
 * @returns {boolean} - Returns true if the email address is valid, otherwise false.
 */
export function validateEmail(email) {
	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

	return emailRegex.test(email);
}

/**
 * Capitalizes the first letter of the given string.
 *
 * @param {string} str - The string to be capitalized.
 * @return {string} The string with the first letter capitalized.
 */
export function capitalize(str) {
	if (!str) return str; // Return the original string if it's falsy (e.g., "", null, undefined)

	return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * Removes the file extension from a given filename.
 *
 * @param {string} filename - The filename from which to remove the extension.
 * @returns {string} The filename without the extension.
 */
export function removeFileExtension(filename) {
	const parts = filename.split('.');

	if (parts.length > 1) {
		return parts.slice(0, -1).join('.');
	} else {
		return filename;
	}
}

/**
 * Returns the file extension from a given filename.
 *
 * @param {string} filename - The filename from which to extract the extension.
 * @returns {string} The file extension (without the dot).
 */
export function getFileExtension(filename) {
	const parts = filename.split('.');

	if (parts.length > 1) {
		return parts.pop();
	} else {
		return '';
	}
}

/**
 * Converts a given string into a URL-compatible slug.
 *
 * The function performs the following steps:
 * 1. Ensures the input is a string.
 * 2. Trims leading and trailing whitespace.
 * 3. Converts the string to lowercase.
 * 4. Normalizes Unicode characters (e.g., æ -> ae).
 * 5. Removes diacritical marks (accents) from letters.
 * 6. Removes all non-alphanumeric characters except spaces and hyphens.
 * 7. Replaces spaces with hyphens.
 * 8. Collapses multiple consecutive hyphens into a single hyphen.
 * 9. Removes hyphens from the start and end of the string.
 *
 * @param {string} input - The string to convert into a slug.
 * @returns {string} - The URL-compatible slug.
 */
export function slugify(input) {
	return input
		.toString() // Ensure the input is a string
		.normalize('NFD') // Normalize Unicode characters (e.g., æ -> ae)
		.replace(/[\u0300-\u036f]/g, '') // Remove diacritical marks
		.trim() // Remove leading and trailing whitespace
		.toLowerCase() // Convert to lowercase
		.replace(/[^a-z0-9\s-]/g, '') // Remove all non-alphanumeric characters except spaces and hyphens
		.replace(/\s+/g, '-') // Replace spaces with hyphens
		.replace(/-+/g, '-') // Replace multiple hyphens with a single hyphen
		.replace(/^-+|-+$/g, ''); // Remove hyphens from the start and end
}

/**
 * Converts a string to a filename slug with optinal date and format.
 *
 * @param {string|string[]} filename - The input string or array of strings to convert to a slug.
 * @param {string|null} fileExtension - Optional file extension to append to the slug.
 * @param {Object} options - Additional options for slug generation.
 * @param {string} [options.separator='-'] - The separator used to replace spaces.
 * @param {boolean} [options.includeDate=true] - Whether to include the current date in the slug.
 * @param {string} [options.dateFormat='YYYY-MM-DD'] - The format of the date to include in the slug.
 * @param {string} [options.dateSeperator='-'] - The separator between the date and the rest of the slug.
 * @param {Date} [options.date=new Date()] - The date to include in the slug.
 *  @param {boolean} [options.lowercase=true] - Whether to use all lowercase or not.
 * @returns {string} The slugified string.
 */
export function fileSlugify(filename = '', fileExtension = null, options = {}) {
	// Destructure options with default values
	const {
		separator = '-',
		includeDate = false,
		dateFormat = 'YYYY-MM-DD',
		dateSeperator = '-',
		date = new Date(),
		lowercase = true,
	} = options;

	let slug = Array.isArray(filename) ? filename.join(' ') : String(filename);

	if (lowercase) {
		slug = slug.toLowerCase();
	}

	slug = slug
		.replace(/[^\p{L}\p{N}._\s-]+/gu, '') // Remove all non-word characters except for letters, numbers, periods, underscores, dashes, and spaces
		.replace(/\s+/g, separator) // Replace spaces with separator
		.replace(new RegExp(`^${separator}+`), '') // Trim separator from start of text
		.replace(new RegExp(`${separator}+$`), ''); // Trim separator from end of text

	if (includeDate) {
		slug = `${dayjs(date).format(dateFormat)}${dateSeperator}${slug}`;
	}

	return fileExtension ? `${slug}.${fileExtension}` : slug;
}
