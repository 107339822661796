import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router';
import ButtonBase from '@mui/material/ButtonBase';

import { formatDate } from 'utils/dates';

import AnnualSettlementStatusDialog from 'web/screens/dialogs/companies/AnnualSettlementStatusDialog';

import { AnnualSettlementControlStatusPill } from 'components/Pill';

import { GET_COMPANY_CONTROL_FOR_COMPANY } from 'api/queries/companyControlQueries';

const thisYear = new Date().getFullYear();
const previousYear = thisYear - 1;

export default function AnnualSettlementControlStatus({
	companyId,
	selfRevisionRequired = false,
	reviewedAnnualFinancialStatus = null,
}) {
	const [isOpenDialog, setIsOpenDialog] = useState(false);

	const { data: { control } = { control: null }, loading } = useQuery(
		GET_COMPANY_CONTROL_FOR_COMPANY,
		{
			variables: {
				companyId,
				controlTypeValue: 'arsregnskap-skattemelding',
				year: previousYear,
			},
		}
	);

	if (loading) {
		return null;
	}

	if (!control) {
		if (reviewedAnnualFinancialStatus?.readyForReview) {
			return (
				<Link to={`/bedrifter/${companyId}/rediger/?tab=skjema`}>
					<AnnualSettlementControlStatusPill
						$statusCode="READY"
						title={`Årsregnskap ${thisYear} satt klart ${formatDate(
							reviewedAnnualFinancialStatus.reviewedAt,
							'DD.MM.YY [kl] HH:mm'
						)}`}
					>
						Klar til kontroll
					</AnnualSettlementControlStatusPill>
				</Link>
			);
		}

		if (reviewedAnnualFinancialStatus?.notRelevant) {
			return (
				<Link to={`/bedrifter/${companyId}/rediger/?tab=skjema`}>
					<AnnualSettlementControlStatusPill title="Ikke relavant">
						Ikke relevant
					</AnnualSettlementControlStatusPill>
				</Link>
			);
		}

		return (
			<div>
				<ButtonBase onClick={() => setIsOpenDialog(true)}>
					<AnnualSettlementControlStatusPill
						$statusCode={selfRevisionRequired ? 'ERROR' : null}
						title={
							selfRevisionRequired
								? 'Sett klar til kontroll. Kontroll er påkrevd.'
								: 'Sett klar til kontroll. Kontroll er ikke påkrevd.'
						}
					>
						Sett klar
					</AnnualSettlementControlStatusPill>
				</ButtonBase>

				<AnnualSettlementStatusDialog
					companyId={companyId}
					isOpen={isOpenDialog}
					setIsOpen={setIsOpenDialog}
				/>
			</div>
		);
	}

	return (
		<Link to={`/bedrifter/${companyId}/kontroller/${control._id}/rediger/`}>
			<AnnualSettlementControlStatusPill
				$statusCode={control.status.code}
				title={`Oppdatert ${formatDate(
					control.status.updatedAt,
					'DD.MM.YY [kl] HH:mm'
				)}`}
			>
				{control.status.label}
			</AnnualSettlementControlStatusPill>
		</Link>
	);
}
