import { useState, useEffect } from 'react';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import { useNavigate, useParams } from 'react-router';
import Button from '@mui/material/Button';

import Card from 'components/Card';
import DocumentIcon from 'components/DocumentIcon';

import {
	DocumentTitleWrapper,
	DocumentTitle,
	ParentTitle,
	DocumentParagraph,
} from 'components/DocumentLayout';

import DesktopLayout, { NarrowContent } from 'web/components/Layout';
import SigneeForm from 'web/components/SigneeForm';

import { GET_COMPANY_DOCUMENTS } from 'api/queries/documentQueries';
import { SHARE_DOCUMENT } from 'api/mutations/documentMutations';
import { FIND_ONE_USER } from 'api/queries/userQueries';

export default function DocumentShare({ companyId }) {
	const { documentNodeId } = useParams();
	const navigate = useNavigate();
	const client = useApolloClient();

	const [sent, setSent] = useState(false);
	const [signees, setSignees] = useState([]); // TODO: Wrong wording since we are shareing a doc
	const [notified, setNotified] = useState([]);

	const {
		data: { documentNode } = {},
		loading,
		error,
	} = useQuery(GET_COMPANY_DOCUMENTS, {
		variables: {
			tenantId: companyId,
			parentId: documentNodeId,
		},
		errorPolicy: 'all',
	});

	const [
		sendSharingRequest,
		{ loading: sharingLoading, error: sharingError },
	] = useMutation(SHARE_DOCUMENT, { errorPolicy: 'all' });

	useEffect(() => {
		if (!signees) return;

		(async () => {
			const notifiedArray = [];
			await Promise.all(
				signees.map(async s => {
					if (s.userId) {
						const { data } = await client.query({
							query: FIND_ONE_USER,
							variables: {
								_id: s.userId,
							},
						});
						if (data && data.user) {
							notifiedArray.push(data.user.name);
						}
					}
					if (s.phone) {
						notifiedArray.push(s.phone);
					}
					if (s.email) {
						notifiedArray.push(s.email);
					}
				})
			);

			setNotified(notifiedArray);
		})();
	}, [signees]);

	return (
		<DesktopLayout
			pageTitle="Del"
			breadcrumbs={[
				{
					to: '/dokumenter/',
					label: 'Dokumenter',
				},
				{
					label: (documentNode && documentNode.name) || '...',
					to: '/dokumenter/' + documentNodeId + '/',
				},
				{
					label: 'Del',
				},
			]}
		>
			<NarrowContent>
				{!sent && (
					<SigneeForm
						singleLabel="Kontakt #"
						description=""
						loading={loading || sharingLoading}
						errors={[error, sharingError]}
						documentId={documentNodeId}
						companyId={companyId}
						onSubmit={async ({ signees }) => {
							setSignees(signees);

							const data = await sendSharingRequest({
								variables: {
									documentId: documentNodeId,
									shareWith: signees,
								},
							});

							if (!data.error) {
								setSent(true);
							}
						}}
						{...documentNode}
					/>
				)}
				{sent && documentNode && (
					<Card
						icon={
							<DocumentIcon
								needsSignature={false}
								hasSignature={false}
								fontSize="large"
							/>
						}
					>
						<DocumentTitleWrapper>
							<DocumentTitle>{documentNode.name}</DocumentTitle>

							{documentNode.parent &&
								documentNode.parent.name && (
									<ParentTitle>
										{' / '}
										{documentNode.parent.name}
									</ParentTitle>
								)}

							{signees && notified && (
								<div>
									<DocumentParagraph>
										{`Dokumentet er delt med ${notified.join(
											', '
										)}`}
									</DocumentParagraph>

									<DocumentParagraph>
										<Button
											variant="contained"
											color="primary"
											onClick={() =>
												navigate(
													`/dokumenter/${documentNodeId}/`
												)
											}
										>
											Tilbake til dokument
										</Button>
									</DocumentParagraph>
								</div>
							)}
						</DocumentTitleWrapper>
					</Card>
				)}
			</NarrowContent>
		</DesktopLayout>
	);
}
