import { useState } from 'react';
import styled from 'styled-components';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { useMutation } from '@apollo/client';
import FileCopyIcon from '@mui/icons-material/FileCopy';

import { formatDate } from 'utils/dates';
import { upload } from 'utils/fileHandling';
import { pluralize } from 'utils/strings';

import { useSnackbar } from 'web/contexts/SnackbarContext';

import { InlineLoading } from 'components/Loading';
import ErrorMessage from 'components/ErrorMessage';

import {
	UPDATE_DOCUMENT_NODE,
	CREATE_ONE_DOCUMENT_NODE,
} from 'api/mutations/documentMutations';

const DropArea = styled.div`
	position: relative;
`;

const DropAreaOverlay = styled.div`
	background-color: ${p => p.theme.palette.background.gray};
	border: 2px dashed #ccc;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 20px;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 99;
`;

const DropAreaOverlayContent = styled.div`
	text-align: center;
	opacity: 0.2;
`;

const DropAreaOverlayText = styled.div`
	clear: both;
	font-size: 18px;
	font-weight: 400;
	line-height: 30px;
	margin-top: 10px;
`;

export default function FileDropArea({ parentId, tenantId, children }) {
	const { notify } = useSnackbar();
	const [uploading, setUploading] = useState(false);
	const [error, setError] = useState(null);

	const [createDocument, { error: createDocumentError }] = useMutation(
		CREATE_ONE_DOCUMENT_NODE
	);
	const [updateDocument, { error: updateDocumentError }] =
		useMutation(UPDATE_DOCUMENT_NODE);

	const [{ canDrop, isOver }, drop] = useDrop(
		() => ({
			accept: [NativeTypes.FILE],
			async drop(item) {
				await handleFilesUpload(item.files);
			},
			canDrop() {
				return true;
			},
			collect: monitor => {
				return {
					isOver: monitor.isOver(),
					canDrop: monitor.canDrop(),
				};
			},
		}),
		[parentId, tenantId]
	);

	const isActive = canDrop && isOver;

	const handleFilesUpload = async files => {
		setError(null);
		setUploading(true);

		for (const file of files) {
			if (file.size > 15000000) {
				setUploading(false);
				setError('Hver fil må være mindre enn 15 MB!');
				return;
			}

			if (!file.type) {
				setUploading(false);
				setError('Du må laste opp gyldige filformater!');
				return;
			}

			const fileName =
				formatDate(new Date(), 'DD.MM.YYYY') + ' - ' + file.name;
			let data;

			try {
				({ data } = await createDocument({
					variables: {
						name: fileName,
						size: file.size,
						contentType: file.type,
						order: 2,
						parentId,
						tenantId,
					},
				}));
			} catch (err) {
				console.error(err);
				setUploading(false);
				return;
			}

			let documentState = 'ready';

			try {
				await upload(data.file.uploadUrl, file, {
					headers: {
						'Content-Type': file.type,
					},
				});
			} catch (err) {
				documentState = 'error';
				console.error(err);
			}

			try {
				await updateDocument({
					variables: {
						_id: data.file._id,
						status: documentState,
					},
					refetchQueries: ['paginateDocumentNodes'],
				});
			} catch (err) {
				console.error(err);
				setUploading(false);
			}
		}

		notify(pluralize('Filen', 'Filene', files) + ' ble lastet opp!');

		setUploading(false);
	};

	return (
		<DropArea ref={drop}>
			<ErrorMessage
				errors={[error, createDocumentError, updateDocumentError]}
			/>

			{children}

			{isActive && (
				<DropAreaOverlay>
					<DropAreaOverlayContent>
						<FileCopyIcon sx={{ fontSize: 55 }} />

						<div>Dra og slipp filen(e) dine her…</div>
					</DropAreaOverlayContent>
				</DropAreaOverlay>
			)}

			{uploading && (
				<DropAreaOverlay>
					<DropAreaOverlayContent>
						<InlineLoading />

						<DropAreaOverlayText>
							Laster opp filer. Vennligst vent…
						</DropAreaOverlayText>
					</DropAreaOverlayContent>
				</DropAreaOverlay>
			)}
		</DropArea>
	);
}
