import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { saveAs } from 'file-saver';
import styled from 'styled-components';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import Checkbox from '@mui/material/Checkbox';

import { formatDate } from 'utils/dates';
import { fileSlugify } from 'utils/strings';

import ErrorAlert from 'components/ErrorAlert';
import { TaskDeadlineEntryStatusPill } from 'components/Pill';

import { UnderlineLink } from 'web/components/Link';

const Actions = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 20px;
	margin-bottom: 20px;
`;

const Statistic = styled.div`
	color: ${p => p.theme.palette.status.subdued};
	font-style: italic;
	font-size: 0.8rem;
`;

const MissingFieldError = styled.div`
	color: ${p => p.theme.palette.status.error};
`;

import { SET_TASK_DEADLINE_ENTRIES_POSTPONED_STATUS_EXPORTED } from 'api/mutations/companyAssignmentTaskMutations';

export default function DataTable({ data, type, year, loading = false }) {
	const [selectedData, setSelectedData] = useState([]);

	const [
		setPostponedStatusExported,
		{
			loading: setPostponedStatusExportedLoading,
			error: setPostponedStatusExportedError,
		},
	] = useMutation(SET_TASK_DEADLINE_ENTRIES_POSTPONED_STATUS_EXPORTED, {
		refetchQueries: ['getCompaniesWithPostponedTaskDeadlineEntry'],
	});

	const availableData = data.filter(
		item =>
			!!item.company?.identifier &&
			!item.deadlineEntry.postponedStatus?.exported
	);

	const exportedData = data.filter(
		item => item.deadlineEntry.postponedStatus?.exported
	);

	function handleSelectAll() {
		if (selectedData.length > 0) {
			return setSelectedData([]);
		}

		setSelectedData(availableData);
	}

	function handleSelectRow(data) {
		if (selectedData.find(item => item.company._id === data.company._id)) {
			setSelectedData(prevData =>
				prevData.filter(item => item.company._id !== data.company._id)
			);
			return;
		}

		setSelectedData(prevData => [...prevData, data]);
	}

	return (
		<div>
			<ErrorAlert error={setPostponedStatusExportedError} />

			<Actions>
				{!loading && (
					<Statistic>
						{exportedData.length} av {data.length} eksportert
					</Statistic>
				)}

				<Button
					variant="contained"
					startIcon={<SaveAltIcon />}
					disabled={
						selectedData.length === 0 ||
						loading ||
						setPostponedStatusExportedLoading
					}
					onClick={async () => {
						try {
							if (selectedData.length === 0) {
								return alert(
									'Det er ingen utsatte oppgaver å eksportere.'
								);
							}

							const exportData = selectedData.map(
								item => item.company.identifier
							);

							const fileData = exportData.join('\n');
							const fileBlob = new Blob([fileData], {
								type: 'text/csv;charset=utf-8;',
							});

							const fileName = fileSlugify(
								['bedrifter med utsatt frist', type, year],
								'csv',
								{ includeDate: true }
							);

							saveAs(fileBlob, fileName);

							const deadlineEntryIds = selectedData.map(
								item => item.deadlineEntry._id
							);

							const { data } = await setPostponedStatusExported({
								variables: {
									deadlineEntryIds,
								},
							});

							if (!data.updated) {
								throw new Error(
									'Failed to update postponed exported status'
								);
							}

							setSelectedData([]);
						} catch (err) {
							console.error('Error exporting data:', err);
						}
					}}
				>
					Eksporter data
				</Button>
			</Actions>

			<TableContainer>
				<Table aria-label="Tabell over bedrifter med utsatt oppgavefrist">
					<TableHead>
						<TableRow>
							<TableCell padding="checkbox">
								<Checkbox
									color="primary"
									indeterminate={
										selectedData.length > 0 &&
										selectedData.length <
											availableData.length
									}
									checked={selectedData.length > 0}
									onChange={handleSelectAll}
									inputProps={{
										'aria-label': 'Velg alle bedrifter',
									}}
								/>
							</TableCell>

							<TableCell>Bedrift</TableCell>

							<TableCell>Org.nr/F.nr</TableCell>

							<TableCell>Selskapstype</TableCell>

							<TableCell>Status</TableCell>

							<TableCell>Hvem søker?</TableCell>

							<TableCell>Utsatt til</TableCell>

							<TableCell>Eksportert?</TableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						<DataTableRows
							data={data}
							loading={loading}
							selected={selectedData}
							onSelect={handleSelectRow}
						/>
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	);
}

function DataTableRows({ data, loading, selected, onSelect }) {
	if (loading) {
		return <LoadingTableRow />;
	}

	if (data.length === 0) {
		return <EmptyDataTableRow />;
	}

	return data.map(item => (
		<DataTableRow
			key={item.company._id}
			item={item}
			isSelected={
				!!selected.find(
					selectedItem =>
						selectedItem.company._id === item.company._id
				)
			}
			onSelect={onSelect}
		/>
	));
}

function LoadingTableRow() {
	return (
		<TableRow>
			<TableCell colSpan={7}>Laster data…</TableCell>
		</TableRow>
	);
}

function EmptyDataTableRow() {
	return (
		<TableRow>
			<TableCell colSpan={7}>
				Ingen bedrifter med utsatt oppgavefrist ble funnet
			</TableCell>
		</TableRow>
	);
}

function DataTableRow({ item, isSelected, onSelect }) {
	const isDisabled = !item.company?.identifier;

	return (
		<TableRow
			selected={isSelected}
			onClick={() => {
				if (isDisabled) return;

				onSelect(item);
			}}
			disabled={isDisabled}
			sx={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
			hover={!isDisabled}
		>
			<TableCell padding="checkbox">
				<Checkbox
					color="primary"
					checked={isSelected}
					disabled={isDisabled}
					inputProps={{
						'aria-labelledby': item.company._id,
					}}
				/>
			</TableCell>

			<TableCell id={item.company._id}>
				<UnderlineLink to={`/bedrifter/${item.company._id}/rediger/`}>
					{item.company.name}
				</UnderlineLink>
			</TableCell>

			<TableCell>
				{item.company?.identifier ?? <MissingField />}
			</TableCell>

			<TableCell>{item.company?.type ?? <MissingField />}</TableCell>

			<TableCell>
				<TaskDeadlineEntryStatusPill
					$statusCode={item.deadlineEntry?.status}
				>
					{item.deadlineEntry?.status ?? 'Ikke startet'}
				</TaskDeadlineEntryStatusPill>
			</TableCell>

			<TableCell>{item.deadlineEntry.postponedStatus?.status}</TableCell>

			<TableCell>
				{formatDate(item.deadlineEntry.date, 'DD.MM.YYYY')}
			</TableCell>

			<TableCell align="center">
				{item.deadlineEntry.postponedStatus?.exported && (
					<TaskAltIcon color="success" />
				)}
			</TableCell>
		</TableRow>
	);
}

function MissingField() {
	return <MissingFieldError>Mangler!</MissingFieldError>;
}
