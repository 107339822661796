import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router';
import SaveIcon from '@mui/icons-material/Save';

import { useSnackbar } from 'web/contexts/SnackbarContext';

import ErrorAlert from 'components/ErrorAlert';

import DesktopLayout, { NarrowContent } from 'web/components/Layout';

import Form from 'components/form/Form';
import TextField from 'components/form/TextField';
import SubmitButton from 'components/form/SubmitButton';

import { INSERT_TENANT_CATEGORY } from 'api/mutations/tenantCategoryMutations';

export default function CompanyCategoriesCreate() {
	const { notify } = useSnackbar();
	const navigate = useNavigate();

	const [createNewTenantCategory, { error, loading }] = useMutation(
		INSERT_TENANT_CATEGORY
	);

	return (
		<DesktopLayout
			breadcrumbs={[
				{
					to: '/bedriftskategorier/',
					label: 'Bedriftskategorier',
				},
				{
					label: 'Ny bedriftskategori',
				},
			]}
		>
			<NarrowContent>
				<Form
					isLoading={loading}
					isDisabled={loading}
					onSubmit={async variables => {
						try {
							const { data } = await createNewTenantCategory({
								variables,
							});

							if (data?.category?._id) {
								notify('Bedriftskategori opprettet');

								navigate('/bedriftskategorier/');
							}
						} catch (err) {
							console.error(err);
						}
					}}
				>
					<ErrorAlert error={error} />

					<TextField name="name" label="Navn" />

					<SubmitButton
						variant="contained"
						size="large"
						icon={<SaveIcon />}
						fullWidth
					>
						Ny kategori
					</SubmitButton>
				</Form>
			</NarrowContent>
		</DesktopLayout>
	);
}
