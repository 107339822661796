import { useAppFilters, actionTypes } from 'web/contexts/AppFiltersContext';

import UsersFilterSelect from 'components/filters/components/UsersFilterSelect';

export default function PayrollManagerFilter({
	disabled = false,
	size = 'medium',
}) {
	const { payrollManager, dispatch } = useAppFilters();

	return (
		<UsersFilterSelect
			title="Lønnsansvarlig"
			roles={['admin', 'accountant']}
			value={payrollManager}
			onChange={value =>
				dispatch({
					type: actionTypes.SET_PAYROLL_MANAGER,
					payload: value,
				})
			}
			disabled={disabled}
			size={size}
		/>
	);
}
