const localStorageKey = process.env.REACT_APP_SETTINGS_KEY;

export const getLocalStorageState = (key, defaultValue) => {
	const storedSettings = localStorage.getItem(localStorageKey);
	const parsedSettings = storedSettings ? JSON.parse(storedSettings) : {};

	return key in parsedSettings ? parsedSettings[key] : defaultValue;
};

export const setLocalStorageState = (key, value) => {
	const storedSettings = localStorage.getItem(localStorageKey);
	const parsedSettings = storedSettings ? JSON.parse(storedSettings) : {};

	const updatedSettings = {
		...parsedSettings,
		[key]: value,
	};

	localStorage.setItem(localStorageKey, JSON.stringify(updatedSettings));
};
