import { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useQuery } from '@apollo/client';
import { useWebApp } from 'web/contexts/WebContexts';
import styled from 'styled-components';
import debounce from 'lodash/debounce';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';

import logo from 'assets/images/logo-full.svg';

import ErrorMessage from 'components/ErrorMessage';

import { GET_MY_ACCOUNTS } from 'api/queries/tenantQueries';

const SelectTenantWrapper = styled.div`
	background-color: ${p => p.theme.palette.background.gray};
`;

const Inner = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100vh;
	padding: 10vh 20px 20px;
	max-width: 350px;
	max-width: 500px;
	margin: 0 auto;
`;

const Logo = styled.img`
	width: 100%;
	height: auto;
	max-width: 238px;
	margin-bottom: 18px;
`;

const Title = styled.h1`
	font-size: ${22 / 16}rem;
	margin: 0 0 40px 0;
`;

const AccountsWrapper = styled.div`
	box-shadow: 3px 0 10px rgb(98 15 35 / 6%);
	border-radius: 4px;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 500px;
	max-height: 430px;
	max-width: 100%;
`;

const StyledList = styled(List)`
	max-height: 310px;
	width: 100%;
	overflow-y: ${p => (p.count > 6 ? 'scroll' : 'hidden')};

	li[role='separator']:last-child {
		display: none;
	}
`;

const StyledTextField = styled(TextField)`
	width: 100%;
	margin-bottom: 8px;
`;

export default function SelectTenant() {
	const { set } = useWebApp();
	const location = useLocation();
	const navigate = useNavigate();

	const [search, setSearch] = useState('');
	const [showSearch, setShowSearch] = useState(false);
	const [initialCount, setInitialCount] = useState(0);

	const {
		data: { accounts = { count: 0, items: [] } } = {
			accounts: { count: 0, items: [] },
		},
		loading,
		error,
		fetchMore,
	} = useQuery(GET_MY_ACCOUNTS, {
		variables: {
			search,
			limit: 100,
		},
		fetchPolicy: 'cache-and-network',
		errorPolicy: 'all',
		notifyOnNetworkStatusChange: true,
	});

	useEffect(() => {
		if (!initialCount && accounts.count > 0) {
			setInitialCount(accounts.count);
			setShowSearch(accounts.items.length > 6);
		}
	}, [accounts]);

	const handleChange = debounce(value => setSearch(value), 750);

	const handleScroll = async e => {
		const bottom =
			e.target.scrollHeight - e.target.scrollTop ===
			e.target.clientHeight;

		const skip = accounts.items.length;
		const loadMore = accounts.count > accounts.items.length;

		if (bottom && loadMore) {
			await fetchMore({
				variables: { skip: skip },
				updateQuery: (prev, { fetchMoreResult }) => {
					if (!fetchMoreResult.accounts.items.length === 0)
						return prev;
					return {
						...prev,
						accounts: {
							...prev.accounts,
							items: [
								...prev.accounts.items,
								...fetchMoreResult.accounts.items,
							],
						},
					};
				},
			});
		}
	};

	const handleListItemClick = ({ _id, name }) => {
		set({
			tenant: {
				_id,
				name,
			},
		});

		navigate(location.state?.from || '/');
	};

	return (
		<SelectTenantWrapper>
			<Inner>
				<Logo src={logo} alt="Admento Falstad AS" />

				<Title>Admento Falstad</Title>

				{error && <ErrorMessage errors={error} />}

				{!error && (
					<>
						{!loading &&
							initialCount === 0 &&
							'Du er ikke tilknyttet noen kontoer enda...'}

						{initialCount > 0 && (
							<>
								{showSearch ? (
									<StyledTextField
										type="search"
										label="Søk etter bedrift"
										fullWidth={true}
										onChange={({ target }) =>
											handleChange(target.value)
										}
									/>
								) : (
									<p>
										<strong>Velg bedrift</strong>
									</p>
								)}
								<AccountsWrapper>
									<StyledList
										onScroll={event => handleScroll(event)}
										count={accounts.items.length}
									>
										{accounts?.items?.map(tenant => (
											<Fragment key={tenant._id}>
												<ListItemButton
													onClick={event => {
														event.preventDefault();
														handleListItemClick(
															tenant
														);
													}}
												>
													<ListItemText>
														{tenant.notificationsCount ? (
															<Notifier
																name={
																	tenant.name
																}
																count={
																	tenant.notificationsCount
																}
															/>
														) : (
															tenant.name
														)}
													</ListItemText>
												</ListItemButton>
												<Divider component="li" />
											</Fragment>
										))}
									</StyledList>
								</AccountsWrapper>
							</>
						)}
					</>
				)}

				{loading && <CircularProgress />}
			</Inner>
		</SelectTenantWrapper>
	);
}

const NotificationsLabel = styled.span`
	background-color: ${p => p.theme.palette.primary.main};
	font-size: 0.8em;
	width: 20px;
	height: 20px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	line-height: 24px;
	color: #ffffff;
	border-radius: 100%;
	margin-left: 5px;
	transform: translateY(-4px);
`;

function Notifier({ name, count }) {
	return (
		<>
			{name}
			<NotificationsLabel>{count}</NotificationsLabel>
		</>
	);
}
