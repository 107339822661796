import { gql } from '@apollo/client';

export const FIND_TENANTS = gql`
	query findTenants($order: Int, $orderBy: String) {
		companies: findTenants(order: $order, orderBy: $orderBy) {
			_id
			name
		}
	}
`;

export const PAGINATE_TENANTS = gql`
	query paginateTenants(
		$search: String
		$filter: AppFilterInput
		$order: Int
		$orderBy: String
		$offset: Int
		$limit: Int
	) {
		companies: paginateTenants(
			search: $search
			filter: $filter
			order: $order
			orderBy: $orderBy
			skip: $offset
			limit: $limit
		) {
			count
			items {
				_id
				name
				accountTypes
				freeText
				isInactive
				annualFinancialStatus {
					readyForReview
					reviewedYear
				}
				reviewedAnnualFinancialStatus {
					readyForReview
					reviewedByDisplayName
					reviewedAt
					notRelevant
				}
				dataDeviations
				selfRevisionRequired
			}
		}
	}
`;

export const GET_ONE_COMPANY = gql`
	query findOneTenant($_id: ID!) {
		company: findOneTenant(_id: $_id) {
			_id
			accountTypes
			name
			companyType {
				id
				name
			}
			clientNo
			orgNo
			phone
			email
			signatories
			procurations
			isVatRegistered
			isAaRegistered
			accountingFirms {
				companyId
				name
				role
				title
			}
			businessAddress {
				streetAddress
				postalCode
				locality
			}
			postalAddress {
				streetAddress
				postalCode
				locality
			}
			accountantIds
			accountants {
				name
			}
			projectManagerIds
			projectManagers {
				name
			}
			accountManagerIds
			accountManagers {
				name
			}
			payrollManagerIds
			payrollManagers {
				name
			}
			annualAccountsManagerIds
			annualAccountsManagers {
				name
			}
			advisorIds
			advisors {
				name
			}
			controllerIds
			controllers {
				name
			}
			auditorIds
			auditors {
				name
			}
			departmentId
			notificationsCount
			categoryIds
			freeText
			isInactive
			userRoles {
				id
				role
			}
			boardMembers(
				roles: [
					"innehaver"
					"dagligleder"
					"nestleder"
					"styreleder"
					"styremedlem"
				]
			) {
				_id
				name
				userRole
			}
			owners {
				user {
					_id
					name
				}
				note
				shareInPercent
			}
			proffData
			dataDeviations
			proffDataDeviationExceptions {
				type
				name
				role
			}
			isFavorite
			annualFinancialStatus {
				readyForReview
				reviewedYear
				reviewedByDisplayName
				reviewedAt
				notRelevant
			}
			createdAt
			createdByDisplayName
			updatedAt
			updatedByDisplayName
		}
	}
`;

export const GET_TENANT_USERS = gql`
	query getTenantUsers($tenantId: ID!) {
		users: getTenantUsers(tenantId: $tenantId) {
			_id
			name
			email
			phone
		}
	}
`;

export const PAGINATE_MY_TENANTS = gql`
	query paginateMyTenants(
		$search: String
		$order: Int
		$orderBy: String
		$skip: Int
		$limit: Int
	) {
		companies: paginateMyTenants(
			search: $search
			order: $order
			orderBy: $orderBy
			skip: $skip
			limit: $limit
		) {
			count
			items {
				_id
				name
			}
		}
	}
`;

export const GET_MY_ACCOUNTS = gql`
	query getMyAccounts($limit: Int, $skip: Int, $search: String) {
		accounts: paginateMyTenants(
			limit: $limit
			skip: $skip
			search: $search
		) {
			count
			items {
				_id
				name
				notificationsCount
				userRoles {
					id
					role
				}
			}
		}
	}
`;

export const FIND_COMPANIES_EXPORT_DATA = gql`
	query findCompaniesExportData(
		$fromDate: DateTime!
		$toDate: DateTime!
		$departmentId: ID
	) {
		companies: findCompaniesExportData(
			fromDate: $fromDate
			toDate: $toDate
			departmentId: $departmentId
		) {
			name
			orgNo
			companyType {
				id
				name
			}
			clientNo
			sizeUsed
			ceoCount
			chairmanCount
			boardmemberCount
			auditorCount
			signedDocumentsCount
			signedCount
			proffData
		}
	}
`;

export const GET_PROFFDATA = gql`
	query getProffData($orgNo: ID!) {
		proffData: getProffData(orgNo: $orgNo) {
			_id
			proffData
		}
	}
`;

export const GET_COMPANY_USERS_MANUALLY_VERIFIED = gql`
	query getCompanyUsersManuallyVerified($companyId: ID!) {
		users: getCompanyUsersManuallyVerified(companyId: $companyId) {
			_id
			name
			role
			userVerifications {
				type
				lastSent
			}
		}
	}
`;
