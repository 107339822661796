import theme from 'web/theme/theme';

import { scoreColor } from 'utils/colors';

import useChildTotal from 'hooks/useChildTotal';

import {
	WidgetList,
	WidgetListItem,
	WidgetListLabel,
} from 'web/components/widgets/components/WidgetList';
import WidgetListData from 'web/components/widgets/components/WidgetListData';

import Widget from 'web/components/widgets/Widget';

import {
	COMPANIES_MISSING_RISKS,
	COMPANIES_NEED_RISKS_REVISION,
	COMPANIES_WITH_LOW_RISK_SCORE,
	COMPANIES_WITH_NORMAL_RISK_SCORE,
	COMPANIES_WITH_HIGH_RISK_SCORE,
} from 'api/queries/widgetQueries';

export default function CompanyRisksWidget() {
	const [total, updateTotal] = useChildTotal();

	return (
		<Widget title="Hvitvasking" titleIcon="circle-exclamation">
			<WidgetList>
				<WidgetListData
					label={
						<WidgetListLabel
							label="Mangler risikovurdering"
							chipColor={theme.palette.status.error}
						/>
					}
					query={COMPANIES_MISSING_RISKS}
					link={id => `/bedrifter/${id}/rediger/?tab=hvitvasking`}
				/>

				<WidgetListData
					label={
						<WidgetListLabel
							label="Trenger revisjon"
							chipColor={theme.palette.status.warning}
						/>
					}
					query={COMPANIES_NEED_RISKS_REVISION}
					link={id => `/bedrifter/${id}/rediger/?tab=hvitvasking`}
				/>
			</WidgetList>

			<WidgetList>
				<WidgetListData
					name="low"
					label={
						<WidgetListLabel
							label="Lav risiko"
							chipColor={scoreColor('Lav')}
						/>
					}
					query={COMPANIES_WITH_LOW_RISK_SCORE}
					link={id => `/bedrifter/${id}/rediger/?tab=hvitvasking`}
					total={total}
					onLoaded={updateTotal}
				/>

				<WidgetListData
					name="normal"
					label={
						<WidgetListLabel
							label="Normal risiko"
							chipColor={scoreColor('Normal')}
						/>
					}
					query={COMPANIES_WITH_NORMAL_RISK_SCORE}
					link={id => `/bedrifter/${id}/rediger/?tab=hvitvasking`}
					total={total}
					onLoaded={updateTotal}
				/>

				<WidgetListData
					name="high"
					label={
						<WidgetListLabel
							label="Høy risiko"
							chipColor={scoreColor('Høy')}
						/>
					}
					query={COMPANIES_WITH_HIGH_RISK_SCORE}
					link={id => `/bedrifter/${id}/rediger/?tab=hvitvasking`}
					total={total}
					onLoaded={updateTotal}
				/>
			</WidgetList>

			<WidgetList>
				<WidgetListItem label="Totalt" value={total} />
			</WidgetList>
		</Widget>
	);
}
