import Zoom from '@mui/material/Zoom';
import Fab from '@mui/material/Fab';
import Icon from 'components/Icon';
import styled from 'styled-components';
import theme from 'web/theme/theme';

const Wrapper = styled.div`
	position: fixed;
	bottom: 24px;
	right: 24px;
	z-index: 2;
`;

const StyledFab = styled(Fab)`
	&& {
		background: ${theme.palette.primary.main};
		color: white;
		font-size: 18px;
		font-weight: 400;
		&:hover,
		&:focus {
			background: ${theme.palette.primary.dark};
		}
	}
`;

export default function AddButton(props) {
	const transitionDuration = {
		enter: theme.transitions.duration.enteringScreen,
		exit: theme.transitions.duration.leavingScreen,
	};

	return (
		<Wrapper>
			<Zoom
				in={true}
				timeout={transitionDuration}
				style={{
					transitionDelay: `${transitionDuration.exit}ms`,
				}}
				unmountOnExit
			>
				<StyledFab {...props}>
					<Icon icon="plus" />
				</StyledFab>
			</Zoom>
		</Wrapper>
	);
}
