import { useState } from 'react';
import { useMutation } from '@apollo/client';
import styled from 'styled-components';

import CheckIcon from '@mui/icons-material/Check';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import EmailIcon from '@mui/icons-material/Email';

import { useSnackbar } from 'web/contexts/SnackbarContext';
import { useConfirmDialog } from 'components/contexts/ConfirmContext';

import {
	Dialog,
	DialogTitle,
	DialogCloseButton,
	DialogActions,
	DialogContent,
	Button,
} from 'components/dialogs/DialogBox';

import ErrorMessage from 'components/ErrorMessage';

import { SEND_COMPANY_CONTRACT_SIGNING_REQUEST_NOTIFICATION } from 'api/mutations/companyContractMutations';

export default function ResendSigningRequestDialog({
	contractId,
	signeeId,
	signeeName,
	signeeAccessKey,
	open,
	onClose,
}) {
	return (
		<Dialog
			open={open}
			onClose={onClose}
			aria-labelledby="alert-dialog-slide-title"
			aria-describedby="alert-dialog-slide-description"
			fullWidth={true}
			maxWidth="md"
		>
			<DialogTitle id="alert-dialog-slide-title">
				<div>Varsling om signering av kontrakt</div>

				<DialogCloseButton onClick={onClose} />
			</DialogTitle>

			<SigningRequestDialogContent
				contractId={contractId}
				signeeId={signeeId}
				signeeName={signeeName}
				signingRequestUrl={`${process.env.REACT_APP_CONTRACT_SIGN_URL}/avtaler/${contractId}?tilgangsnokkel=${signeeAccessKey}`}
				open={open}
				onClose={onClose}
			/>
		</Dialog>
	);
}

function SigningRequestDialogContent({
	contractId,
	signeeId,
	signeeName,
	signingRequestUrl,
	onClose,
}) {
	const { notify } = useSnackbar();
	const { verify } = useConfirmDialog();

	const [copied, setCopied] = useState(false);
	const [noticeSent, setNoticeSent] = useState(false);

	const [resendSigningRequest, { loading, error }] = useMutation(
		SEND_COMPANY_CONTRACT_SIGNING_REQUEST_NOTIFICATION
	);

	return (
		<>
			<DialogContent id="alert-dialog-slide-description">
				<ErrorMessage errors={[error]} />

				<div>
					Send ut et nytt varsel til <strong>{signeeName}</strong> om
					signering av kontrakten, eller kopier lenken til
					utklippstavlen.
				</div>

				<SigningRequestUrlField
					signingRequestUrl={signingRequestUrl}
					copied={copied}
					setCopied={setCopied}
				/>
			</DialogContent>

			<DialogActions>
				<Button onClick={onClose}>Lukk</Button>

				<Button
					variant="outlined"
					disabled={copied}
					startIcon={<ContentPasteIcon />}
					onClick={() => {
						setCopied(true);
						navigator.clipboard.writeText(signingRequestUrl);
						notify('Signeringslenke kopiert til utklippstavlen');
					}}
				>
					Kopier lenke
				</Button>

				<Button
					variant="contained"
					disabled={loading || noticeSent}
					startIcon={<EmailIcon />}
					onClick={async () => {
						try {
							if (
								!(await verify({
									title: 'Nytt e-postvarsel',
									text: `Er du sikker på at du ønsker å sende ut et nytt e-postvarsel til ${signeeName}?`,
								}))
							) {
								return;
							}

							const { data } = await resendSigningRequest({
								variables: {
									_id: contractId,
									signingUserId: signeeId,
								},
							});

							if (data) {
								setNoticeSent(true);

								notify('Nytt varsel ble sendt!');
							}
						} catch (err) {
							console.error(err);
						}
					}}
				>
					Send varsel
				</Button>
			</DialogActions>
		</>
	);
}

const SigningRequestUrlFieldWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
	margin: 20px 0;
	position: relative;
`;

const SigninLinkInput = styled.input`
	background: ${p => p.theme.palette.background.paper};
	border: 1px solid ${p => p.theme.palette.divider};
	cursor: pointer;
	padding: 12px;
	width: 100%;
`;

const StyledCheckIcon = styled(CheckIcon)`
	opacity: ${p => (p.$copied ? 1 : 0)};
	transition: opacity 0.5s ease-in-out;
	position: absolute;
	top: 8px;
	right: 15px;
`;

function SigningRequestUrlField({ signingRequestUrl, copied, setCopied }) {
	const { notify } = useSnackbar();

	return (
		<SigningRequestUrlFieldWrapper>
			<SigninLinkInput
				value={signingRequestUrl}
				onClick={() => {
					navigator.clipboard.writeText(signingRequestUrl);
					setCopied(true);

					notify('Signeringslenke kopiert til utklippstavlen');
				}}
				readOnly
			/>

			<StyledCheckIcon $copied={copied} color="success" />
		</SigningRequestUrlFieldWrapper>
	);
}
