import { useAppFilters, actionTypes } from 'web/contexts/AppFiltersContext';

import Filter from 'components/filters/Filter';

const options = [
	{ value: '', label: 'Alle kontotyper' },
	{ value: 'accounting', label: 'Regnskapskunde' },
	{ value: 'counseling', label: 'Rådgivningskunde' },
	{ value: 'lead', label: 'Potensiell kunde' },
];

export default function AccountTypeFilter({
	disabled = false,
	size = 'medium',
}) {
	const { accountType, dispatch } = useAppFilters();

	return (
		<Filter
			name="accounttype-filter"
			label="Vis kontotype"
			value={accountType || options[0]}
			options={options}
			onChange={value =>
				dispatch({
					type: actionTypes.SET_ACCOUNT_TYPE,
					payload: value,
				})
			}
			disabled={disabled}
			size={size}
		/>
	);
}
