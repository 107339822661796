import { useNavigate } from 'react-router';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';

import { useConfirmDialog } from 'components/contexts/ConfirmContext';
import { useSnackbar } from 'web/contexts/SnackbarContext';

export default function DeleteButton({ onClick, redirect = null, ...rest }) {
	const { verify } = useConfirmDialog();
	const { notify } = useSnackbar();
	const navigate = useNavigate();

	return (
		<Button
			startIcon={<DeleteIcon />}
			onClick={async () => {
				try {
					if (
						!(await verify({
							title: 'Slett',
							text: 'Er du sikker på at du vil slette elementet?',
						}))
					) {
						return;
					}

					await onClick();

					notify('Elementet ble slettet!');

					redirect && navigate(redirect);
				} catch (err) {
					console.error(err);

					notify(
						'Det oppstod en feil ved sletting av elementet!',
						'error'
					);
				}
			}}
			{...rest}
		>
			Slett
		</Button>
	);
}
