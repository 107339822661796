import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';

import { InlineLoading } from 'components/Loading';
import ErrorMessage from 'components/ErrorMessage';

import AssignmentTasksCalendarTimeline from 'web/components/AssignmentTasksCalendarTimeline';

import { GET_TASKS } from 'api/queries/companyAssignmentTaskQueries';

const Header = styled.div`
	display: flex;
	gap: 20px;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 40px;
`;

const Title = styled.h1`
	margin: 0;
`;

export default function AssignmentTasksTab({ company }) {
	const navigate = useNavigate();

	const {
		data: { tasks } = { tasks: [] },
		loading: tasksLoading,
		error: tasksError,
	} = useQuery(GET_TASKS, {
		variables: {
			companyId: company?._id,
			onlyActive: true,
		},
	});

	if (tasksLoading) return <InlineLoading />;

	if (tasksError) return <ErrorMessage errors={[tasksError]} />;

	if (tasks.length === 0)
		return (
			<Header>
				<div>
					<Title>Oppdragsutførelse</Title>

					<p>
						Det er ikke planlagt noen oppgaver for denne bedriften
						ennå.
					</p>
				</div>

				<Button
					type="button"
					variant="contained"
					startIcon={<AddIcon />}
					onClick={() =>
						navigate(`/bedrifter/${company._id}/oppdrag/ny`)
					}
				>
					Planlegg oppgaver
				</Button>
			</Header>
		);

	const tasksGroupedByCategory = tasks
		.reduce((categories, task) => {
			let categoryGroup = categories.find(
				group => group.category === task.category
			);

			if (!categoryGroup) {
				categoryGroup = {
					category: task.category,
					tasks: [],
				};

				categories.push(categoryGroup);
			}

			categoryGroup.tasks.push(task);

			return categories;
		}, [])
		.sort((a, b) => a.category.localeCompare(b.category));

	return (
		<AssignmentTasksCalendarTimeline
			companyId={company._id}
			data={tasksGroupedByCategory}
		/>
	);
}
