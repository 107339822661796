import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { useSnackbar } from 'web/contexts/SnackbarContext';

import ErrorAlert from 'components/ErrorAlert';

import Form, { AutoComplete } from 'web/form/Form';

import { INSERT_ONE_ASSIGNMENT_RISK } from 'api/mutations/assignmentRiskMutations';

export default function AssignmentRisksCreateDialog({
	existingRisks = [],
	companyId,
	open,
	onClose,
}) {
	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth={true}
			maxWidth="sm"
			aria-labelledby="dialog-title"
			aria-describedby="dialog-description"
		>
			<DialogTitle id="dialog-title">
				Opprett oppdragsrisikoanalyse
			</DialogTitle>

			<IconButton
				aria-label="close"
				onClick={onClose}
				sx={{
					position: 'absolute',
					right: 8,
					top: 8,
					color: theme => theme.palette.grey[500],
				}}
			>
				<CloseIcon />
			</IconButton>

			<AddAssignmentRisksForm
				existingRisks={existingRisks}
				companyId={companyId}
				onClose={onClose}
			/>
		</Dialog>
	);
}

const riskTypes = [
	{
		value: 'BUSINESS_RISKS',
		label: 'Virksomhetsforståelse (Om virksomheten)',
	},
	{
		value: 'SOFTWARE',
		label: 'Systemoversikt - Systemer involvert i oppdraget',
	},
	{
		value: 'ROUTINES',
		label: 'Oppdragsgivers interne rutiner',
	},
	{
		value: 'ASSESSMENTS',
		label: 'Grunnleggende intern oppdragsvurdering',
	},
];

function AddAssignmentRisksForm({ existingRisks, companyId, onClose }) {
	const { notify } = useSnackbar();
	const navigate = useNavigate();

	const [insertRisk, { loading, error }] = useMutation(
		INSERT_ONE_ASSIGNMENT_RISK,
		{ refetchQueries: ['getAssignmentRisks'] }
	);

	return (
		<Form
			onSubmit={async ({ type }) => {
				try {
					if (!type) {
						return;
					}

					const { data } = await insertRisk({
						variables: {
							type,
							companyId,
						},
					});

					if (data.risk?._id) {
						notify(`«${data.risk.name}» ble opprettet`);

						navigate(
							`/bedrifter/${companyId}/oppdragsrisiko/${data.risk._id}/rediger/`
						);
					}
				} catch (err) {
					console.error(err);
				}
			}}
		>
			<DialogContent>
				<ErrorAlert error={error} />

				<AutoComplete
					name="type"
					label="Velg oppdragsrisikoanalyse"
					options={riskTypes}
					getOptionDisabled={option =>
						existingRisks.some(
							existingRisk => existingRisk.type === option.value
						)
					}
				/>
			</DialogContent>

			<DialogActions>
				<Button onClick={onClose}>Avbryt</Button>

				<Button type="submit" variant="contained" disabled={loading}>
					Opprett
				</Button>
			</DialogActions>
		</Form>
	);
}
