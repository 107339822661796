import theme from 'web/theme/theme';

import {
	WidgetList,
	WidgetListLabel,
} from 'web/components/widgets/components/WidgetList';
import WidgetListData from 'web/components/widgets/components/WidgetListData';

import Widget from 'web/components/widgets/Widget';

import {
	COMPANIES_MISSING_ASSIGNMENT_RISKS,
	COMPANIES_NEED_ASSIGNMENT_RISKS_REVISION,
	COMPANIES_WITH_COMPLETED_ASSIGNMENT_RISKS,
} from 'api/queries/widgetQueries';

export default function AssignmentsRisksWidget() {
	return (
		<Widget title="Oppdragsrisikoanalyser" titleIcon="clipboard-list-check">
			<WidgetList>
				<WidgetListData
					label={
						<WidgetListLabel
							label="Mangler virksomhetsforståelse"
							chipColor={theme.palette.status.error}
						/>
					}
					query={COMPANIES_MISSING_ASSIGNMENT_RISKS}
					variables={{ type: 'BUSINESS_RISKS' }}
					link={id => `/bedrifter/${id}/rediger/?tab=oppdrag`}
				/>

				<WidgetListData
					label={
						<WidgetListLabel
							label="Trenger revisjon"
							chipColor={theme.palette.status.warning}
						/>
					}
					query={COMPANIES_NEED_ASSIGNMENT_RISKS_REVISION}
					variables={{ type: 'BUSINESS_RISKS' }}
					link={id => `/bedrifter/${id}/rediger/?tab=oppdrag`}
				/>

				<WidgetListData
					label={
						<WidgetListLabel
							label="Utført"
							chipColor={theme.palette.status.success}
						/>
					}
					query={COMPANIES_WITH_COMPLETED_ASSIGNMENT_RISKS}
					variables={{ type: 'BUSINESS_RISKS' }}
					link={id => `/bedrifter/${id}/rediger/?tab=oppdrag`}
				/>
			</WidgetList>

			<WidgetList>
				<WidgetListData
					label={
						<WidgetListLabel
							label="Mangler systemoversikt"
							chipColor={theme.palette.status.error}
						/>
					}
					query={COMPANIES_MISSING_ASSIGNMENT_RISKS}
					variables={{ type: 'SOFTWARE' }}
					link={id => `/bedrifter/${id}/rediger/?tab=oppdrag`}
				/>

				<WidgetListData
					label={
						<WidgetListLabel
							label="Trenger revisjon"
							chipColor={theme.palette.status.warning}
						/>
					}
					query={COMPANIES_NEED_ASSIGNMENT_RISKS_REVISION}
					variables={{ type: 'SOFTWARE' }}
					link={id => `/bedrifter/${id}/rediger/?tab=oppdrag`}
				/>

				<WidgetListData
					label={
						<WidgetListLabel
							label="Utført"
							chipColor={theme.palette.status.success}
						/>
					}
					query={COMPANIES_WITH_COMPLETED_ASSIGNMENT_RISKS}
					variables={{ type: 'SOFTWARE' }}
					link={id => `/bedrifter/${id}/rediger/?tab=oppdrag`}
				/>
			</WidgetList>

			<WidgetList>
				<WidgetListData
					label={
						<WidgetListLabel
							label="Mangler interne rutiner"
							chipColor={theme.palette.status.error}
						/>
					}
					query={COMPANIES_MISSING_ASSIGNMENT_RISKS}
					variables={{ type: 'ROUTINES' }}
					link={id => `/bedrifter/${id}/rediger/?tab=oppdrag`}
				/>

				<WidgetListData
					label={
						<WidgetListLabel
							label="Trenger revisjon"
							chipColor={theme.palette.status.warning}
						/>
					}
					query={COMPANIES_NEED_ASSIGNMENT_RISKS_REVISION}
					variables={{ type: 'ROUTINES' }}
					link={id => `/bedrifter/${id}/rediger/?tab=oppdrag`}
				/>

				<WidgetListData
					label={
						<WidgetListLabel
							label="Utført"
							chipColor={theme.palette.status.success}
						/>
					}
					query={COMPANIES_WITH_COMPLETED_ASSIGNMENT_RISKS}
					variables={{ type: 'ROUTINES' }}
					link={id => `/bedrifter/${id}/rediger/?tab=oppdrag`}
				/>
			</WidgetList>

			<WidgetList>
				<WidgetListData
					label={
						<WidgetListLabel
							label="Mangler oppdragsvurdering"
							chipColor={theme.palette.status.error}
						/>
					}
					query={COMPANIES_MISSING_ASSIGNMENT_RISKS}
					variables={{ type: 'ASSESSMENTS' }}
					link={id => `/bedrifter/${id}/rediger/?tab=oppdrag`}
				/>

				<WidgetListData
					label={
						<WidgetListLabel
							label="Trenger revisjon"
							chipColor={theme.palette.status.warning}
						/>
					}
					query={COMPANIES_NEED_ASSIGNMENT_RISKS_REVISION}
					variables={{ type: 'ASSESSMENTS' }}
					link={id => `/bedrifter/${id}/rediger/?tab=oppdrag`}
				/>

				<WidgetListData
					label={
						<WidgetListLabel
							label="Utført"
							chipColor={theme.palette.status.success}
						/>
					}
					query={COMPANIES_WITH_COMPLETED_ASSIGNMENT_RISKS}
					variables={{ type: 'ASSESSMENTS' }}
					link={id => `/bedrifter/${id}/rediger/?tab=oppdrag`}
				/>
			</WidgetList>
		</Widget>
	);
}
