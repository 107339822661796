import { useAppFilters, actionTypes } from 'web/contexts/AppFiltersContext';

import Filter from 'components/filters/Filter';

import { adminCompanyRoles } from 'server/libs/companyRoles';

const allOption = { value: '', label: 'Alle roller' };

export default function AccountantRoleFilter({
	disabled = false,
	size = 'medium',
}) {
	const { accountantRole, dispatch } = useAppFilters();

	return (
		<Filter
			name="accountant-role-filter"
			label="Rolle"
			value={accountantRole ?? allOption}
			options={[allOption, ...adminCompanyRoles]}
			onChange={value =>
				dispatch({
					type: actionTypes.SET_ACCOUNTANT_ROLE,
					payload: value,
				})
			}
			disabled={disabled}
			size={size}
		/>
	);
}
