import { useState, Fragment } from 'react';
import styled from 'styled-components';
import { useQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SendIcon from '@mui/icons-material/Send';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { formatDate } from 'utils/dates';
import { companyControlStatusColor } from 'utils/colors';
import { sanitizeHtml } from 'utils/sanitizers';

import NotFound from 'web/screens/NotFound';

import { useConfirmDialog } from 'components/contexts/ConfirmContext';
import { useSnackbar } from 'web/contexts/SnackbarContext';
import useScrollTo from 'hooks/useScrollTo';

import DeviationCommentDialog from 'web/screens/dialogs/companies/DeviationCommentDialog';
import FileListWithUploadDialog from 'web/screens/dialogs/users/FileListWithUploadDialog';
import FileUploadDialog from 'web/screens/dialogs/files/FileUploadDialog';

import ErrorMessage from 'components/ErrorMessage';
import DeleteButton from 'components/buttons/DeleteButton';

import DesktopLayout, {
	WideContent,
	StickyAction,
} from 'web/components/Layout';

import { GET_ONE_COMPANY } from 'api/queries/tenantQueries';
import {
	GET_ONE_COMPANY_CONTROL,
	GET_CATEGORY_QUESTIONS,
	GET_QUESTIONS_WITH_COMMENTS_OR_DEVIATIONS,
	GET_CUSTOM_QUESTIONS,
} from 'api/queries/companyControlQueries';
import {
	DELETE_ONE_COMPANY_CONTROL,
	INSERT_ONE_COMPANY_CONTROL_QUESTION,
	BULK_SET_COMPANY_CONTROL_QUESTION_VALUES,
	SET_COMPANY_CONTROL_QUESTION_VALUE,
	SET_COMPANY_CONTROL_STATUS,
	ADD_COMPANY_CONTROL_QUESTION_COMMENT,
	DELETE_COMPANY_CONTROL_QUESTION_COMMENT,
	ADD_COMPANY_CONTROL_QUESTION_DEVIATION,
	SET_COMPANY_CONTROL_QUESTION_DEVIATION_STATUS,
	DELETE_COMPANY_CONTROL_QUESTION_DEVIATION,
} from 'api/mutations/companyControlMutations';
import { GET_FILES } from 'api/queries/fileQueries';
import { DELETE_ONE_FILE } from 'api/mutations/fileMutations';

const Title = styled.h1`
	margin: 0 0 30px 0;
`;

const Category = styled.div`
	margin-bottom: 30px;
`;

const CategoryHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 20px;
	margin-bottom: 10px;
`;

const CategoryName = styled.h3`
	opacity: ${p => (p.$subdued ? 0.4 : 1)};
	margin: 0;
	flex: none;
`;

const ErrorText = styled.div`
	color: ${p => p.theme.palette.status.error};
`;

export default function CompanyControlEdit() {
	const { companyId, controlId } = useParams();

	const {
		data: { company } = { company: null },
		loading: companyLoading,
		error: companyError,
	} = useQuery(GET_ONE_COMPANY, {
		variables: {
			_id: companyId,
		},
	});

	const {
		data: { control } = { control: null },
		loading: controllLoading,
		error: controlError,
	} = useQuery(GET_ONE_COMPANY_CONTROL, {
		variables: {
			_id: controlId,
		},
	});

	if (!control && !controllLoading) {
		return <NotFound />;
	}

	return (
		<DesktopLayout
			loading={companyLoading || controllLoading}
			breadcrumbs={[
				{
					to: '/bedrifter/',
					label: 'Bedrifter',
				},
				{
					to: `/bedrifter/${company?._id}/rediger/`,
					label: company?.name ?? '...',
				},
				{
					to: `/bedrifter/${company?._id}/rediger/?tab=skjema`,
					label: 'Kontroller',
				},
				{
					label: control?.name
						? `${control.name} – ${control.year}`
						: 'Kontrollskjema',
				},
			]}
		>
			{!control && !controllLoading && <div>Hello</div>}

			{control && (
				<WideContent>
					<Title>
						{control.name} – {control.year}
					</Title>

					<StatusButton
						companyId={control.companyId}
						controlId={control._id}
						currentStatusCode={control.status.code}
					/>

					<ErrorMessage errors={[companyError, controlError]} />

					<StatusMessage status={control.status} />

					<CompanyControl
						control={control}
						currentStatusCode={control.status.code}
					/>
				</WideContent>
			)}
		</DesktopLayout>
	);
}

function CompanyControl({ control, currentStatusCode }) {
	switch (currentStatusCode) {
		case 'REVIEW':
			return <CompanyControlReview control={control} />;
		case 'CONTROL':
			return <CompanyControlControl control={control} />;
		case 'APPROVAL':
			return <CompanyControlApprove control={control} />;
		case 'APPROVED':
			return <CompanyControlApproved control={control} />;
		default:
			return (
				<div>
					Skjema for status {currentStatusCode} er ikke implementert!
				</div>
			);
	}
}

function CompanyControlReview({ control }) {
	const { notify } = useSnackbar();

	const [
		bulkSetCompanyControlQuestionValues,
		{
			loading: bulkSetCompanyControlQuestionValuesLoading,
			error: bulkSetCompanyControlQuestionValuesError,
		},
	] = useMutation(BULK_SET_COMPANY_CONTROL_QUESTION_VALUES, {
		refetchQueries: ['getCategoryQuestions'],
	});

	return (
		<div>
			<ErrorMessage errors={[bulkSetCompanyControlQuestionValuesError]} />

			<CompanyControlFileUpload docId={control._id} />

			{control.categories.map((category, index) => {
				const [categoryIr, setCategoryIR] = useState(false);

				return (
					<Category key={index}>
						<CategoryHeader>
							<CategoryName $subdued={categoryIr}>
								{category.name}
							</CategoryName>

							<Button
								startIcon={<PlaylistAddCheckIcon />}
								title="Sett alle spørsmål i denne kategorien som ikke relevant"
								align="right"
								disabled={
									bulkSetCompanyControlQuestionValuesLoading
								}
								onClick={async () => {
									try {
										const { data } =
											await bulkSetCompanyControlQuestionValues(
												{
													variables: {
														controlId: control._id,
														categoryName:
															category.name,
														value: 'ir',
													},
												}
											);

										if (data?.updated) {
											notify(
												`Alle spørsmål i ${category.name} satt som ikke relevant`
											);
										}
									} catch (err) {
										console.error(err);
									}
								}}
							>
								Sett alle som IR
							</Button>
						</CategoryHeader>

						<CategoryQuestions
							controlId={control._id}
							categoryName={category.name}
							setCategoryIR={setCategoryIR}
						/>
					</Category>
				);
			})}

			<CustomQuestions
				controlId={control._id}
				companyId={control.companyId}
			/>
		</div>
	);
}

function CompanyControlFileUpload({ docId, canEdit = true }) {
	return (
		<FileListWithUploadDialog
			docId={docId}
			uploadContext="companyControl"
			canEdit={canEdit}
		/>
	);
}

const AddQuestionForm = styled.form`
	margin-top: 40px;
	display: flex;
	algin-items: center;
	gap: 10px;
`;

function CustomQuestions({ controlId, companyId }) {
	const { notify } = useSnackbar();

	const { data: { questions } = { questions: [] }, error: questionsError } =
		useQuery(GET_CUSTOM_QUESTIONS, {
			variables: {
				controlId,
			},
		});

	const [
		insertQuestion,
		{ loading: insertQuestionLoading, error: insertQuestionError },
	] = useMutation(INSERT_ONE_COMPANY_CONTROL_QUESTION, {
		refetchQueries: ['getCustomQuestions'],
	});

	const allQuestionsInCategoryIsIR =
		questions.length > 0 &&
		questions.every(question => question.value === 'ir');

	return (
		<Category>
			<CategoryHeader>
				<CategoryName $subdued={allQuestionsInCategoryIsIR}>
					Egendefinerte spørsmål
				</CategoryName>
			</CategoryHeader>

			<ErrorMessage errors={[questionsError, insertQuestionError]} />

			<QuestionsWrapper>
				{questions?.length > 0 && (
					<div>
						{questions.map(question => (
							<Question key={question._id} question={question} />
						))}
					</div>
				)}

				<AddQuestionForm
					onSubmit={async e => {
						try {
							e.preventDefault();

							const question = e.target.question.value.trim();

							if (!question) return;

							const { data } = await insertQuestion({
								variables: {
									controlId,
									companyId,
									question,
								},
							});

							if (data?.question) {
								notify('Spørsmål lagt til!');
							}

							e.target.reset();
						} catch (err) {
							console.error(err);
						}
					}}
				>
					<TextField
						name="question"
						label="Skriv inn et egendefinert spørsmål"
						fullWidth
						size="small"
						required
					/>

					<Button
						variant="contained"
						type="submit"
						disabled={insertQuestionLoading}
						aria-label="Kommenter"
						startIcon={<HelpCenterIcon />}
					>
						Legg&nbsp;til
					</Button>
				</AddQuestionForm>
			</QuestionsWrapper>
		</Category>
	);
}

const ReviewQuestions = styled.div`
	background-color: ${p => p.theme.palette.background.paper};
	margin-bottom: 20px;
	padding: 20px;
`;

function CompanyControlControl({ control }) {
	const { data: { questions } = { questions: [] }, error: questionsError } =
		useQuery(GET_QUESTIONS_WITH_COMMENTS_OR_DEVIATIONS, {
			variables: {
				controlId: control._id,
			},
		});

	const questionsGroupedByCategory = groupBy(
		questions,
		({ categoryName }) => categoryName
	);

	return (
		<div>
			<ErrorMessage errors={[questionsError]} />

			<CompanyControlFileUpload docId={control._id} />

			{map(questionsGroupedByCategory, (questions, categoryName) => (
				<div key={categoryName}>
					<h3>{categoryName}</h3>

					<ReviewQuestions>
						{questions.map(question => (
							<ReviewQuestion
								key={question._id}
								question={question}
							/>
						))}
					</ReviewQuestions>
				</div>
			))}
		</div>
	);
}

function CompanyControlApprove({ control }) {
	const { data: { questions } = { questions: [] }, error: questionsError } =
		useQuery(GET_QUESTIONS_WITH_COMMENTS_OR_DEVIATIONS, {
			variables: {
				controlId: control._id,
			},
		});

	const questionsGroupedByCategory = groupBy(
		questions,
		({ categoryName }) => categoryName
	);

	return (
		<div>
			<ErrorMessage errors={[questionsError]} />

			<CompanyControlFileUpload docId={control._id} />

			{map(questionsGroupedByCategory, (questions, categoryName) => (
				<div key={categoryName}>
					<h3>{categoryName}</h3>

					<ReviewQuestions>
						{questions.map(question => (
							<ApproveQuestion
								key={question._id}
								question={question}
							/>
						))}
					</ReviewQuestions>
				</div>
			))}
		</div>
	);
}

function ApproveQuestion({ question }) {
	return (
		<ReviewQuestionWrapper>
			<QuestionHeaderWrapper>
				<ReviewQuestionLabel>
					<ReviewQuestionText>{question.label}</ReviewQuestionText>

					{question?.help && <HelpText text={question.help} />}
				</ReviewQuestionLabel>
			</QuestionHeaderWrapper>

			{question.value === 'ok' && (
				<Comments
					questionId={question._id}
					comments={question?.comments}
				/>
			)}

			{question.value === 'avvik' && (
				<ApproveDeviations
					questionId={question._id}
					deviations={question?.deviations}
				/>
			)}
		</ReviewQuestionWrapper>
	);
}

function ApproveDeviations({ questionId, deviations }) {
	return (
		<DeviationsWrapper>
			{deviations?.length > 0 ? (
				<List>
					{deviations.map(deviation => (
						<ApproveDeviation
							key={deviation.id}
							questionId={questionId}
							deviation={deviation}
						/>
					))}
				</List>
			) : (
				<ErrorText>
					Det har ikke blitt lagt til noen avvik på dette punktet
				</ErrorText>
			)}
		</DeviationsWrapper>
	);
}

function ApproveDeviation({ questionId, deviation }) {
	const [showCommentDialog, setShowCommentDialog] = useState(false);

	const [setDeviationStatus, { error: setDeviationStatusError }] =
		useMutation(SET_COMPANY_CONTROL_QUESTION_DEVIATION_STATUS, {
			refetchQueries: ['getQuestionsWithCommentsOrDeviations'],
		});

	const { data: { files = [] } = { files: [] }, error: filesError } =
		useQuery(GET_FILES, { variables: { docId: deviation.id } });

	return (
		<div>
			<ErrorMessage errors={[setDeviationStatusError, filesError]} />

			<ListItem
				dense={true}
				divider={true}
				secondaryAction={
					<ButtonGroup size="small" aria-label="Avvikkontroll">
						<IconButton
							size="small"
							title="Opphev"
							onClick={() => setShowCommentDialog(true)}
						>
							<ChatBubbleOutlineIcon fontSize="small" />
						</IconButton>

						<Button
							variant={
								!deviation.completed && !deviation.approved
									? 'contained'
									: 'outlined'
							}
							title="Avvis"
							onClick={async () => {
								try {
									await setDeviationStatus({
										variables: {
											questionId,
											deviationId: deviation.id,
											completed: false,
											approved: false,
											type: 'DEVIATION',
										},
									});
								} catch (err) {
									console.error(err);
								}
							}}
						>
							Avvik
						</Button>

						<Button
							variant={
								deviation.completed && deviation.approved
									? 'contained'
									: 'outlined'
							}
							title="Godkjenn"
							onClick={async () => {
								try {
									await setDeviationStatus({
										variables: {
											questionId,
											deviationId: deviation.id,
											completed: true,
											approved: true,
										},
									});
								} catch (err) {
									console.error(err);
								}
							}}
						>
							Godkjenn
						</Button>
					</ButtonGroup>
				}
			>
				<ListItemText
					primary={
						<div>
							<DeviationDescriptionWrapper>
								<DeviationStatusIcon
									status={deviation.type}
									completed={deviation.completed}
									approved={deviation.approved}
								/>

								<DeviationDescription>
									{deviation.description}
								</DeviationDescription>

								<DeviationType>
									{deviation.typeLabel}
								</DeviationType>
							</DeviationDescriptionWrapper>

							{files?.length > 0 && (
								<DeviationFilesWrapper>
									{files &&
										files.map(file => (
											<DeviationFile
												key={file._id}
												file={file}
											/>
										))}
								</DeviationFilesWrapper>
							)}

							{deviation?.comments?.length > 0 && (
								<DeviationCommentsWrapper>
									{deviation.comments.map(comment => (
										<DeviationComment
											key={comment.id}
											comment={comment}
										/>
									))}
								</DeviationCommentsWrapper>
							)}
						</div>
					}
				/>
			</ListItem>

			<DeviationCommentDialog
				questionId={questionId}
				deviationId={deviation.id}
				open={showCommentDialog}
				onClose={() => setShowCommentDialog(false)}
			/>
		</div>
	);
}

function DeviationStatusIcon({ type, completed = false, approved = false }) {
	if (completed && approved) {
		return <CheckCircleIcon color="success" />;
	}

	if (completed && !approved) {
		return <CheckCircleOutlineIcon color="success" />;
	}

	if (type === 'FOLLOW_UP') {
		return <HelpOutlineIcon color="warning" />;
	}

	return <ErrorOutlineIcon color="error" />;
}

function CompanyControlApproved({ control }) {
	const [categoryIr, setCategoryIR] = useState(false);

	return (
		<div>
			<CompanyControlFileUpload docId={control._id} canEdit={false} />

			{control.categories.map((category, index) => (
				<Category key={index}>
					<CategoryHeader>
						<CategoryName $subdued={categoryIr}>
							{category.name}
						</CategoryName>
					</CategoryHeader>

					<ApprovedCategoryQuestions
						controlId={control._id}
						categoryName={category.name}
						setCategoryIR={setCategoryIR}
					/>
				</Category>
			))}
		</div>
	);
}

function ApprovedCategoryQuestions({ controlId, categoryName, setCategoryIR }) {
	const { data: { questions } = { questions: [] }, error: questionsError } =
		useQuery(GET_CATEGORY_QUESTIONS, {
			variables: {
				controlId,
				categoryName,
			},
			onCompleted: ({ questions }) => {
				const allQuestionsInCategoryIsIR = questions.every(
					question => question.value === 'ir'
				);

				setCategoryIR(allQuestionsInCategoryIsIR);
			},
		});

	return (
		<QuestionsWrapper>
			<ErrorMessage errors={[questionsError]} />

			{questions.map(question => (
				<ApprovedQuestion key={question._id} question={question} />
			))}
		</QuestionsWrapper>
	);
}

function ApprovedQuestion({ question }) {
	return (
		<QuestionWrapper>
			<QuestionHeaderWrapper>
				<QuestionLabel $subdued={question?.value === 'ir'}>
					<span>{question.label}</span>

					{question?.help && <HelpText text={question.help} />}
				</QuestionLabel>
			</QuestionHeaderWrapper>

			{question?.value === 'avvik' && (
				<ApprovedDeviations deviations={question?.deviations} />
			)}

			{question.comments && (
				<ApprovedComments comments={question.comments} />
			)}
		</QuestionWrapper>
	);
}

function ApprovedDeviations({ deviations = [] }) {
	return (
		<DeviationsWrapper>
			{deviations?.length > 0 ? (
				<List>
					{deviations.map(deviation => (
						<ApprovedDeviation
							key={deviation.id}
							deviation={deviation}
						/>
					))}
				</List>
			) : (
				<ErrorText>
					Punktet er markert som et avvik, men det har ikke blitt lagt
					til noen avvik på dette punktet
				</ErrorText>
			)}
		</DeviationsWrapper>
	);
}

function ApprovedDeviation({ deviation }) {
	const { data: { files = [] } = { files: [] }, error: filesError } =
		useQuery(GET_FILES, { variables: { docId: deviation.id } });

	return (
		<div>
			<ErrorMessage errors={[filesError]} />

			<ListItem dense={true} divider={true}>
				<ListItemText
					primary={
						<div>
							<DeviationDescriptionWrapper>
								<DeviationStatusIcon
									type={deviation.type}
									completed={deviation.approved}
									solid={deviation.approved}
								/>

								<DeviationDescription>
									{deviation.description}
								</DeviationDescription>

								<DeviationType>
									{deviation.typeLabel}
								</DeviationType>
							</DeviationDescriptionWrapper>

							{files?.length > 0 && (
								<DeviationFilesWrapper>
									{files &&
										files.map(file => (
											<DeviationFile
												key={file._id}
												file={file}
											/>
										))}
								</DeviationFilesWrapper>
							)}

							{deviation?.comments?.length > 0 && (
								<DeviationCommentsWrapper>
									{deviation.comments.map(comment => (
										<DeviationComment
											key={comment.id}
											comment={comment}
										/>
									))}
								</DeviationCommentsWrapper>
							)}
						</div>
					}
				/>
			</ListItem>
		</div>
	);
}

function ApprovedComments({ comments }) {
	return (
		<CommentsWrapper>
			{comments?.length > 0 && (
				<List>
					{comments.map(comment => (
						<Fragment key={comment.id}>
							<ListItem dense={true} divider={true}>
								<ChatBubbleOutlineIcon
									fontSize="small"
									sx={{ marginRight: 2 }}
								/>

								<ListItemText
									primary={
										<CommentMessage>
											{comment.message}
										</CommentMessage>
									}
									secondary={
										<span>
											{formatDate(
												comment.createdAt,
												'DD.MM.YY [kl] HH:mm'
											)}{' '}
											av {comment.createdByDisplayName}
										</span>
									}
								/>
							</ListItem>
						</Fragment>
					))}
				</List>
			)}
		</CommentsWrapper>
	);
}

const ReviewQuestionWrapper = styled.div`
	margin-bottom: 20px;

	&:last-child {
		margin-bottom: 0;
	}
`;

const ReviewQuestionLabel = styled.div`
	opacity: ${p => (p.$subdued ? 0.4 : 1)};
	margin: 0;
	display: flex;
	gap: 10px;
	align-items: center;
`;

const ReviewQuestionText = styled.h3`
	margin: 0;
`;

function ReviewQuestion({ question }) {
	return (
		<ReviewQuestionWrapper>
			<QuestionHeaderWrapper>
				<ReviewQuestionLabel>
					<ReviewQuestionText>{question.label}</ReviewQuestionText>

					{question?.help && <HelpText text={question.help} />}
				</ReviewQuestionLabel>
			</QuestionHeaderWrapper>

			{question.value === 'ok' && (
				<Comments
					questionId={question._id}
					comments={question?.comments}
				/>
			)}

			{question.value === 'avvik' && (
				<ReviewDeviations
					questionId={question._id}
					deviations={question?.deviations}
				/>
			)}
		</ReviewQuestionWrapper>
	);
}

function ReviewDeviations({ questionId, deviations = [] }) {
	return (
		<DeviationsWrapper>
			{deviations?.length > 0 ? (
				<List>
					{deviations.map(deviation => {
						return (
							<ReviewDeviation
								key={deviation.id}
								questionId={questionId}
								deviation={deviation}
							/>
						);
					})}
				</List>
			) : (
				<ErrorText>
					Det har ikke blitt lagt til noen avvik på dette punktet
				</ErrorText>
			)}
		</DeviationsWrapper>
	);
}

function ReviewDeviation({ questionId, deviation }) {
	const [showCommentDialog, setShowCommentDialog] = useState(false);

	const [
		setDeviationStatus,
		{ loading: setDeviationStatusLoading, error: setDeviationStatusError },
	] = useMutation(SET_COMPANY_CONTROL_QUESTION_DEVIATION_STATUS, {
		refetchQueries: ['getQuestionsWithCommentsOrDeviations'],
	});

	const { data: { files = [] } = { files: [] }, error: filesError } =
		useQuery(GET_FILES, { variables: { docId: deviation.id } });

	return (
		<div>
			<ErrorMessage errors={[setDeviationStatusError, filesError]} />

			<ListItem
				dense={true}
				divider={true}
				secondaryAction={
					<div>
						<IconButton
							size="small"
							title="Opphev"
							onClick={() => setShowCommentDialog(true)}
						>
							<ChatBubbleOutlineIcon fontSize="small" />
						</IconButton>

						{!deviation.completed ? (
							<Button
								variant="outlined"
								size="small"
								disabled={setDeviationStatusLoading}
								onClick={async () => {
									try {
										await setDeviationStatus({
											variables: {
												questionId,
												deviationId: deviation.id,
												completed: true,
												approved: false,
											},
										});
									} catch (err) {
										console.error(err);
									}
								}}
							>
								Fullfør
							</Button>
						) : (
							<IconButton
								size="small"
								color="primary"
								title="Opphev"
								onClick={async () => {
									try {
										await setDeviationStatus({
											variables: {
												questionId,
												deviationId: deviation.id,
												completed: false,
												approved: false,
											},
										});
									} catch (err) {
										console.error(err);
									}
								}}
							>
								<CancelIcon fontSize="small" />
							</IconButton>
						)}
					</div>
				}
			>
				<ListItemText
					primary={
						<div>
							<DeviationDescriptionWrapper>
								<DeviationStatusIcon
									type={deviation.type}
									completed={deviation.completed}
									approved={deviation.approved}
								/>

								<DeviationDescription>
									{deviation.description}
								</DeviationDescription>

								<DeviationType>
									{deviation.typeLabel}
								</DeviationType>
							</DeviationDescriptionWrapper>

							{files?.length > 0 && (
								<DeviationFilesWrapper>
									{files &&
										files.map(file => (
											<DeviationFile
												key={file._id}
												file={file}
											/>
										))}
								</DeviationFilesWrapper>
							)}

							{deviation?.comments?.length > 0 && (
								<DeviationCommentsWrapper>
									{deviation.comments.map(comment => (
										<DeviationComment
											key={comment.id}
											comment={comment}
										/>
									))}
								</DeviationCommentsWrapper>
							)}
						</div>
					}
				/>
			</ListItem>
			<DeviationCommentDialog
				questionId={questionId}
				deviationId={deviation.id}
				open={showCommentDialog}
				onClose={() => setShowCommentDialog(false)}
			/>
		</div>
	);
}

const MessageBox = styled.div`
	background-color: ${p => companyControlStatusColor(p.$statusCode)};
	padding: 20px;
	margin-bottom: 30px;
`;

const MessageHeader = styled.h3`
	margin: 0 0 15px 0;
`;

const MessageContent = styled.div`
	margin-bottom: 10px;
`;

const MessageFooter = styled.div`
	text-align: right;
	font-style: italic;
`;

function StatusMessage({ status }) {
	const messageTexts = {
		REVIEW: 'Skjemaet er klart for revisjon av oppdragskontrollør.',
		CONTROL: 'Skjemaet er klart for avviksbehandling av regnskapsfører.',
		APPROVAL: 'Skjemaet er klart for godkjenning av oppdragskontrollør.',
		APPROVED: 'Skjemaet er godkjent.',
	};

	return (
		<MessageBox $statusCode={status.code}>
			<MessageHeader>{status.label}</MessageHeader>
			<MessageContent>
				{messageTexts[status.code] ?? 'Skjemastatustekst mangler'}
			</MessageContent>

			<MessageFooter>
				Sist oppdatert{' '}
				{formatDate(status.updatedAt, 'DD.MM.YY [kl] HH:mm')} av{' '}
				{status.updatedByDisplayName}
			</MessageFooter>
		</MessageBox>
	);
}

function StatusButton({ companyId, controlId, currentStatusCode }) {
	const { notify } = useSnackbar();
	const [scrollToRef, scrollToElement] = useScrollTo();

	const nextButtonTexts = {
		REVIEW: 'Send til gjennomgang',
		CONTROL: 'Send til godkjenning',
		APPROVAL: 'Fullfør skjema',
	};

	const nextStatusCodes = {
		REVIEW: 'CONTROL',
		CONTROL: 'APPROVAL',
		APPROVAL: 'APPROVED',
	};

	const [setStatus, { loading: setStatusLoading, error: setStatusError }] =
		useMutation(SET_COMPANY_CONTROL_STATUS, {
			refetchQueries: ['findOneCompanyControl'],
		});

	const [
		deleteControl,
		{ loading: deleteControlLoading, error: deleteControlError },
	] = useMutation(DELETE_ONE_COMPANY_CONTROL, {
		refetchQueries: ['getCompanyControls'],
	});

	return (
		<div ref={scrollToRef}>
			<ErrorMessage errors={[setStatusError, deleteControlError]} />

			<StickyAction>
				<DeleteButton
					disabled={deleteControlLoading}
					onClick={async () => {
						const { data } = await deleteControl({
							variables: {
								_id: controlId,
							},
						});

						if (!data.deleted) {
							throw new Error(
								'Det oppstod en feil ved sletting!'
							);
						}
					}}
					redirect={`/bedrifter/${companyId}/rediger/?tab=skjema`}
				/>

				{currentStatusCode === 'APPROVAL' && (
					<Button
						variant="outlined"
						size="large"
						disabled={setStatusLoading}
						onClick={async () => {
							try {
								const { data } = await setStatus({
									variables: {
										_id: controlId,
										newStatusCode: 'CONTROL',
									},
								});

								if (data?.updated) {
									notify('Skjemastatus ble endret!');
								}
							} catch (err) {
								scrollToElement();
								console.error(err);
							}
						}}
					>
						Send tilbake
					</Button>
				)}

				{currentStatusCode !== 'APPROVED' && (
					<Button
						variant="contained"
						size="large"
						disabled={setStatusLoading}
						onClick={async () => {
							try {
								const { data } = await setStatus({
									variables: {
										_id: controlId,
										newStatusCode:
											nextStatusCodes[currentStatusCode],
									},
								});

								if (data?.updated) {
									notify('Skjemastatus ble endret!');
								}
							} catch (err) {
								scrollToElement();
								console.error(err);
							}
						}}
					>
						{nextButtonTexts[currentStatusCode] ?? 'Neste'}
					</Button>
				)}
			</StickyAction>
		</div>
	);
}

const QuestionsWrapper = styled.div`
	background-color: ${p => p.theme.palette.background.paper};
	margin-bottom: 10px;
	padding: 20px;
`;

function CategoryQuestions({ controlId, categoryName, setCategoryIR }) {
	const { data: { questions } = { questions: [] }, error: questionsError } =
		useQuery(GET_CATEGORY_QUESTIONS, {
			variables: {
				controlId,
				categoryName,
			},
			onCompleted: ({ questions }) => {
				const allQuestionsInCategoryIsIR = questions.every(
					question => question.value === 'ir'
				);

				setCategoryIR(allQuestionsInCategoryIsIR);
			},
		});

	return (
		<QuestionsWrapper>
			<ErrorMessage errors={[questionsError]} />

			{questions.map(question => (
				<Question key={question._id} question={question} />
			))}
		</QuestionsWrapper>
	);
}

const QuestionWrapper = styled.div`
	margin-bottom: 20px;

	&:last-child {
		margin-bottom: 0;
	}
`;

const QuestionHeaderWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 20px;
	position: relative;
`;

const QuestionLabel = styled.h4`
	opacity: ${p => (p.$subdued ? 0.4 : 1)};
	margin: 0;
	display: flex;
	gap: 10px;
	align-items: center;
`;

const questionButtons = [
	{ value: 'ir', label: 'IR', title: 'Ikke relevant' },
	{ value: 'ok', label: 'OK', title: 'OK' },
	{ value: 'avvik', label: 'Avvik', title: 'Avvik' },
];

function Question({ question }) {
	const [setQuestionValue, { error: setQuestionValueError }] = useMutation(
		SET_COMPANY_CONTROL_QUESTION_VALUE,
		{
			refetchQueries: ['getCategoryQuestions', 'getCustomQuestions'],
		}
	);

	return (
		<QuestionWrapper>
			<ErrorMessage errors={[setQuestionValueError]} />

			<QuestionHeaderWrapper>
				<QuestionLabel $subdued={question?.value === 'ir'}>
					<span>{question.label}</span>

					{question?.help && <HelpText text={question.help} />}
				</QuestionLabel>

				<ButtonGroup size="small" aria-label="Spørsmålsverdi">
					{questionButtons.map(({ value, label, title }) => (
						<Button
							key={value}
							variant={
								question?.value === value
									? 'contained'
									: 'outlined'
							}
							title={title}
							onClick={async () => {
								try {
									await setQuestionValue({
										variables: {
											questionId: question._id,
											value,
										},
									});
								} catch (err) {
									console.error(err);
								}
							}}
						>
							{label}
						</Button>
					))}
				</ButtonGroup>
			</QuestionHeaderWrapper>

			{question?.value === 'avvik' && (
				<Deviations
					questionId={question._id}
					deviations={question?.deviations}
				/>
			)}

			{question?.value === 'ok' && (
				<Comments
					questionId={question._id}
					comments={question?.comments}
				/>
			)}
		</QuestionWrapper>
	);
}

const CommentsWrapper = styled.div`
	margin: 0;
`;

const CommentsForm = styled.form`
	display: flex;
	gap: 20px;
	align-items: center;
	margin-top: 20px;
`;

const CommentMessage = styled.div`
	font-size: 16px;
`;

const CommentButton = styled(Button)`
	padding: 11px 0;
`;

function Comments({ questionId, comments = [] }) {
	const { verify } = useConfirmDialog();

	const [addComment, { loading: addCommentLoading, error: addCommentError }] =
		useMutation(ADD_COMPANY_CONTROL_QUESTION_COMMENT, {
			refetchQueries: [
				'getCategoryQuestions',
				'getCustomQuestions',
				'getQuestionsWithCommentsOrDeviations',
			],
		});

	const [
		deleteComment,
		{ loading: deleteCommentLoading, error: deleteCommentError },
	] = useMutation(DELETE_COMPANY_CONTROL_QUESTION_COMMENT, {
		refetchQueries: [
			'getCategoryQuestions',
			'getCustomQuestions',
			'getQuestionsWithCommentsOrDeviations',
		],
	});

	return (
		<CommentsWrapper>
			<ErrorMessage errors={[addCommentError, deleteCommentError]} />

			{comments?.length > 0 && (
				<List>
					{comments.map(comment => (
						<Fragment key={comment.id}>
							<ListItem
								dense={true}
								divider={true}
								secondaryAction={
									<IconButton
										edge="end"
										aria-label="delete"
										disabled={deleteCommentLoading}
										onClick={async () => {
											try {
												if (
													!(await verify({
														title: 'Slett kommentar',
														text: 'Er du sikker på at du vil slette kommentaren?',
													}))
												) {
													return;
												}

												await deleteComment({
													variables: {
														questionId,
														commentId: comment.id,
													},
												});
											} catch (err) {
												console.error(err);
											}
										}}
									>
										<DeleteIcon />
									</IconButton>
								}
							>
								<ChatBubbleOutlineIcon
									fontSize="small"
									sx={{ marginRight: 2 }}
								/>

								<ListItemText
									primary={
										<CommentMessage>
											{comment.message}
										</CommentMessage>
									}
									secondary={
										<span>
											{formatDate(
												comment.createdAt,
												'DD.MM.YY [kl] HH:mm'
											)}{' '}
											av {comment.createdByDisplayName}
										</span>
									}
								/>
							</ListItem>
						</Fragment>
					))}
				</List>
			)}

			<CommentsForm
				onSubmit={async e => {
					try {
						e.preventDefault();

						const message = e.target.message.value.trim();

						if (!message) return;

						await addComment({
							variables: {
								questionId,
								message,
							},
						});

						e.target.reset();
					} catch (err) {
						console.error(err);
					}
				}}
			>
				<TextField
					name="message"
					label="Skriv inn kommentar"
					fullWidth
					size="small"
					required
				/>

				<CommentButton
					size="small"
					variant="outlined"
					color="primary"
					type="submit"
					disabled={addCommentLoading}
					aria-label="Kommenter"
				>
					<SendIcon fontSize="small" />
				</CommentButton>
			</CommentsForm>
		</CommentsWrapper>
	);
}

const DeviationsWrapper = styled.div`
	margin: 10px 0 0 0;
`;

const DeviationsForm = styled.form`
	display: flex;
	gap: 10px;
	align-items: center;
	margin-top: 20px;
`;

const AddDeviationButton = styled(Button)`
	padding: 7px 0;
`;

function Deviations({ questionId, deviations = [] }) {
	const [
		addDeviation,
		{ loading: addDeviationLoading, error: addDeviationError },
	] = useMutation(ADD_COMPANY_CONTROL_QUESTION_DEVIATION, {
		refetchQueries: ['getCategoryQuestions', 'getCustomQuestions'],
	});

	return (
		<DeviationsWrapper>
			<ErrorMessage errors={[addDeviationError]} />

			{deviations?.length > 0 && (
				<List>
					{deviations.map(deviation => (
						<Deviation
							key={deviation.id}
							questionId={questionId}
							deviation={deviation}
						/>
					))}
				</List>
			)}

			<DeviationsForm
				onSubmit={async e => {
					try {
						e.preventDefault();

						const description = e.target.description.value.trim();
						const type = e.target.type.value;

						await addDeviation({
							variables: {
								questionId,
								description,
								type,
							},
						});

						e.target.reset();
					} catch (err) {
						console.error(err);
					}
				}}
			>
				<TextField
					name="description"
					label="Skriv inn avviket"
					fullWidth
					size="small"
					required
				/>

				<FormControl sx={{ minWidth: 150 }}>
					<InputLabel id="deviation-type-select-label">
						Type
					</InputLabel>

					<Select
						name="type"
						labelId="deviation-type-select-label"
						id="deviation-type-select"
						label="Avvikstype"
						defaultValue="DEVIATION"
						size="small"
						required
					>
						<MenuItem value="DEVIATION">Avvik</MenuItem>
						<MenuItem value="FOLLOW_UP">Oppfølging</MenuItem>
					</Select>
				</FormControl>

				<AddDeviationButton
					variant="outlined"
					color="primary"
					type="submit"
					disabled={addDeviationLoading}
					aria-label="Legg til avvik"
				>
					Legg til
				</AddDeviationButton>
			</DeviationsForm>
		</DeviationsWrapper>
	);
}

const DeviationButtons = styled.div`
	display: flex;
	align-items: center;
`;

const DeviationDescriptionWrapper = styled.div`
	display: flex;
	align-items: center;
	font-size: 16px;
	line-height: 1;
	gap: 10px;
	padding-right: 150px;
`;

const DeviationDescription = styled.div`
	font-size: 1rem;
	font-weight: bold;
	flex: 1;
`;

const DeviationType = styled.div`
	color: ${p => p.theme.palette.status.subdued};
	font-size: 0.7rem;
	font-weight: bold;
	text-transform: uppercase;
`;

const DeviationFilesWrapper = styled.div`
	margin: 10px 30px 5px 30px;
`;

const DeviationCommentsWrapper = styled.div`
	margin: 10px 30px 5px 30px;
`;

function Deviation({ questionId, deviation }) {
	const { verify } = useConfirmDialog();
	const { notify } = useSnackbar();

	const [showFileUploadDialog, setShowFileUploadDialog] = useState(false);
	const [showCommentDialog, setShowCommentDialog] = useState(false);

	const { data: { files = [] } = { files: [] }, error: filesError } =
		useQuery(GET_FILES, { variables: { docId: deviation.id } });

	const [
		deleteDeviation,
		{ loading: deleteDeviationLoading, error: deleteDeviationError },
	] = useMutation(DELETE_COMPANY_CONTROL_QUESTION_DEVIATION, {
		refetchQueries: ['getCategoryQuestions', 'getCustomQuestions'],
	});

	return (
		<div>
			<ErrorMessage errors={[filesError, deleteDeviationError]} />

			<ListItem
				dense={true}
				divider={true}
				secondaryAction={
					<DeviationButtons>
						<div>
							<IconButton
								size="small"
								title="Legg inn kommentar"
								disabled={false}
								onClick={() => setShowCommentDialog(true)}
							>
								<ChatBubbleOutlineIcon fontSize="small" />
							</IconButton>
						</div>

						<div>
							<IconButton
								size="small"
								title="Last opp vedlegg"
								disabled={deleteDeviationLoading}
								onClick={() => setShowFileUploadDialog(true)}
							>
								<UploadFileIcon fontSize="small" />
							</IconButton>
						</div>

						<div>
							<IconButton
								size="small"
								title="Slett avvik"
								disabled={deleteDeviationLoading}
								onClick={async () => {
									try {
										if (
											!(await verify({
												title: 'Slett avvik',
												text: 'Er du sikker på at du vil slette avviket?',
											}))
										) {
											return;
										}

										const { data } = await deleteDeviation({
											variables: {
												questionId,
												deviationId: deviation.id,
											},
										});

										if (data?.deleted) {
											notify('Avviket ble slettet!');
										}
									} catch (err) {
										console.error(err);
									}
								}}
							>
								<DeleteIcon fontSize="small" />
							</IconButton>
						</div>
					</DeviationButtons>
				}
			>
				<ListItemText
					primary={
						<div>
							<DeviationDescriptionWrapper>
								<DeviationStatusIcon
									type={deviation.type}
									completed={deviation.completed}
									approved={deviation.approved}
								/>

								<DeviationDescription>
									{deviation.description}
								</DeviationDescription>

								<DeviationType>
									{deviation.typeLabel}
								</DeviationType>
							</DeviationDescriptionWrapper>

							{files?.length > 0 && (
								<DeviationFilesWrapper>
									{files.map(file => (
										<DeviationFile
											key={file._id}
											file={file}
											showDeleteButton={true}
										/>
									))}
								</DeviationFilesWrapper>
							)}

							{deviation?.comments?.length > 0 && (
								<DeviationCommentsWrapper>
									{deviation.comments.map(comment => (
										<DeviationComment
											key={comment.id}
											comment={comment}
										/>
									))}
								</DeviationCommentsWrapper>
							)}
						</div>
					}
				/>
			</ListItem>

			<FileUploadDialog
				docId={deviation.id}
				uploadContext="companyControl"
				open={showFileUploadDialog}
				onClose={() => setShowFileUploadDialog(false)}
			/>

			<DeviationCommentDialog
				questionId={questionId}
				deviationId={deviation.id}
				open={showCommentDialog}
				onClose={() => setShowCommentDialog(false)}
			/>
		</div>
	);
}

const DeviationFileWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`;

const DeviationFileLink = styled.a`
	display: flex;
	align-items: center;
	gap: 15px;
`;

const DeleteFileButton = styled.button`
	background: none;
	border: none;
	color: ${p => p.theme.palette.text.secondary};
	cursor: pointer;
	margin: 0;
	padding: 0;
	text-decoration: underline;
	transition: all 0.5s;

	&:hover {
		color: ${p => p.theme.palette.primary.main};
	}
`;

function DeviationFile({ file, showDeleteButton = false }) {
	return (
		<DeviationFileWrapper>
			<DeviationFileLink
				href={file.url}
				title={`Last ned ${file.fileName}`}
				target="_blank"
				rel="noreferrer"
			>
				<AttachFileIcon fontSize="small" />

				<span>{file.fileName}</span>
			</DeviationFileLink>

			{showDeleteButton && (
				<DeviationFileDeleteButton
					fileId={file._id}
					fileName={file.fileName}
				/>
			)}
		</DeviationFileWrapper>
	);
}

function DeviationFileDeleteButton({ fileId, fileName }) {
	const { verify } = useConfirmDialog();
	const { notify } = useSnackbar();

	const [
		deleteOneFile,
		{ loading: deleteOneFileLoading, error: deleteOneFileError },
	] = useMutation(DELETE_ONE_FILE, {
		refetchQueries: ['getFiles'],
	});

	return (
		<div>
			<ErrorMessage errors={[deleteOneFileError]} />

			<DeleteFileButton
				type="button"
				disabled={deleteOneFileLoading}
				onClick={async () => {
					try {
						if (
							!(await verify({
								title: 'Slett fil',
								text: `Er du sikker på at du vil slette ${fileName}?`,
							}))
						) {
							return;
						}

						const { data } = await deleteOneFile({
							variables: {
								_id: fileId,
							},
						});

						if (data?.deleted) {
							notify(`${fileName} ble slettet!`);
						}
					} catch (err) {
						console.error(err);
					}
				}}
			>
				(slett)
			</DeleteFileButton>
		</div>
	);
}

const DeviationCommentWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 15px;
`;

const DeviationCommentMessage = styled.div``;

const DeviationCommentByline = styled.div`
	color: ${p => p.theme.palette.text.secondary};
	font-style: italic;
	font-size: 0.9em;
`;

function DeviationComment({ comment }) {
	return (
		<DeviationCommentWrapper>
			<ChatBubbleOutlineIcon fontSize="small" />

			<div>
				<DeviationCommentMessage>
					{comment.message}
				</DeviationCommentMessage>

				<DeviationCommentByline>
					{formatDate(comment.createdAt, 'DD.MM.YY [kl] HH:mm')} av{' '}
					{comment.createdByDisplayName}
				</DeviationCommentByline>
			</div>
		</DeviationCommentWrapper>
	);
}

const TooltipIconWrapper = styled.span`
	color: ${p => p.theme.palette.text.secondary};
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 30px;
	width: 20px;
`;

const TooltipTextWrapper = styled.div`
	a {
		color: ${p => p.theme.palette.text.white};
	}
`;

function HelpText({ text }) {
	return (
		<Tooltip
			title={
				<TooltipTextWrapper
					dangerouslySetInnerHTML={{
						__html: sanitizeHtml(text),
					}}
				/>
			}
			placement="right"
		>
			<TooltipIconWrapper>
				<HelpOutlineIcon fontSize="small" />
			</TooltipIconWrapper>
		</Tooltip>
	);
}
