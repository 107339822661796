import styled from 'styled-components';

import { useUser } from 'web/contexts/UserContext';

import StatisticsDialogProvider from 'web/contexts/StatisticsDialogContext';

import DesktopLayout from 'web/components/Layout';
import ContentWithSidebarFilters from 'web/components/ContentWithSidebarFilters';

import CompaniesWidget from 'web/components/widgets/statistics/CompaniesWidget';
import CompanyTypesWidget from 'web/components/widgets/statistics/CompanyTypesWidget';
import CompanyTasksWidget from 'web/components/widgets/statistics/CompanyTasksWidget';
import CompanyDocumentsWidget from 'web/components/widgets/statistics/CompanyDocumentsWidget';
import CompanyContractsWidget from 'web/components/widgets/statistics/CompanyContractsWidget';
import AssignmentRisksWidget from 'web/components/widgets/statistics/AssignmentRisksWidget';
import CompanyRisksWidget from 'web/components/widgets/statistics/CompanyRisksWidget';
import ComanyRoutinesWidget from 'web/components/widgets/statistics/ComanyRoutinesWidget';
import CompanyControlsWidget from 'web/components/widgets/statistics/CompanyControlsWidget';
import UsersWidget from 'web/components/widgets/statistics/UsersWidget';
import CompanyCategoryWidget from 'web/components/widgets/statistics/CompanyCategoryWidget';
import TaskDeadlineWidget from 'web/components/widgets/statistics/TaskDeadlineWidget';

const Grid = styled.div`
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	gap: 30px;
	margin-bottom: 30px;
`;

const Column = styled.div`
	grid-column: span ${({ span }) => span};
`;

const displayFilters = [
	'year',
	'month',
	'task',
	'department',
	'accountantLegacy',
	'accountantRole',
	'company',
	'accountType',
];

export default function Statistics() {
	const { userHasMinRole } = useUser();

	return (
		<DesktopLayout
			title="Statistikk"
			breadcrumbs={[
				{
					to: '/statistikk/',
					label: 'Statistikk',
				},
			]}
		>
			<ContentWithSidebarFilters displayFilters={displayFilters}>
				<StatisticsDialogProvider>
					<Grid>
						<Column span={4}>
							<CompaniesWidget />
						</Column>

						<Column span={4}>
							<CompanyTypesWidget />
						</Column>

						<Column span={4}>
							<CompanyTasksWidget />
						</Column>

						<Column span={4}>
							<CompanyDocumentsWidget />
						</Column>

						<Column span={4}>
							<CompanyContractsWidget />
						</Column>

						<Column span={4}>
							<AssignmentRisksWidget />
						</Column>

						<Column span={4}>
							<CompanyRisksWidget />
						</Column>

						<Column span={4}>
							<TaskDeadlineWidget
								title="Årsregnskap"
								type="ANNUAL_ACCOUNTS"
							/>
						</Column>

						<Column span={4}>
							<TaskDeadlineWidget
								title="Skattemelding"
								type="TAX_NOTICE"
							/>
						</Column>

						<Column span={4}>
							<CompanyControlsWidget />
						</Column>

						<Column span={4}>
							<ComanyRoutinesWidget />
						</Column>

						{userHasMinRole('admin') && (
							<Column span={4}>
								<CompanyCategoryWidget />
							</Column>
						)}

						{userHasMinRole('admin') && (
							<Column span={4}>
								<UsersWidget />
							</Column>
						)}
					</Grid>
				</StatisticsDialogProvider>
			</ContentWithSidebarFilters>
		</DesktopLayout>
	);
}
