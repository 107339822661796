import { useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { useApolloClient, useQuery, useMutation } from '@apollo/client';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import EditNoteIcon from '@mui/icons-material/EditNote';
import NotificationsIcon from '@mui/icons-material/Notifications';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { useUser } from 'web/contexts/UserContext';
import { useConfirmDialog } from 'components/contexts/ConfirmContext';
import { useSnackbar } from 'web/contexts/SnackbarContext';

import SigneeModal from 'web/screens/modals/SigneeModal';

import ErrorMessage from 'components/ErrorMessage';
import DocumentIcon from 'components/DocumentIcon';

import {
	UPDATE_SIGNATURE_REQUEST_SIGNEES,
	REMOVE_SIGNATURE_REQUEST_SIGNEE,
	RESEND_SIGNING_REQUEST,
} from 'api/mutations/signatureMutations';
import { GET_ACTIVE_SIGNATURE_REQUEST } from 'api/queries/signatureQueries';

const SignersWrapper = styled.div`
	margin-top: 15px;
`;

const Title = styled.h4`
	margin: 0 0 15px 0;
`;

const List = styled.div`
	width: 100%;
`;

const Item = styled.div`
	background: ${p => p.theme.palette.background.gray};
	display: flex;
	align-items: center;
	gap: 15px;
	margin-bottom: 5px;
	padding: 15px;
`;

const Data = styled.div`
	flex: 1 1 0%;
`;

const Email = styled.div`
	font-weight: 400;
	font-size: 18px;
	text-align: left;
`;

const Text = styled.span`
	font-weight: 400;
	font-size: 13px;
	text-align: left;
`;

const ButtonsWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	gap: 10px;
`;

export default function Signers({
	documentId,
	title,
	showSignedSignees = true,
}) {
	const apolloClient = useApolloClient();
	const { user } = useUser();
	const { verify } = useConfirmDialog();
	const { notify } = useSnackbar();
	const [disabledReminderButtons, setDisabledReminderButtons] = useState([]);
	const [editSigneeIndex, setEditSigneeIndex] = useState(-1);

	const {
		data: { getActiveSignatureRequest = {} } = {},
		loading,
		error: getActiveSignatureRequestError,
	} = useQuery(GET_ACTIVE_SIGNATURE_REQUEST, {
		variables: {
			documentId,
		},
		errorPolicy: 'all',
	});

	const [resendSigningRequest, { error: resendSigningRequestError }] =
		useMutation(RESEND_SIGNING_REQUEST);

	const [
		removeSignatureSignee,
		{
			loading: removeSignatureSigneeLoading,
			error: removeSignatureSigneeError,
		},
	] = useMutation(REMOVE_SIGNATURE_REQUEST_SIGNEE, {
		refetchQueries: ['getActiveSignatureRequest'],
	});

	async function handleEditSignee(signee) {
		const { signees, _id } = getActiveSignatureRequest;

		const modifiedSignees = [...signees];
		modifiedSignees[editSigneeIndex] = signee; // Replace the old signee with the new signee

		const updatedSignees = [];
		modifiedSignees.map(s => {
			updatedSignees.push({
				phone: s.phone || '',
				email: s.email || '',
				userId: s.userId || '',
			});
		});

		await apolloClient.mutate({
			mutation: UPDATE_SIGNATURE_REQUEST_SIGNEES,
			variables: {
				_id,
				signees: updatedSignees,
			},
			refetchQueries: [
				'getActiveSignatureRequest',
				'getCompanyDocuments',
			],
		});

		setEditSigneeIndex(-1);
	}

	if (loading) {
		return <div>Laster…</div>;
	}

	return (
		<div>
			<SignersWrapper>
				{title && <Title>{title}</Title>}

				<ErrorMessage
					errors={[
						getActiveSignatureRequestError,
						resendSigningRequestError,
						removeSignatureSigneeError,
					]}
				/>

				<List>
					{getActiveSignatureRequest?.signees?.map(
						(signee, index) => {
							if (!showSignedSignees && signee.signed)
								return null;

							const signeeLabel = [];
							if (signee.userId && !signee.userName) {
								signeeLabel.push('Bruker finnes ikke lenger');
							} else if (signee.userId) {
								signeeLabel.push(signee.userName);
							}
							if (signee.email) signeeLabel.push(signee.email);
							if (signee.phone) signeeLabel.push(signee.phone);

							return (
								<Item key={index}>
									<div
										onClick={() => {
											if (signee.signed) return;

											setEditSigneeIndex(index);
										}}
									>
										<DocumentIcon
											needsSignature={true}
											hasSignature={signee.signed}
										/>
									</div>

									<Data>
										<div>
											<Email>
												{signeeLabel.join(' - ')}
											</Email>

											<Text>
												{signee.signed
													? 'Signert'
													: 'Mangler signering'}
											</Text>
										</div>
									</Data>

									{!signee.signed && (
										<ButtonsWrapper>
											{signee.userId === user._id &&
												getActiveSignatureRequest?.signUrl && (
													<Tooltip title="Signer dokument">
														<span>
															<IconButton
																aria-label="Signer dokument"
																href={
																	getActiveSignatureRequest?.signUrl
																}
																target="_blank"
															>
																<EditNoteIcon />
															</IconButton>
														</span>
													</Tooltip>
												)}

											<Tooltip title="Send påminnelse">
												<span>
													<IconButton
														aria-label="Send påminnelse"
														disabled={Boolean(
															disabledReminderButtons.includes(
																index
															) ||
																(signee.userId &&
																	!signee.userName)
														)}
														onClick={async () => {
															setDisabledReminderButtons(
																prev => [
																	...prev,
																	index,
																]
															);

															try {
																const { data } =
																	await resendSigningRequest(
																		{
																			variables:
																				{
																					documentId,
																					signee: {
																						email: signee.email,
																						phone: signee.phone,
																						userId: signee.userId,
																					},
																				},
																		}
																	);

																if (data) {
																	notify(
																		'Ny påminnelse ble sendt!'
																	);
																}
															} catch (error) {
																console.error(
																	error
																);
															}
														}}
													>
														<NotificationsIcon />
													</IconButton>
												</span>
											</Tooltip>

											<Tooltip title="Rediger mottaker">
												<span>
													<IconButton
														aria-label="Rediger mottaker"
														onClick={() =>
															setEditSigneeIndex(
																index
															)
														}
													>
														<EditIcon />
													</IconButton>
												</span>
											</Tooltip>

											<Tooltip title="Slett mottaker">
												<IconButton
													aria-label="Slett mottaker"
													disabled={
														removeSignatureSigneeLoading ||
														getActiveSignatureRequest
															.signees.length ===
															1
													}
													onClick={async () => {
														const signedCount =
															getActiveSignatureRequest.signees.filter(
																signee =>
																	signee.signed
															).length;

														const notSignedCount =
															getActiveSignatureRequest.signees.filter(
																signee =>
																	!signee.signed
															).length;

														let confirmMessage =
															'Er du sikker på at du ønsker å fjerne denne mottakeren?';

														if (
															signedCount > 0 &&
															notSignedCount === 1
														) {
															confirmMessage =
																'Hvis du fjerner den siste mottakeren som ikke har signert, vil signeringsprosessen bli ferdigstilt. Er du sikker på at du vil fjerne denne mottakeren?';
														}

														if (
															!(await verify({
																title: 'Fjern mottaker',
																text: confirmMessage,
															}))
														) {
															return;
														}

														try {
															const {
																data: {
																	removed,
																},
															} =
																await removeSignatureSignee(
																	{
																		variables:
																			{
																				_id: getActiveSignatureRequest._id,
																				signee: {
																					email: signee?.email,
																					phone: signee?.phone,
																					userId: signee?.userId,
																				},
																			},
																	}
																);

															if (removed) {
																notify(
																	'Mottaker ble fjernet!'
																);
															}
														} catch (err) {
															console.error(err);
														}
													}}
												>
													<DeleteIcon />
												</IconButton>
											</Tooltip>
										</ButtonsWrapper>
									)}
								</Item>
							);
						}
					)}
				</List>
			</SignersWrapper>

			{editSigneeIndex !== -1 &&
				createPortal(
					<SigneeModal
						signee={
							getActiveSignatureRequest &&
							getActiveSignatureRequest.signees &&
							getActiveSignatureRequest.signees[editSigneeIndex]
						}
						handleClose={() => setEditSigneeIndex(-1)}
						handleSaveSignee={signee => handleEditSignee(signee)}
					/>,
					document.getElementById('modal-root')
				)}
		</div>
	);
}
