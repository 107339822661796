import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useApolloClient } from '@apollo/client';

import dayjs from 'utils/dates';

import { useWebApp } from 'web/contexts/WebContexts';
import useIsMounted from 'components/useIsMounted';

import Icon from 'components/Icon';
import { InlineLoading } from 'components/Loading';
import ErrorMessage from 'components/ErrorMessage';

import SigningRequestsWidget from 'web/components/widgets/SigningRequestsWidget';

import DesktopLayout, { Content } from 'web/components/Layout';
import CompanyInfoWidget from 'web/components/widgets/CompanyInfoWidget';
import DocumentsWidget from 'web/components/widgets/DocumentsWidget';

import { HOME_DASHBOARD_QUERY } from 'api/queries/homeQueries';
import { CLEAR_RECENT_DOCUMENTS } from 'api/mutations/documentMutations';

const Grid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 30px;
`;

const ClearButton = styled.button`
	background: none;
	border: none;
	color: ${p => p.theme.palette.primary.main};
	cursor: pointer;
	display: flex;
	align-items: center;
	font-size: 15px;
	font-weight: 700;
	line-height: 1;
	text-transform: uppercase;
	transition: all 0.5s;

	&:hover {
		color: ${p => p.theme.palette.primary.dark};
	}
`;

export default function UserHome({ companyId }) {
	const isMounted = useIsMounted();
	const client = useApolloClient();
	const { set } = useWebApp();

	const [signatureRequests, setSignatureRequests] = useState(null);

	const [
		{ company, recentDocuments, unSignedDocuments, loading, error },
		setData,
	] = useState({
		company: null,
		recentDocuments: [],
		unSignedDocuments: [],
		loading: true,
		error,
	});

	useEffect(() => {
		client
			.query({
				query: HOME_DASHBOARD_QUERY,
				fetchPolicy: 'network-only',
				variables: {
					tenantId: companyId,
					createdAt: {
						gt: dayjs().subtract(1, 'month').toDate(),
					},
					type: 'file',
				},
			})
			.then(
				({
					data: {
						company,
						recentDocuments,
						unSignedDocuments,
						mySignatureRequests,
					},
				}) => {
					if (!isMounted()) return;
					setData({
						company: company,
						recentDocuments: recentDocuments.items,
						unSignedDocuments: unSignedDocuments.items,
						mySignatureRequests: mySignatureRequests.items,
						loading: false,
					});
					setSignatureRequests(mySignatureRequests);
				}
			)
			.catch(error => {
				console.error(error);

				set({
					tenant: null,
				});
			});
	}, [companyId]);

	function clearRecentDocuments(ids) {
		const oldRecentDocuments = recentDocuments;

		setData(prev => ({ ...prev, unSignedDocuments, recentDocuments: [] }));

		client
			.mutate({
				mutation: CLEAR_RECENT_DOCUMENTS,
				variables: { ids },
			})
			.then(() => {
				setData(prev => ({
					...prev,
					unSignedDocuments,
					recentDocuments: [],
				}));
			})
			.catch(error => {
				console.error(error);
				setData({
					unSignedDocuments,
					recentDocuments: oldRecentDocuments,
					error,
				});
			});
	}

	return (
		<DesktopLayout
			title="Hjem"
			breadcrumbs={[
				{
					to: '/',
					label: 'Hjem',
				},
			]}
		>
			<Content>
				{loading && <InlineLoading />}

				{!loading && (
					<>
						<ErrorMessage errors={error} />

						<Grid>
							<CompanyInfoWidget company={company} />

							{signatureRequests && (
								<SigningRequestsWidget
									title="Dokumenter som avventer min signering"
									signingRequests={signatureRequests}
								/>
							)}

							<DocumentsWidget
								title="Dokumenter som venter på signatur"
								documents={unSignedDocuments}
							/>

							<DocumentsWidget
								title="Dokumenter som er nylig lastet opp"
								action={
									<ClearButton
										onClick={() =>
											clearRecentDocuments(
												recentDocuments.map(d => d._id)
											)
										}
									>
										<span>Skjul</span>

										<Icon icon="times" />
									</ClearButton>
								}
								documents={recentDocuments}
							/>
						</Grid>
					</>
				)}
			</Content>
		</DesktopLayout>
	);
}
