import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router';
import SaveIcon from '@mui/icons-material/Save';

import ErrorMessage from 'components/ErrorMessage';

import DesktopLayout, { NarrowContent } from 'web/components/Layout';
import Form from 'components/form/Form';
import TextField from 'components/form/TextField';
import SubmitButton from 'components/form/SubmitButton';

import { INSERT_AUDITING_COMPANY } from 'api/mutations/auditingCompanyMutations';

export default function AuditingCompaniesCreate() {
	let navigate = useNavigate();

	const [createNewAuditingCompany, { loading, error }] = useMutation(
		INSERT_AUDITING_COMPANY
	);

	return (
		<DesktopLayout
			breadcrumbs={[
				{
					to: '/revisorfirma/',
					label: 'Revisorfirma',
				},
				{
					label: 'Nytt revisorfirma',
				},
			]}
		>
			<NarrowContent>
				<ErrorMessage errors={error} />

				<Form
					onSubmit={async variables => {
						const { data } = await createNewAuditingCompany({
							variables: {
								...variables,
								orgNo: variables?.orgNo?.replaceAll(' ', ''),
							},
						});

						if (data && !error) {
							if (data) {
								navigate('../');
							}
						}
					}}
					isLoading={loading}
					isDisabled={loading}
				>
					<TextField name="name" label="Navn" required />

					<TextField
						name="orgNo"
						label="Organisasjonsnr"
						required
						pattern="^\s*(\d{3}\s*\d{3}\s*\d{3}|\d{9})\s*$"
					/>

					<TextField name="email" type="email" label="E-post" />

					<TextField name="phone" type="tel" label="Telefon" />

					<SubmitButton
						variant="contained"
						size="large"
						icon={<SaveIcon />}
					>
						Nytt revisorfirma
					</SubmitButton>
				</Form>
			</NarrowContent>
		</DesktopLayout>
	);
}
