import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router';
import debounce from 'lodash/debounce';
import styled from 'styled-components';
import MUITextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

import { useAppFilters, actionTypes } from 'web/contexts/AppFiltersContext';

const SearchBarWrapper = styled.div`
	margin: 0;
	flex: 1 1 0%;
	min-width: 300px;
`;

export default function SearchFilter({ label }) {
	const { dispatch } = useAppFilters();
	const inputRef = useRef();

	const [params] = useSearchParams();
	const searchParam = params.get('search');

	// We can't use the value directly beacuse we want to debounce the search.
	const handleChange = debounce(
		({ target }) =>
			dispatch({ type: actionTypes.SET_SEARCH, payload: target.value }),
		750
	);

	useEffect(() => {
		dispatch({ type: actionTypes.SET_SEARCH, payload: searchParam });
		inputRef.current.value = searchParam;
	}, [searchParam]);

	return (
		<SearchBarWrapper>
			<MUITextField
				label={label}
				inputRef={inputRef}
				slotProps={{
					input: {
						startAdornment: (
							<InputAdornment position="start">
								<SearchIcon />
							</InputAdornment>
						),
					},
				}}
				variant={'outlined'}
				fullWidth={true}
				onChange={handleChange}
			/>
		</SearchBarWrapper>
	);
}
