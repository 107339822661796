import { useUser } from 'web/contexts/UserContext';
import { useAppFilters, actionTypes } from 'web/contexts/AppFiltersContext';

import Filter from 'components/filters/Filter';

const allOption = { value: '', label: 'Aktive bedrifter' };

export default function CompanyFilter({ disabled = false, size = 'medium' }) {
	const { userHasMinRole } = useUser();
	const { company, dispatch } = useAppFilters();

	return (
		<Filter
			name="companies-filter"
			label="Vis bedrifter"
			value={company ?? allOption}
			options={[
				allOption,
				{
					value: 'favorites',
					label: 'Favorittbedrifter',
				},
				...(userHasMinRole('admin')
					? [
							{
								value: 'inactive',
								label: 'Inaktive bedrifter',
							},
						]
					: []),
			]}
			onChange={value =>
				dispatch({
					type: actionTypes.SET_COMPANY,
					payload: value,
				})
			}
			disabled={disabled}
			size={size}
		/>
	);
}
