import styled from 'styled-components';
import { Link } from 'react-router';

export const Wrapper = styled.div`
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 10;

	@media print {
		position: initial;
	}
`;

export const Header = styled.div`
	background-color: ${p => p.theme.palette.background.gray};
	display: flex;
	flex-direction: row;
	align-items: center;
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	height: 68px;
	z-index: 12;

	@media print {
		display: none;
	}
`;

export const LeftHeaderSection = styled.div`
	width: 274px;
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export const MenuButton = styled.button`
	background: none;
	border: none;
	cursor: pointer;
	height: 68px;
	margin-top: -17px;
	margin-bottom: -17px;
	padding: 17px 15px;
`;

export const Logo = styled.img`
	height: 15px;
`;

export const BreadcrumbWrapper = styled.div`
	margin-right: 250px;
	padding: 0 10px;
	white-space: nowrap;
	overflow-x: auto;
	overflow-y: hidden;
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export const BreadcumbElement = styled.div`
	color: ${p => p.theme.palette.text.secondary};
	overflow: hidden;
	text-overflow: ellipsis;
	flex: 0 auto;
	flex-shrink: 1000;
	padding: 8px 10px 8px 20px;
	position: relative;

	&:before {
		content: '>';
		display: block;
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
	}

	&:first-child {
		padding: 8px 20px;
		flex: 0 0 auto;
		flex-shrink: 0.5;
		&:before {
			display: none;
		}
	}

	&:last-child {
		flex: 1 0 auto;
		color: ${p => p.theme.palette.text.primary};
	}
`;

export const HelpButton = styled(Link)`
	margin-left: auto;
	padding: 17px 15px;
	background: none;
	border: none;
	color: ${p => p.theme.palette.text.primary};
`;

export const Main = styled.div`
	position: absolute;
	top: 68px;
	right: 0;
	bottom: 0;
	overflow: auto;
	scrollbar-gutter: stable both-edges;
	left: ${p => (p.$isSidebarOpen ? '260px' : '0')};

	@media print {
		position: initial;
		scrollbar-gutter: auto;
	}
`;

export const Content = styled.div`
	box-sizing: border-box;
	margin: 0 auto;
	position: relative;
	padding: 30px 30px 200px 30px;
	width: 100%;
	max-width: ${p => (p.fullWidth ? 'none' : '2500px')};
	min-height: 100%;

	@media print {
		padding: 20px;
	}
`;
