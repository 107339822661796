import { useState, useRef } from 'react';
import { useMutation } from '@apollo/client';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import AttachFileIcon from '@mui/icons-material/AttachFile';

import {
	removeFileExtension,
	getFileExtension,
	fileSlugify,
} from 'utils/strings';
import { upload } from 'utils/fileHandling';

import { useSnackbar } from 'web/contexts/SnackbarContext';

import ErrorMessage from 'components/ErrorMessage';

import Form from 'components/form/Form';
import FileField from 'components/form/FileField';
import TextField from 'components/form/TextField';
import SubmitButton from 'components/form/SubmitButton';

import { GET_FILES } from 'api/queries/fileQueries';
import {
	INSERT_ONE_FILE,
	REQUEST_FILE_UPLOAD,
} from 'api/mutations/fileMutations';

export default function FileUploadDialog({
	docId,
	uploadContext,
	open,
	onClose,
}) {
	const { notify } = useSnackbar();
	const nameInputEl = useRef(null);

	const [fileUploadLoading, setFileUploadLoading] = useState(false);
	const [fileUploadError, setFileUploadError] = useState(null);

	const [
		requestFileUpload,
		{ loading: requestFileUploadLoading, error: requestFileUploadError },
	] = useMutation(REQUEST_FILE_UPLOAD);
	const [
		insertOneFile,
		{ loading: insertOneFileLoading, error: insertOneFileError },
	] = useMutation(INSERT_ONE_FILE, {
		refetchQueries: [
			{
				query: GET_FILES,
				variables: { docId: docId },
			},
		],
	});

	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth={true}
			maxWidth="xs"
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">Last opp fil</DialogTitle>

			<Form
				isLoading={
					fileUploadLoading ||
					requestFileUploadLoading ||
					insertOneFileLoading
				}
				isDisabled={
					fileUploadLoading ||
					requestFileUploadLoading ||
					insertOneFileLoading
				}
				onSubmit={async values => {
					try {
						setFileUploadError(null);
						setFileUploadLoading(true);

						const { file, name } = values;

						const fileName = fileSlugify(
							name,
							getFileExtension(file.name)
						);

						const { data: { uploadUrl = null } = {} } =
							await requestFileUpload({
								variables: {
									uploadContext,
									fileName,
									contentType: file.type,
								},
							});

						if (!uploadUrl) {
							setFileUploadError(
								'Feil ved opplasting til server!'
							);
							return;
						}

						await upload(uploadUrl, file, {
							'Content-Type': file.type,
						});

						const { data } = await insertOneFile({
							variables: {
								fileName,
								fileType: file.type,
								docId,
								url: uploadUrl.split('?')[0],
							},
						});

						if (data?.file) {
							notify('Filen ble lastet opp!');

							onClose();
						} else {
							setFileUploadError(
								'Feil ved opplasting av fil til databasen!'
							);
						}
					} catch (err) {
						console.error(err);

						setFileUploadError('Feil ved opplasting av fil!');
					} finally {
						setFileUploadLoading(false);
					}
				}}
			>
				<DialogContent>
					<ErrorMessage
						errors={[
							fileUploadError,
							requestFileUploadError,
							insertOneFileError,
						]}
					/>

					<FileField
						required
						name="file"
						label="Velg fil"
						helperText="Filen kan maksimalt være 20MB"
						accept=".xlsx,.xls,image/*,.doc,.docx,.ppt,.pptx,.txt,.pdf"
						maxSize="20000000"
						onChange={(file, { updateField }) => {
							//TODO: Convert non latin to latin quickfix.
							const filename = file.name
								.replace('Å', 'Å')
								.replace('å', 'å');

							const name = removeFileExtension(filename);

							// Update the name filed with the filename without the extension.
							updateField({
								name: 'name',
								value: name,
							});

							nameInputEl.current.value = name;
							nameInputEl.current.select();
						}}
					/>

					<TextField
						inputRef={nameInputEl}
						name="name"
						label="Navngi filen"
						required
					/>
				</DialogContent>

				<DialogActions>
					<Button onClick={onClose}>Avbryt</Button>

					<SubmitButton icon={<AttachFileIcon />} variant="contained">
						Last opp fil
					</SubmitButton>
				</DialogActions>
			</Form>
		</Dialog>
	);
}
