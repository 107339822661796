import styled from 'styled-components';
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Skeleton from '@mui/material/Skeleton';

import { calculatePercentage } from 'utils/numbers';

const ListWrapper = styled.div`
	margin-bottom: 20px;
	padding-right: 40px;

	&:last-of-type {
		margin-bottom: 0;
	}
`;

const ListTitle = styled.div`
	font-weight: bold;
	margin: 0 0 5px 0;
`;

export function WidgetList({ title = null, children }) {
	return (
		<ListWrapper>
			{title && <ListTitle>{title}</ListTitle>}

			<div>{children}</div>
		</ListWrapper>
	);
}

const ListItemWrapper = styled.div`
	position: relative;
`;

const ListItemText = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 20px;
`;

const ListItemAction = styled.div`
	position: absolute;
	top: -2px;
	right: -40px;
`;

export function WidgetListItem({
	label,
	value,
	loading = false,
	hideIfEmpty = false,
	total = null,
	onClick = null,
}) {
	if (loading) {
		return <WidgetListItemSkeleton />;
	}

	if (hideIfEmpty && !value) {
		return null;
	}

	return (
		<ListItemWrapper>
			<ListItemText>
				<div>{label}</div>

				<div>
					<span>{value}</span>

					{total !== null && (
						<span>
							{' '}
							/ {calculatePercentage(value, total, 0, 0)}
						</span>
					)}
				</div>
			</ListItemText>

			{onClick && (
				<ListItemAction>
					<IconButton
						title="Vis mer informasjon"
						size="small"
						onClick={onClick}
						disabled={!value}
					>
						<AddCircleOutlineIcon fontSize="inherit" />
					</IconButton>
				</ListItemAction>
			)}
		</ListItemWrapper>
	);
}

export function WidgetListItemSkeleton() {
	return (
		<ListItemWrapper>
			<ListItemText>
				<Skeleton variant="text" sx={{ width: '50%' }} />

				<Skeleton variant="text" sx={{ width: '15px' }} />
			</ListItemText>

			<ListItemAction>
				<IconButton size="small" disabled={true}>
					<AddCircleOutlineIcon fontSize="inherit" />
				</IconButton>
			</ListItemAction>
		</ListItemWrapper>
	);
}

const Label = styled.div`
	display: flex;
	align-items: center;
	gap: 5px;
`;

const Chip = styled.div`
	background-color: ${({ $chipColor }) => $chipColor};
	border-radius: 100px;
	width: 15px;
	height: 15px;
`;

export function WidgetListLabel({ label, chipColor = null }) {
	return (
		<Label>
			{chipColor && <Chip $chipColor={chipColor} />}

			<div>{label}</div>
		</Label>
	);
}
