import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useQuery } from '@apollo/client';

import useSortOrder from 'hooks/useSortOrder';

import { useUser } from 'web/contexts/UserContext';
import { useAppFilters } from 'web/contexts/AppFiltersContext';

import UsersCreateDialog from 'web/screens/dialogs/users/UsersCreateDialog';

import ErrorMessage from 'components/ErrorMessage';
import { InlineLoading } from 'components/Loading';

import SearchFilter from 'components/filters/SearchFilter';

import DesktopLayout from 'web/components/Layout';
import ContentWithSidebarFilters from 'web/components/ContentWithSidebarFilters';
import Table, { TableRow, TableCell } from 'web/components/Table';
import Link from 'web/components/Link';
import SortIcon from 'web/components/SortIcon';
import CreateFabButton from 'web/components/CreateFabButton';

import { PAGINATE_ACCOUNTANTS } from 'api/queries/userQueries';

const displayFilters = ['accountantRoleLegacy'];

export default function AccountantsArchive() {
	const navigate = useNavigate();
	const { userHasMinRole } = useUser();
	const [{ orderBy, order }, setNewOrder] = useSortOrder('name');

	const { search, accountantRoleLegacy } = useAppFilters();

	const [accountantsList, setAccountantsList] = useState([]);
	const [showUserCreateDialog, setShowUserCreateDialog] = useState(false);

	const {
		data: { accountants = { count: 0, items: [] } } = {
			accountants: { count: 0, items: [] },
		},
		loading,
		error,
	} = useQuery(PAGINATE_ACCOUNTANTS, {
		variables: {
			search,
			order,
			orderBy,
		},
	});

	useEffect(() => {
		if (error || loading) return;

		if (accountantRoleLegacy && accountantRoleLegacy.value) {
			setAccountantsList(
				accountants.items.filter(accountant => {
					switch (accountantRoleLegacy.value) {
						case 'accountant':
							return (
								accountant.accountantRoles?.accountantTenants
									?.length > 0
							);

						case 'accountManager':
							return (
								accountant.accountantRoles
									?.accountManagerTenants?.length > 0
							);

						case 'controller':
							return (
								accountant.accountantRoles?.controllerTenants
									?.length > 0
							);

						default:
							console.error(
								'Unknown accountant role: ',
								accountantRoleLegacy.value
							);
							return true;
					}
				})
			);
		} else {
			setAccountantsList(accountants.items);
		}
	}, [accountants?.items, accountantRoleLegacy?.value]);

	return (
		<DesktopLayout
			breadcrumbs={[
				{
					label: 'Regnskapsførere',
				},
			]}
			title="Regnskapsførere"
		>
			<ContentWithSidebarFilters
				displayFilters={displayFilters}
				headerComponents={
					<SearchFilter label="Søk etter regnskapsfører" />
				}
			>
				<ErrorMessage errors={error} />

				<Table columns="7">
					<TableRow header>
						<TableCell
							start="1"
							end="3"
							onClick={() => setNewOrder('name')}
						>
							<span>Navn</span>

							<SortIcon
								field="name"
								orderBy={orderBy}
								order={order}
							/>
						</TableCell>

						<TableCell>Regnskapsfører</TableCell>

						<TableCell>Kundeansvarlig</TableCell>

						<TableCell>Oppdragskontrollør</TableCell>

						<TableCell>Stilling</TableCell>

						<TableCell onClick={() => setNewOrder('role')}>
							<span>Rolle</span>

							<SortIcon
								field="role"
								orderBy={orderBy}
								order={order}
							/>
						</TableCell>
					</TableRow>

					{!loading && accountantsList?.length === 0 && (
						<TableRow transparent>
							<TableCell start="1" end="-1" center>
								Ingen regnskapsførere ble funnet
							</TableCell>
						</TableRow>
					)}

					{!error &&
						accountantsList.map(accountant => (
							<Link
								to={`/brukere/${accountant._id}`}
								key={accountant._id}
							>
								<TableRow hover>
									<TableCell start="1" end="3">
										{accountant.name}
									</TableCell>

									<TableCell>
										{accountant?.accountantRoles
											?.accountantTenants?.length ?? 0}
									</TableCell>

									<TableCell>
										{accountant?.accountantRoles
											?.accountManagerTenants?.length ??
											0}
									</TableCell>

									<TableCell>
										{accountant?.accountantRoles
											?.controllerTenants?.length ?? 0}
									</TableCell>

									<TableCell>
										{accountant?.position}
									</TableCell>

									<TableCell>{accountant.roleName}</TableCell>
								</TableRow>
							</Link>
						))}

					{loading && (
						<TableRow transparent>
							<TableCell start="1" end="-1">
								<InlineLoading />
							</TableCell>
						</TableRow>
					)}

					{!error && !loading && accountantsList?.length !== 0 && (
						<TableRow footer>
							<TableCell start="1" end="-1" right>
								Viser {accountantsList.length} av{' '}
								{accountants.count} regnskapsførere
							</TableCell>
						</TableRow>
					)}
				</Table>
			</ContentWithSidebarFilters>

			{userHasMinRole('accountant') && (
				<CreateFabButton
					onClick={() => setShowUserCreateDialog(true)}
				/>
			)}

			<UsersCreateDialog
				title="Opprett regnskapsfører"
				defaultRole="accountant"
				open={showUserCreateDialog}
				onClose={() => setShowUserCreateDialog(false)}
				onSubmitSuccess={user => {
					setShowUserCreateDialog(false);

					navigate(`/brukere/${user._id}/`);
				}}
			/>
		</DesktopLayout>
	);
}
