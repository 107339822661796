import { Fragment } from 'react';
import styled from 'styled-components';
import { useQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import SaveIcon from '@mui/icons-material/Save';

import { pluralize } from 'utils/strings';

import { useSnackbar } from 'web/contexts/SnackbarContext';

import ErrorAlert from 'components/ErrorAlert';
import ErrorMessage from 'components/ErrorMessage';
import DeleteButton from 'components/buttons/DeleteButton';

import DesktopLayout, {
	NarrowContent,
	StickyAction,
} from 'web/components/Layout';
import { UnderlineLink } from 'web/components/Link';
import Form from 'components/form/Form';
import TextField from 'components/form/TextField';
import SubmitButton from 'components/form/SubmitButton';

import { FIND_AUDITING_COMPANY } from 'api/queries/auditingCompanyQueries';
import { UPDATE_AUDITING_COMPANY } from 'api/mutations/auditingCompanyMutations';
import { DELETE_AUDITING_COMPANY } from 'api/mutations/auditingCompanyMutations';

export default function AuditingCompaniesCreate() {
	let { auditingCompanyId } = useParams();
	const { notify } = useSnackbar();

	const {
		data: { auditingCompany } = { auditingCompany: null },
		loading: auditingCompanyLoading,
		error: auditingCompanyError,
	} = useQuery(FIND_AUDITING_COMPANY, {
		variables: {
			_id: auditingCompanyId,
		},
		errorPolicy: 'all',
	});

	const [
		updateAuditingCompany,
		{
			loading: updateAuditingCompanyLoading,
			error: updateAuditingCompanyError,
		},
	] = useMutation(UPDATE_AUDITING_COMPANY);

	const [
		deleteAuditingCompany,
		{
			loading: deleteAuditingCompanyLoading,
			error: deleteAuditingCompanyError,
		},
	] = useMutation(DELETE_AUDITING_COMPANY);

	return (
		<DesktopLayout
			breadcrumbs={[
				{
					to: '/revisorfirma/',
					label: 'Revisorfirma',
				},
				{
					label: 'Oppdater revisorfirma',
				},
			]}
		>
			<NarrowContent>
				<ErrorMessage errors={[auditingCompanyError]} />

				<ErrorAlert error={updateAuditingCompanyError} />
				<ErrorAlert error={deleteAuditingCompanyError} />

				<Form
					key={auditingCompany && auditingCompany._id}
					values={auditingCompany}
					onSubmit={async variables => {
						const { data } = await updateAuditingCompany({
							variables: {
								...variables,
								orgNo: variables?.orgNo?.replaceAll(' ', ''),
							},
						});

						if (data) {
							notify(
								`${data.auditingCompany.name} ble oppdatert!`
							);
						}
					}}
					isLoading={
						auditingCompanyLoading ||
						updateAuditingCompanyLoading ||
						deleteAuditingCompanyLoading
					}
					isDisabled={
						auditingCompanyLoading ||
						updateAuditingCompanyLoading ||
						deleteAuditingCompanyLoading
					}
				>
					<TextField name="name" label="Navn" required />

					<TextField
						name="orgNo"
						label="Organisasjonsnr"
						required
						pattern="^\s*(\d{3}\s*\d{3}\s*\d{3}|\d{9})\s*$"
					/>

					<TextField name="email" type="email" label="E-post" />

					<TextField name="phone" type="tel" label="Telefon" />

					<Auditors
						title={pluralize(
							'Tilknyttet revisor',
							'Tilknyttet revisorer',
							auditingCompany?.auditors
						)}
						auditors={auditingCompany?.auditors}
					/>

					<StickyAction>
						<DeleteButton
							disabled={deleteAuditingCompanyLoading}
							onClick={async () => {
								const { data } = await deleteAuditingCompany({
									variables: {
										_id: auditingCompany._id,
									},
								});

								if (!data.deleted) {
									throw new Error(
										'Det oppstod en feil ved sletting!'
									);
								}
							}}
							redirect="/revisorfirma/"
						/>

						<SubmitButton
							variant="contained"
							size="large"
							icon={<SaveIcon />}
						>
							Oppdater
						</SubmitButton>
					</StickyAction>
				</Form>
			</NarrowContent>
		</DesktopLayout>
	);
}

const AuditorsWrapper = styled.div`
	margin-top: 40px;
`;

function Auditors({ title, auditors }) {
	if (!auditors) return null;

	return (
		<AuditorsWrapper>
			<h3>{title}</h3>

			<List>
				{auditors.map((auditor, index) => (
					<Fragment key={index}>
						<ListItem>
							<ListItemText
								primary={
									<UnderlineLink
										to={`/brukere/${auditor._id}/`}
									>
										{auditor.name}
									</UnderlineLink>
								}
							/>
						</ListItem>
						<Divider component="li" />
					</Fragment>
				))}
			</List>
		</AuditorsWrapper>
	);
}
