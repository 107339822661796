import { useAppFilters, actionTypes } from 'web/contexts/AppFiltersContext';

import Filter from 'components/filters/Filter';

const currentYear = new Date().getFullYear();

const yearOptions = [
	currentYear - 2,
	currentYear - 1,
	currentYear,
	currentYear + 1,
];

export default function YearFilter({ disabled = false, size = 'medium' }) {
	const { year, dispatch } = useAppFilters();

	return (
		<Filter
			name="year-filter"
			label="Vis år"
			value={year}
			options={yearOptions.map(year => ({
				value: year,
				label: year.toString(),
			}))}
			onChange={value =>
				dispatch({
					type: actionTypes.SET_YEAR,
					payload: value,
				})
			}
			disabled={disabled}
			size={size}
			minWidth="100px"
			disableClearable={true}
		/>
	);
}
