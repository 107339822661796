import { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useParams, useNavigate } from 'react-router';
import styled from 'styled-components';
import Grid from '@mui/material/Grid2';
import Fab from '@mui/material/Fab';
import IconButton from '@mui/material/IconButton';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import DeleteIcon from '@mui/icons-material/Delete';

import { useConfirmDialog } from 'components/contexts/ConfirmContext';
import { useSnackbar } from 'web/contexts/SnackbarContext';

import FileListWithUploadDialog from 'web/screens/dialogs/users/FileListWithUploadDialog';

import ErrorMessage from 'components/ErrorMessage';

import DeleteButton from 'components/buttons/DeleteButton';

import DesktopLayout, {
	NarrowContent,
	StickyAction,
} from 'web/components/Layout';

import Form, { Button, FormGroup, TextField } from 'web/form/Form';

import { FIND_ONE_HELP_PAGE } from 'api/queries/helpPageQueries';
import {
	UPDATE_HELP_PAGE,
	DELETE_HELP_PAGE,
	INSERT_ONE_HELP_PAGE_SECTION,
	UPDATE_ONE_HELP_PAGE_SECTION,
	DELETE_ONE_HELP_PAGE_SECTION,
} from 'api/mutations/helpPageMutations';

export default function HelpPagesEdit() {
	const { helpPageId } = useParams();
	const { notify } = useSnackbar();
	const navigate = useNavigate();

	const { data: { helpPage } = { helpPage: null }, error: helpPageError } =
		useQuery(FIND_ONE_HELP_PAGE, {
			variables: {
				_id: helpPageId,
			},
		});

	const [
		updateHelpPage,
		{ loading: updateHelpPageLoading, error: updateHelpPageError },
	] = useMutation(UPDATE_HELP_PAGE);

	const [
		deleteHelpPage,
		{ loading: deleteHelpPageLoading, error: deleteHelpPageError },
	] = useMutation(DELETE_HELP_PAGE);

	return (
		<DesktopLayout
			breadcrumbs={[
				{
					to: '/hjelpesider/',
					label: 'Hjelpesider',
				},
				{
					label: 'Oppdater hjelpeside',
				},
			]}
		>
			<NarrowContent>
				<Form
					values={helpPage}
					onSubmit={async variables => {
						try {
							const { data } = await updateHelpPage({
								variables,
							});

							if (data) {
								notify(`${data.helpPage.name} ble oppdatert!`);

								navigate('/hjelpesider/');
							}
						} catch (err) {
							console.error(err);
						}
					}}
				>
					<ErrorMessage
						errors={[
							helpPageError,
							updateHelpPageError,
							deleteHelpPageError,
						]}
					/>

					<FormGroup>
						<TextField
							name="name"
							label="Navn på hjelpeside"
							required
							fullWidth
						/>
					</FormGroup>

					<StickyAction>
						<DeleteButton
							disabled={deleteHelpPageLoading}
							onClick={async () => {
								const { data } = await deleteHelpPage({
									variables: {
										_id: helpPage._id,
									},
								});

								if (!data.deleted) {
									throw new Error(
										'Det oppstod en feil ved sletting!'
									);
								}
							}}
							redirect="/hjelpesider/"
						/>

						<Button
							type="submit"
							disabled={updateHelpPageLoading}
							variant="contained"
							size="large"
							startIcon={<SaveIcon />}
						>
							Oppdater
						</Button>
					</StickyAction>
				</Form>

				<HelpPageSections helpPage={helpPage} />

				<AddHelpPageSectionForm helpPageId={helpPageId} />
			</NarrowContent>
		</DesktopLayout>
	);
}

const NoSectionsMessage = styled.div`
	background-color: ${p => p.theme.palette.background.paper};
	font-style: italic;
	margin-bottom: 20px;
	padding: 20px;
`;

function HelpPageSections({ helpPage }) {
	if (!helpPage) {
		return null;
	}

	if (!helpPage.sections || helpPage.sections.length === 0) {
		return (
			<NoSectionsMessage>
				Ingen avsnitt er lagt til ennå.
			</NoSectionsMessage>
		);
	}

	return helpPage.sections.map(section => (
		<HelpPageSection
			key={section._id}
			helpPageId={helpPage._id}
			section={section}
		/>
	));
}

const HelpPageSectionWrapper = styled.div`
	background-color: ${p => p.theme.palette.background.paper};
	margin-bottom: 20px;
	padding: 20px;
`;

function HelpPageSection({ helpPageId, section }) {
	return (
		<div>
			<HelpPageSectionWrapper>
				<HelpPageSectionHeader
					helpPageId={helpPageId}
					sectionId={section._id}
					title={section.title}
				/>

				<FileListWithUploadDialog
					docId={section._id}
					uploadContext="helpPage"
				/>
			</HelpPageSectionWrapper>
		</div>
	);
}

const HelpPageSectionDeleteWrapper = styled.div`
	position: absolute;
	top: -30px;
	right: -30px;
`;

function HelpPageSectionHeader({ helpPageId, sectionId, title }) {
	const { verify } = useConfirmDialog();
	const { notify } = useSnackbar();

	const [deleteSection, { loading, error }] = useMutation(
		DELETE_ONE_HELP_PAGE_SECTION,
		{
			refetchQueries: ['findOneHelpPage'],
		}
	);

	return (
		<HelpPageSectionHeaderWrapper>
			<HelpPageSectionTitle
				helpPageId={helpPageId}
				sectionId={sectionId}
				title={title}
			/>

			<ErrorMessage errors={[error]} />

			<HelpPageSectionDeleteWrapper>
				<Fab
					aria-label="Fjern seksjon"
					size="small"
					color="gray"
					disabled={loading}
					onClick={async () => {
						try {
							if (
								!(await verify({
									title: 'Fjern avsnitt',
									text: 'Er du sikker på at du ønsker å fjerne avsnittet? Eventuelle filer i seksjonen vil også bli slettet.',
								}))
							) {
								return;
							}

							const { data } = await deleteSection({
								variables: {
									helpPageId,
									sectionId,
								},
							});

							if (data.deleted) {
								notify('Avsnitt slettet!');
							}
						} catch (err) {
							console.error(err);
						}
					}}
				>
					<DeleteIcon fontSize="small" />
				</Fab>
			</HelpPageSectionDeleteWrapper>
		</HelpPageSectionHeaderWrapper>
	);
}

const HelpPageSectionHeaderWrapper = styled.div`
	position: relative;
`;

const HelpPageSectionTitleH2 = styled.h2`
	margin: 0;
`;

const HelpPageSectionTitleWrapper = styled.div`
	display: flex;
`;

const HelpPageSectionTitleFormContent = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`;

function HelpPageSectionTitle({ helpPageId, sectionId, title }) {
	const { notify } = useSnackbar();

	const [isEditing, setIsEditing] = useState(false);

	const [updateSection, { loading, error }] = useMutation(
		UPDATE_ONE_HELP_PAGE_SECTION,
		{
			refetchQueries: ['findOneHelpPage'],
		}
	);

	if (isEditing) {
		return (
			<Form
				values={{ title }}
				onSubmit={async ({ title }) => {
					try {
						const { data } = await updateSection({
							variables: {
								helpPageId,
								sectionId,
								title,
							},
						});

						setIsEditing(false);

						if (data.updated) {
							notify('Avsnitt ble oppdatert!');
						}
					} catch (err) {
						console.error(err);
					}
				}}
			>
				<ErrorMessage errors={[error]} />

				<HelpPageSectionTitleFormContent>
					<TextField
						name="title"
						label="Tittel"
						size="small"
						required
						fullWidth
					/>

					<Button size="small" onClick={() => setIsEditing(false)}>
						Avbryt
					</Button>

					<Button
						disabled={loading}
						type="submit"
						variant="contained"
						size="small"
						startIcon={<SaveIcon />}
					>
						Lagre
					</Button>
				</HelpPageSectionTitleFormContent>
			</Form>
		);
	}

	return (
		<HelpPageSectionTitleWrapper>
			<HelpPageSectionTitleH2>{title}</HelpPageSectionTitleH2>

			<IconButton
				aria-label="Rediger"
				disabled={loading}
				onClick={() => setIsEditing(true)}
			>
				<EditIcon />
			</IconButton>
		</HelpPageSectionTitleWrapper>
	);
}

function AddHelpPageSectionForm({ helpPageId }) {
	const { notify } = useSnackbar();

	const [
		insertSection,
		{ loading: insertSectionLoading, error: insertSectionError },
	] = useMutation(INSERT_ONE_HELP_PAGE_SECTION, {
		refetchQueries: ['findOneHelpPage'],
	});

	return (
		<Form
			onSubmit={async ({ title }, { reset }) => {
				try {
					const { data } = await insertSection({
						variables: {
							helpPageId,
							title,
						},
					});

					if (data?.helpPage?._id) {
						reset();
						notify('Nytt avsnitt lagt til!');
					}
				} catch (err) {
					console.error(err);
				}
			}}
		>
			<ErrorMessage errors={[insertSectionError]} />

			<Grid container spacing={2}>
				<Grid size={8}>
					<TextField
						name="title"
						label="Tittel"
						size="small"
						required
						fullWidth
					/>
				</Grid>

				<Grid size={4}>
					<Button
						type="submit"
						variant="contained"
						disabled={insertSectionLoading}
						startIcon={<FormatListBulletedIcon />}
						fullWidth
					>
						Legg til avsnitt
					</Button>
				</Grid>
			</Grid>
		</Form>
	);
}
