import { useNavigate } from 'react-router';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

export default function CreateFabButton({ to, onClick = null }) {
	const navigate = useNavigate();

	return (
		<>
			<Fab
				color="primary"
				onClick={() => {
					if (to) {
						navigate(to);
					}
					if (onClick) {
						onClick();
					}
				}}
				sx={{ position: 'fixed', bottom: '5vw', right: '5vw' }}
			>
				<AddIcon />
			</Fab>
		</>
	);
}
