import { WidgetList } from 'web/components/widgets/components/WidgetList';
import WidgetListData from 'web/components/widgets/components/WidgetListData';

import Widget from 'web/components/widgets/Widget';

import { COMPANIES_WITH_COMPANY_TYPE } from 'api/queries/widgetQueries';

export default function CompanyTypesWidget() {
	return (
		<Widget title="Selskapsformer" titleIcon="building">
			<WidgetList>
				<WidgetListData
					label="Aksjeselskap"
					query={COMPANIES_WITH_COMPANY_TYPE}
					variables={{ companyTypeId: 'AS' }}
					link={id => `/bedrifter/${id}/rediger/`}
					hideIfEmpty
				/>

				<WidgetListData
					label="Enkeltpersonforetak"
					query={COMPANIES_WITH_COMPANY_TYPE}
					variables={{ companyTypeId: 'ENK' }}
					link={id => `/bedrifter/${id}/rediger/`}
					hideIfEmpty
				/>

				<WidgetListData
					label="Forening/lag/innretning"
					query={COMPANIES_WITH_COMPANY_TYPE}
					variables={{ companyTypeId: 'FLI' }}
					link={id => `/bedrifter/${id}/rediger/`}
					hideIfEmpty
				/>

				<WidgetListData
					label="Samvirkeforetak"
					query={COMPANIES_WITH_COMPANY_TYPE}
					variables={{ companyTypeId: 'SA' }}
					link={id => `/bedrifter/${id}/rediger/`}
					hideIfEmpty
				/>

				<WidgetListData
					label="Ansvarlig selskap med delt ansvar"
					query={COMPANIES_WITH_COMPANY_TYPE}
					variables={{ companyTypeId: 'DA' }}
					link={id => `/bedrifter/${id}/rediger/`}
					hideIfEmpty
				/>

				<WidgetListData
					label="Eierseksjonssameie"
					query={COMPANIES_WITH_COMPANY_TYPE}
					variables={{ companyTypeId: 'ESEK' }}
					link={id => `/bedrifter/${id}/rediger/`}
					hideIfEmpty
				/>

				<WidgetListData
					label="Stiftelse"
					query={COMPANIES_WITH_COMPANY_TYPE}
					variables={{ companyTypeId: 'STI' }}
					link={id => `/bedrifter/${id}/rediger/`}
					hideIfEmpty
				/>

				<WidgetListData
					label="Tingretttslig sameie"
					query={COMPANIES_WITH_COMPANY_TYPE}
					variables={{ companyTypeId: 'SAM' }}
					link={id => `/bedrifter/${id}/rediger/`}
					hideIfEmpty
				/>

				<WidgetListData
					label="Borettslag"
					query={COMPANIES_WITH_COMPANY_TYPE}
					variables={{ companyTypeId: 'BRL' }}
					link={id => `/bedrifter/${id}/rediger/`}
					hideIfEmpty
				/>
			</WidgetList>
		</Widget>
	);
}
