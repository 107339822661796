import { roles } from 'server/context/roles';

import { useAppFilters, actionTypes } from 'web/contexts/AppFiltersContext';

import Filter from 'components/filters/Filter';

const allOption = { value: '', label: 'Alle brukere' };

export default function UserRolesFilter({ disabled = false, size = 'medium' }) {
	const { userRole, dispatch } = useAppFilters();

	return (
		<Filter
			name="userroles-filter"
			label="Vis brukere"
			value={userRole || allOption}
			options={[
				allOption,
				...roles.map(({ _id, name }) => ({
					value: _id,
					label: name,
				})),
			]}
			onChange={value =>
				dispatch({
					type: actionTypes.SET_USER_ROLE,
					payload: value,
				})
			}
			disabled={disabled}
			size={size}
		/>
	);
}
