import { createContext, useState, useContext, useEffect } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { ThemeProvider } from 'styled-components';

import {
	ApolloClient,
	ApolloProvider,
	HttpLink,
	ApolloLink,
	concat,
	InMemoryCache,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import UserProvider from 'app/contexts/UserProvider';

import MoveDocumentContextMenuProvider from 'components/contexts/MoveDocumentContextMenuContext';
import ConfirmProvider from 'components/contexts/ConfirmContext';
import MainMenuProvider from 'app/menus/MainMenu';
import { NavigatorProvider } from 'app/components/Navigator';
import theme from 'web/theme/theme';
import { CURRENT_USER } from 'api/queries/userQueries';

const APP_DATA_KEY = 'admento-app-20241001';
let APP_DATA = localStorage.getItem(APP_DATA_KEY);

try {
	APP_DATA = JSON.parse(APP_DATA);
} catch (error) {
	console.warn(error);
}

export function parseJwt(token) {
	if (!token) return;

	const base64Url = token.split('.')[1];
	const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
	const decoded = JSON.parse(decodeURIComponent(atob(base64)));

	return decoded;
}

export const AppContext = createContext({
	set: () => {},
	data: APP_DATA || {},
});

export const useApp = () => useContext(AppContext);

export default function AppProviders({ children }) {
	const [data, _setData] = useState(APP_DATA || {});

	const httpLink = new HttpLink({
		uri: `${process.env.REACT_APP_API_BASE_URL}/graphql`,
		credentials: 'same-origin',
	});

	const authLink = new ApolloLink((operation, forward) => {
		const { apiToken } = data;
		operation.setContext({
			headers: {
				Authorization: apiToken ? 'Bearer ' + apiToken : '',
				Platform: typeof cordova !== 'undefined' ? 'mobile' : 'web',
			},
		});

		return forward(operation);
	});

	const errorLink = onError(({ graphQLErrors, networkError }) => {
		if (graphQLErrors)
			graphQLErrors.forEach(({ message, locations, path }) =>
				console.warn(
					`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
				)
			);
		if (networkError) console.warn(`[Network error]: ${networkError}`);
	});

	const client = new ApolloClient({
		link: concat(authLink, httpLink, errorLink),
		cache: new InMemoryCache(),
		defaultOptions: {
			watchQuery: {
				fetchPolicy: 'cache-and-network',
			},
		},
	});

	function setData(values) {
		if (values?.apiToken && !data?.user) {
			values.user = parseJwt(values.apiToken);
		}
		const updatedData = { ...data, ...values };

		localStorage.setItem(APP_DATA_KEY, JSON.stringify(updatedData));
		_setData(updatedData);
	}

	useEffect(() => {
		if (data.apiToken) {
			client
				.query({ query: CURRENT_USER })
				.then(res => setData({ user: res.data.currentUser }))
				.catch(err => console.warn(err));
		}
		if (data?.user?.exp && Date.now() / 1000 > data?.user?.exp) {
			setData({
				apiToken: null,
				user: null,
			});
		}
	}, [data.apiToken]);

	return (
		<ThemeProvider theme={theme}>
			<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="nb">
				<ApolloProvider client={client}>
					<AppContext.Provider
						value={{
							data,
							set: setData,
						}}
					>
						<UserProvider>
							<NavigatorProvider>
								<MainMenuProvider>
									<MoveDocumentContextMenuProvider>
										<ConfirmProvider>
											{children}
										</ConfirmProvider>
									</MoveDocumentContextMenuProvider>
								</MainMenuProvider>
							</NavigatorProvider>
						</UserProvider>
					</AppContext.Provider>
				</ApolloProvider>
			</LocalizationProvider>
		</ThemeProvider>
	);
}
