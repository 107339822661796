import theme from 'web/theme/theme';

export function assigmentTaskDeadlineEntryStatusColor({
	status = null,
	hasCompletedSubTasks = false,
	isOverdue = false,
	paletteType = 'light',
}) {
	const statusColors = theme.palette.status[paletteType];

	const colors = {
		'Ikke aktuell': statusColors.gray,
		Utført: statusColors.mediumGreen,
		Kontrollert: statusColors.green,
		'Klart for utarbeidelse': statusColors.blue,
		'Venter på opplysninger': statusColors.orange,
		Internrevisjon: statusColors.yellow,
		'Ferdig utarbeidet, venter opplysninger': statusColors.lightGreen,
		'Ferdig utarbeidet': statusColors.lightGreen,
		'Sendt til revisor': statusColors.yellow,
		'Sendt til signering': statusColors.lightGreen,
		'Venter på signering revisor': statusColors.yellow,
		'Ferdig signert og godkjent Altinn': statusColors.mediumGreen,
		'Kontrollert skatteoppgjør': statusColors.green,
		'Venter på revisors beretning': statusColors.yellow,
		'Ferdig signert': statusColors.lightGreen,
		'Sendt til Altinn': statusColors.mediumGreen,
		'Godkjent Brreg': statusColors.green,
	};

	if (isOverdue && !hasCompletedSubTasks) {
		return statusColors.red;
	}

	if (!status && hasCompletedSubTasks) {
		return statusColors.yellow;
	}

	return colors[status] ?? statusColors.gray;
}

export function companyControlStatusColor(statusCode) {
	const bgColors = theme.palette.background;

	const colors = {
		READY: bgColors.blue,
		REVIEW: bgColors.yellow,
		CONTROL: bgColors.red,
		APPROVAL: bgColors.orange,
		APPROVED: bgColors.green,
		ERROR: bgColors.red,
	};

	return colors[statusCode] ?? bgColors.gray;
}

export function companyContractStatusColor(statusCode) {
	const bgColors = theme.palette.background;

	const colors = {
		CREATED: bgColors.orange,
		SIGNING: bgColors.yellow,
		SIGNED: bgColors.green,
	};

	return colors[statusCode] ?? bgColors.gray;
}

export function scoreColor(score) {
	switch (score) {
		case 'Høy':
			return theme.palette.background.red;
		case 'Normal':
			return theme.palette.background.yellow;
		case 'Lav':
			return theme.palette.background.green;
		default:
			return theme.palette.common.gray;
	}
}
