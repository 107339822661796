import { useAppFilters, actionTypes } from 'web/contexts/AppFiltersContext';

import UsersFilterSelect from 'components/filters/components/UsersFilterSelect';

export default function ProjectManagerFilter({
	disabled = false,
	size = 'medium',
}) {
	const { projectManager, dispatch } = useAppFilters();

	return (
		<UsersFilterSelect
			title="Vis oppdragsansvarlig"
			roles={['admin', 'accountant']}
			value={projectManager}
			onChange={value =>
				dispatch({
					type: actionTypes.SET_PROJECT_MANAGER,
					payload: value,
				})
			}
			disabled={disabled}
			size={size}
		/>
	);
}
