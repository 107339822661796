import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router';

import { InlineLoading } from 'components/Loading';
import ErrorMessage from 'components/ErrorMessage';

import { GET_HELP_PAGES } from 'api/queries/helpPageQueries';

const Title = styled.h1`
	margin: 0 0 24px 0;
`;

export default function HelpIndex() {
	const {
		data: { helpPages } = {
			helpPages: [],
		},
		loading,
		error,
	} = useQuery(GET_HELP_PAGES);

	if (loading) {
		return <InlineLoading />;
	}

	if (error) {
		return <ErrorMessage errors={[error]} />;
	}

	return (
		<div>
			<Title>Hjelpesider og rutiner</Title>

			<p>
				Velkommen til hjelpesidene for vår app! Her finner du svar på
				ofte stilte spørsmål, rutiner, veiledninger og nyttige tips for
				å få mest mulig ut av funksjonene i appen. Vi har laget en
				oversiktlig samling med informasjon som hjelper deg å navigere
				og løse eventuelle utfordringer du måtte støte på. Enten du er
				ny bruker eller trenger støtte på spesifikke områder, er
				hjelpesidene her for å gi deg en enkel og effektiv opplevelse.
			</p>

			<p>
				Nedenfor finner du en innholdsfortegnelse som gir deg rask
				tilgang til de ulike temaene.
			</p>

			<h2>Innholdsfortegnelse</h2>

			{helpPages?.map(page => (
				<li key={page._id}>
					<Link to={`/hjelp/${page._id}/`}>{page.name}</Link>
				</li>
			))}

			<li>
				<Link to={`/hjelp/brukerveiledning`}>Brukerveiledning</Link>
			</li>
		</div>
	);
}
